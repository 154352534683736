import React, { Fragment } from "react";

import Select from "react-select";

export const SingleSelect = (props) => {
  return (
    <Fragment>
      <Select
        onChange={props.handleChange}
        className="basic-single"
        classNamePrefix="select"
        placeholder={props.placeholder}
        isDisabled={props.isDisabled}
        isLoading={props.isLoading}
        isSearchable={true}
        defaultValue={props.selected}
        value={props.value}
        name="name"
        isMulti={props.isMulti}
        options={props.data}
        isOptionDisabled={(option) => option?.disabled}
        isClearable={props.isClearable}
      />
    </Fragment>
  );
};
