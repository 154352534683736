import { MuiThemeProvider, Tooltip } from "@material-ui/core";
import React from "react";
import { tooltipTheme } from "../utils/Helpers";
import infoIcon from "../assets/images/info.png";

const SimpleTooltip = (props) => {
  return (
    <MuiThemeProvider theme={tooltipTheme}>
      <Tooltip title={props.data}>
        <img src={infoIcon} className="infoIcon mt-2" alt="info" />
      </Tooltip>
    </MuiThemeProvider>
  );
};

export default SimpleTooltip;
