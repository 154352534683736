import axios from "axios";
import React, { useEffect, useState } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { GET_SERVICE_BY_ID } from "../shared/ApiURLs";
import {
  addHeaderInAxios,
  AQLDataArray,
  AQLevels,
  calInvNorCumulativeDistribution,
  checkSignature,
  helpTextsMarginOfErrorCalculation,
  helpTextsSampleSizeCalculation,
  InspectionTypes,
  sampleSizeCodes,
  sampleSizeCodeValue,
  samplingMethodologyType,
} from "../utils/Helpers";
import MyLoader from "../components/ContentLoader";
import { toast, ToastContainer } from "react-toastify";
import backLogo from "../assets/images/back.png";

import {
  Backdrop,
  CircularProgress,
  Container,
  makeStyles,
  Button,
  Checkbox,
} from "@material-ui/core";
import { Col, Row } from "react-bootstrap";
import { SingleSelect } from "../components/SingleSelect";
import { BrowserView, MobileView } from "react-device-detect";
import SimpleTooltip from "../components/SimpleToolTip";
import { AQLModal } from "../components/AQLModal";
import Select from "react-select";
import NumericInput from "react-numeric-input";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const SSCalcService = (props) => {
  const classes = useStyles();
  const [service, setService] = useState([]);
  const [loading, setLoading] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const [isContentLoading, setContentLoading] = useState(true);
  const [methodologyType, setMethodologyType] = useState(null);

  const [populationSizeInSample, setPopulationSizeInSample] = useState(1);
  const [marginErrInSample, setMarginErrInSample] = useState(1);
  const [confidenceLevelInSample, setConfidenceLevelInSample] = useState(1);
  const [numOfRespondentsInSample, setNumOfRespondentsInSample] = useState(0);

  const [populationSizeInMargin, setPopulationSizeInMargin] = useState(1);
  const [marginErrInMargin, setMarginErrInMargin] = useState(0);
  const [confidenceLevelInMargin, setConfidenceLevelInMargin] = useState(1);
  const [numOfRespondentsInMargin, setNumOfRespondentsInMargin] = useState(1);

  const [populationSizeInISO, setPopulationSizeInISO] = useState(0);
  const [inspectionType, setInspectionType] = useState(null);
  const [is_accep_rej_level, setIs_accep_rej_level] = useState(false);
  const [acceptableQualityLevel, setAcceptableQualityLevel] = useState("");
  const [showAQLData, setShowAQLData] = useState(false);

  const [sampleSizeISO, setSampleSizeISO] = useState("");
  const [sampleSizeCodeISO, setSampleSizeCodeISO] = useState("");
  const [acceptanceISO, setAcceptanceISO] = useState("");
  const [rejectionISO, setRejectionISO] = useState("");

  useEffect(() => {
    getServiceById(props.match.params.service_id);
    console.log("useEff", acceptableQualityLevel);
    console.log("useEff", inspectionType);
    console.log("useEff", populationSizeInISO);
    calculateSampleSizeAndRejAndAcc(acceptableQualityLevel);
    calculateNumberOfRespondents(
      confidenceLevelInSample,
      marginErrInSample,
      populationSizeInSample
    );
    calculateMarginOfError(
      confidenceLevelInMargin,
      populationSizeInMargin,
      numOfRespondentsInMargin
    );
  }, [
    populationSizeInISO,
    acceptableQualityLevel,
    inspectionType,
    confidenceLevelInSample,
    marginErrInSample,
    populationSizeInSample,
    confidenceLevelInMargin,
    populationSizeInMargin,
    numOfRespondentsInMargin,
  ]);

  const getServiceById = (service_id) => {
    if (firstLoad) {
      axios
        .get(GET_SERVICE_BY_ID(service_id))
        .then((res) => {
          setFirstLoad(false);
          setService(res.data.service);
          setContentLoading(false);
        })
        .catch((error) => {
          checkSignature(error);
          setFirstLoad(false);
          setContentLoading(false);
          props.history.replace("/services");
        });
    }
  };

  const handleToast = (event_type, message) => {
    return event_type === "success"
      ? toast.success(message)
      : toast.error(message);
  };

  const backBtnHandler = () => {
    return props.history.push("/services");
  };

  const handleMethodChange = (selectedOption, triggeredAction) => {
    if (triggeredAction.action === "clear") {
      setMethodologyType(null);
    } else {
      setMethodologyType(selectedOption);
      if (selectedOption.value && selectedOption.value === "ISO2859-1") {
        // resetISOInputs();
        setInspectionType(InspectionTypes[0]);
        calculateSampleSizeAndRejAndAcc(acceptableQualityLevel);
      } else if (
        selectedOption.value &&
        selectedOption.value === "statistical_method"
      ) {
        resetSampleInputs();
        resetMarginInputs();
      }
    }
  };

  const resetSampleInputs = () => {
    setPopulationSizeInSample(1);
    setMarginErrInSample(1);
    setConfidenceLevelInSample(1);
    setNumOfRespondentsInSample(0);
  };

  const resetMarginInputs = () => {
    setPopulationSizeInMargin(1);
    setNumOfRespondentsInMargin(1);
    setConfidenceLevelInMargin(1);
    setMarginErrInMargin(0);
  };

  const calculateNumberOfRespondents = (
    confidenceLevelInSample,
    marginErrInSample,
    populationSizeInSample
  ) => {
    if (
      confidenceLevelInSample &&
      marginErrInSample &&
      populationSizeInSample
    ) {
      const value_a = marginErrInSample / 100;
      const value_b = 1 - (1 - confidenceLevelInSample / 100) / 2;
      const p = 0.5;

      const confiLevel_z = calInvNorCumulativeDistribution(value_b);
      console.log("Confidence level z value : ", confiLevel_z);

      const result_a = (confiLevel_z ** 2 * p * (1 - p)) / value_a ** 2;
      const result_b = result_a / (1 + (result_a - 1) / populationSizeInSample);
      console.log("ss", result_b);
      setNumOfRespondentsInSample(Math.round(result_b));
    }
  };

  const calculateMarginOfError = (
    confidenceLevelInMargin,
    populationSizeInMargin,
    numOfRespondentsInMargin
  ) => {
    if (
      confidenceLevelInMargin &&
      populationSizeInMargin &&
      numOfRespondentsInMargin
    ) {
      const value_b = 1 - (1 - confidenceLevelInMargin / 100) / 2;
      const p = 0.5;

      const confiLevel_z = calInvNorCumulativeDistribution(value_b);

      const ss =
        (numOfRespondentsInMargin * (populationSizeInMargin - 1)) /
        (populationSizeInMargin - numOfRespondentsInMargin);

      console.log("ss", Math.round(ss));

      const marginErr = confiLevel_z * Math.sqrt((p * (1 - p)) / ss);
      console.log(marginErr);

      console.log("marginErr", parseFloat(marginErr * 100).toFixed(2));

      setMarginErrInMargin(parseFloat(marginErr * 100).toFixed(2));
    }
  };

  const handleInspectionTypeChange = (selectedOption, triggeredAction) => {
    if (triggeredAction.action === "clear") {
      setMethodologyType(null);
      return calculateSampleSizeAndRejAndAcc(acceptableQualityLevel);
    } else {
      setInspectionType(selectedOption);
      return calculateSampleSizeAndRejAndAcc(acceptableQualityLevel);
    }
  };

  const handleAQLChange = (data) => {
    setAcceptableQualityLevel(String(data));
    calculateSampleSizeAndRejAndAcc(acceptableQualityLevel);
  };

  const calculateSampleSizeAndRejAndAcc = (acceptableQualityLevel) => {
    console.log(populationSizeInISO, inspectionType?.value);

    if (populationSizeInISO >= 0 && inspectionType?.value) {
      let result = sampleSizeCodes.filter(
        (el) => populationSizeInISO >= el.min && populationSizeInISO <= el.max
      )[0];

      const sampleSizeCode = result.data[inspectionType?.value];

      const sampleSizeValue =
        sampleSizeCodeValue[result.data[inspectionType?.value]];

      console.log(sampleSizeCode, sampleSizeValue);

      setSampleSizeCodeISO(sampleSizeCode);
      setSampleSizeISO(sampleSizeValue);

      if (is_accep_rej_level && acceptableQualityLevel && sampleSizeCode) {
        let a_r_values = AQLevels[acceptableQualityLevel][sampleSizeCode];

        setAcceptanceISO(a_r_values[0]);
        setRejectionISO(a_r_values[1]);
        console.log(a_r_values);
      }
    }
  };

  const resetISOInputs = () => {
    setPopulationSizeInISO(2);
    setInspectionType(InspectionTypes[0]);
    setIs_accep_rej_level(false);
    setAcceptableQualityLevel("");
    setShowAQLData(false);
    setSampleSizeISO("");
    setAcceptanceISO("");
    setRejectionISO("");
    setSampleSizeCodeISO("");
  };

  const isAccRejCheckboxChange = (e) => {
    setIs_accep_rej_level(e.target.checked);
    // setAcceptableQualityLevel("");
    setAcceptanceISO("");
    setRejectionISO("");
    calculateSampleSizeAndRejAndAcc(acceptableQualityLevel);
  };

  return (
    <div className="main-wrapper">
      <Header {...props} />
      {isContentLoading && <MyLoader />}
      {!isContentLoading && (
        <Container>
          <div className="mb-3">
            <div className="custom_card px-4 py-1">
              <div className="mt-2">
                <span>
                  <img
                    src={backLogo}
                    alt="back"
                    className="backBtn"
                    onClick={backBtnHandler}
                  />
                </span>
                <span className="mx-3 sscalcHeading">
                  <BrowserView className="d-inline">
                    Sample Size Calculator
                  </BrowserView>
                  <MobileView className="d-inline">
                    {service[0]?.name}
                  </MobileView>
                </span>
              </div>

              <Row className="py-3">
                <Col lg={4} md={12}>
                  <div>
                    <div className="d-flex justify-content-between mb-2">
                      <h6>Select Sampling Methodology Type</h6>
                      {/* {methodologyType && <SimpleTooltip data={"asdas"} />} */}
                    </div>

                    <SingleSelect
                      data={samplingMethodologyType}
                      value={methodologyType}
                      placeholder={"Select one of them..."}
                      isDisabled={false}
                      isLoading={false}
                      handleChange={handleMethodChange}
                    />
                  </div>
                </Col>
                <Col lg={8} md={12}>
                  <p className="text-justify mt-lg-0 mt-2 pl-lg-5 pl-0">
                    <b>What is {service[0]?.name} service?</b>
                    <br />
                    {service[0]?.description}
                  </p>
                </Col>
              </Row>
            </div>

            {methodologyType?.value === "statistical_method" && (
              <div className="mt-3 mb-5 mb-lg-3" id="statistical_method">
                <Row>
                  <Col lg={6} md={12}>
                    <div className="custom_card px-4 py-3 my-1">
                      <div className="d-flex justify-content-between">
                        <h5 className="py-1 mb-3">
                          Calculate representative sample size
                        </h5>

                        <span className="d-flex">
                          <Button
                            color="secondary"
                            className=" h-75"
                            variant="contained"
                            component="span"
                            onClick={resetSampleInputs}
                          >
                            Reset
                          </Button>
                        </span>
                      </div>

                      <div className="">
                        <Row className="mb-1">
                          <Col lg={6} md={12}>
                            <h6>Population size:</h6>
                          </Col>
                          <Col
                            lg={6}
                            md={12}
                            className="d-flex justify-content-between"
                          >
                            <NumericInput
                              onChange={(e) =>
                                e
                                  ? setPopulationSizeInSample(e)
                                  : setPopulationSizeInSample(0)
                              }
                              className="form-control px-2"
                              min={1}
                              max={1000000000}
                              value={populationSizeInSample}
                              onKeyDown={(e) => {
                                if (e.key === ".") {
                                  e.preventDefault();
                                }
                              }}
                            />
                            <SimpleTooltip
                              data={
                                helpTextsSampleSizeCalculation.population_size
                              }
                            />
                          </Col>
                        </Row>

                        <Row className="mb-1">
                          <Col lg={6} md={12}>
                            <h6>Margin of error (%):</h6>
                          </Col>
                          <Col
                            lg={6}
                            md={12}
                            className="d-flex justify-content-between"
                          >
                            <NumericInput
                              onChange={(e) =>
                                e
                                  ? setMarginErrInSample(e)
                                  : setMarginErrInSample(1)
                              }
                              style={{ width: "90%" }}
                              className="form-control px-2"
                              min={1}
                              max={99}
                              value={marginErrInSample}
                              format={(num) => num + "%"}
                            />

                            <SimpleTooltip
                              data={
                                helpTextsSampleSizeCalculation.margin_of_error
                              }
                            />
                          </Col>
                        </Row>

                        <Row className="mb-1">
                          <Col lg={6} md={12}>
                            <h6>Confidence level (%):</h6>
                          </Col>
                          <Col
                            lg={6}
                            md={12}
                            className="d-flex justify-content-between"
                          >
                            <NumericInput
                              onChange={(e) =>
                                e
                                  ? setConfidenceLevelInSample(e)
                                  : setConfidenceLevelInSample(0)
                              }
                              style={{ width: "90%" }}
                              className="form-control px-2"
                              min={1}
                              max={99}
                              value={confidenceLevelInSample}
                              format={(num) => num + "%"}
                            />

                            <SimpleTooltip
                              data={
                                helpTextsSampleSizeCalculation.confidence_level
                              }
                            />
                          </Col>
                        </Row>
                        <hr />
                        <Row>
                          <Col lg={6} md={12}>
                            <h6>Required sample size:</h6>
                          </Col>
                          <Col
                            lg={6}
                            md={12}
                            className="d-flex justify-content-between"
                          >
                            <input
                              type="text"
                              style={{ width: "90%" }}
                              className="form-control px-2"
                              value={String(numOfRespondentsInSample)}
                              readOnly={true}
                            />
                            <SimpleTooltip
                              data={
                                helpTextsSampleSizeCalculation.req_sample_size
                              }
                            />
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Col>
                  <Col lg={6} md={12}>
                    <div className="custom_card px-4 py-3 my-1">
                      <div className="d-flex justify-content-between">
                        <h5 className="py-1 mb-3">
                          Calculate sample size margin of error
                        </h5>

                        <span className="d-flex">
                          <Button
                            color="secondary"
                            className=" h-75"
                            variant="contained"
                            component="span"
                            onClick={resetMarginInputs}
                          >
                            Reset
                          </Button>
                        </span>
                      </div>

                      <div className="">
                        <Row className="mb-1">
                          <Col lg={6} md={12}>
                            <h6>Population size:</h6>
                          </Col>
                          <Col
                            lg={6}
                            md={12}
                            className="d-flex justify-content-between"
                          >
                            <NumericInput
                              onChange={(e) =>
                                e
                                  ? setPopulationSizeInMargin(e)
                                  : setPopulationSizeInMargin(0)
                              }
                              type="number"
                              className="form-control px-2"
                              min={1}
                              max={1000000000}
                              value={populationSizeInMargin}
                              onKeyDown={(e) => {
                                if (e.key === ".") {
                                  e.preventDefault();
                                }
                              }}
                            />

                            <SimpleTooltip
                              data={
                                helpTextsMarginOfErrorCalculation.population_size
                              }
                            />
                          </Col>
                        </Row>

                        <Row className="mb-1">
                          <Col lg={6} md={12}>
                            <h6>Number of respondents:</h6>
                          </Col>
                          <Col
                            lg={6}
                            md={12}
                            className="d-flex justify-content-between"
                          >
                            <NumericInput
                              onChange={(e) =>
                                e
                                  ? setNumOfRespondentsInMargin(e)
                                  : setNumOfRespondentsInMargin(0)
                              }
                              style={{ width: "90%" }}
                              className="form-control px-2"
                              min={1}
                              max={1000000000}
                              value={numOfRespondentsInMargin}
                              onKeyDown={(e) => {
                                if (e.key === ".") {
                                  e.preventDefault();
                                }
                              }}
                            />
                            <SimpleTooltip
                              data={
                                helpTextsMarginOfErrorCalculation.num_of_respondents
                              }
                            />
                          </Col>
                        </Row>

                        <Row className="mb-1">
                          <Col lg={6} md={12}>
                            <h6>Confidence level (%):</h6>
                          </Col>
                          <Col
                            lg={6}
                            md={12}
                            className="d-flex justify-content-between"
                          >
                            <NumericInput
                              onChange={(e) =>
                                e
                                  ? setConfidenceLevelInMargin(e)
                                  : setConfidenceLevelInMargin(1)
                              }
                              style={{ width: "90%" }}
                              className="form-control px-2"
                              min={0}
                              max={99}
                              value={confidenceLevelInMargin}
                              format={(num) => num + "%"}
                            />
                            <SimpleTooltip
                              data={
                                helpTextsMarginOfErrorCalculation.confidence_level
                              }
                            />
                          </Col>
                        </Row>
                        <hr />
                        <Row>
                          <Col lg={6} md={12}>
                            <h6>Margin of error:</h6>
                          </Col>
                          <Col
                            lg={6}
                            md={12}
                            className="d-flex justify-content-between"
                          >
                            <input
                              type="text"
                              style={{ width: "90%" }}
                              className="form-control px-2"
                              value={
                                String(marginErrInMargin) === "NaN"
                                  ? "0%"
                                  : `${marginErrInMargin}%`
                              }
                              readOnly={true}
                            />
                            <SimpleTooltip
                              data={
                                helpTextsMarginOfErrorCalculation.margin_of_error
                              }
                            />
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            )}

            {methodologyType?.value === "ISO2859-1" && (
              <div className="mt-3 mb-5 mb-lg-3" id="ISO2859">
                <Row>
                  <Col lg={12} md={12}>
                    <div className="custom_card px-4 py-3 my-1">
                      <div className="d-flex justify-content-between">
                        <h5 className="py-1 mb-3">
                          Calculate Sample-Size, Acceptance, Rejection levels
                        </h5>

                        <span className="d-flex">
                          <Button
                            color="secondary"
                            className=" h-75"
                            variant="contained"
                            component="span"
                            onClick={resetISOInputs}
                          >
                            Reset
                          </Button>
                        </span>
                      </div>

                      <Row className="">
                        <Col lg={6} md={12}>
                          <Row className="mb-1">
                            <Col lg={6} md={12}>
                              <h6>Population size:</h6>
                            </Col>
                            <Col
                              lg={6}
                              md={12}
                              className="d-flex justify-content-between"
                            >
                              <NumericInput
                                onChange={(e) => {
                                  e
                                    ? setPopulationSizeInISO(e)
                                    : setPopulationSizeInISO(0);
                                  calculateMarginOfError();
                                }}
                                className="form-control px-2"
                                min={0}
                                max={1000000000}
                                value={populationSizeInISO}
                                onKeyDown={(e) => {
                                  if (e.key === ".") {
                                    e.preventDefault();
                                  }
                                }}
                              />
                            </Col>
                          </Row>

                          <Row className="mb-1">
                            <Col lg={6} md={12}>
                              <h6>Inspection type:</h6>
                            </Col>
                            <Col lg={6} md={12} className="">
                              <Select
                                onChange={handleInspectionTypeChange}
                                className="basic-single"
                                classNamePrefix="select"
                                placeholder={"Select one of them..."}
                                isSearchable={true}
                                value={inspectionType}
                                name="name"
                                options={InspectionTypes}
                              />
                            </Col>
                          </Row>

                          <Row className="mb-1">
                            <Col lg={6} md={12}>
                              <h6>Acceptance, Rejection levels?</h6>
                            </Col>
                            <Col lg={6} md={12} className="">
                              <Checkbox
                                color="primary"
                                className="p-0"
                                checked={is_accep_rej_level}
                                onChange={(e) => {
                                  isAccRejCheckboxChange(e);
                                }}
                              />
                            </Col>
                          </Row>

                          {is_accep_rej_level && (
                            <Row className="mb-1">
                              <Col lg={6} md={12}>
                                <h6>Acceptable Quality Level:</h6>
                              </Col>
                              <Col lg={6} md={12} className="">
                                <input
                                  type="text"
                                  readOnly={true}
                                  className="form-control"
                                  placeholder="Click here..."
                                  onClick={() => setShowAQLData(true)}
                                  value={
                                    acceptableQualityLevel
                                      ? acceptableQualityLevel
                                      : ""
                                  }
                                />
                              </Col>
                            </Row>
                          )}
                        </Col>

                        <Col lg={6} md={12}>
                          <h6>Sample Size Code: {sampleSizeCodeISO}</h6>
                          <h6>Sample Size: {sampleSizeISO}</h6>
                          {is_accep_rej_level && (
                            <div>
                              <h6>
                                {acceptanceISO >= 0 &&
                                acceptableQualityLevel !== ""
                                  ? `Acceptance: Upto ${acceptanceISO} no. of defects`
                                  : ""}
                              </h6>
                              <h6>
                                {rejectionISO >= 0 &&
                                acceptableQualityLevel !== ""
                                  ? `Rejection: Greater than ${rejectionISO} no. of defects.`
                                  : ""}
                              </h6>
                            </div>
                          )}
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </div>
            )}
          </div>
        </Container>
      )}
      <AQLModal
        show={showAQLData}
        onHide={() => setShowAQLData(false)}
        data={AQLDataArray}
        handledata={(data) => handleAQLChange(data)}
      />
      <ToastContainer />
      <Backdrop
        className={classes.backdrop}
        style={{ zIndex: "10" }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Footer />
    </div>
  );
};

export default SSCalcService;
