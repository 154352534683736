import axios from "axios";
import React, { useEffect, useState } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { GET_SERVICE_BY_ID } from "../shared/ApiURLs";
import {
  checkSignature,
  earthingCalcType,
  earthingConductorTypes,
  earthinMaterials,
  faultDuration,
  finalTemp,
  insulationTypes,
  sqrt_3,
  _6AData,
} from "../utils/Helpers";
import MyLoader from "../components/ContentLoader";
import { toast, ToastContainer } from "react-toastify";
import backLogo from "../assets/images/back.png";
import NumericInput from "react-numeric-input";

import _img1222 from "../assets/images/12.2.2.png";
import _img12221 from "../assets/images/12.2.2.1.png";
import _img922 from "../assets/images/9.2.2.png";
import _img923 from "../assets/images/9.2.3.png";
import _img861 from "../assets/images/8.6.1.png";
import _img103 from "../assets/images/10.3.png";
import _geotechnical from "../assets/images/geotechnical.png";
import _k_formula from "../assets/images/k_formula.png";
import IS3043PDF from "../assets/docs/IS3043.pdf";

import { Container } from "@material-ui/core";
import { Col, Row } from "react-bootstrap";
import { ImageModal } from "../components/imageModal";
import { SingleSelect } from "../components/SingleSelect";

const EarthinCalcService = (props) => {
  const [service, setService] = useState([]);
  const [firstLoad, setFirstLoad] = useState(true);
  const [isContentLoading, setContentLoading] = useState(true);

  // Design Data:
  const [fault_duration, setFaultDuration] = useState(faultDuration[0]);
  // const [v_level_dc, setV_level_dc] = useState(1.0);
  const [soil_resistivity, setSoil_resistivity] = useState(102.01);
  const [earthingConductorType, setEarthingConductorType] = useState(
    earthingConductorTypes[0]
  );
  const [earthingConductor, setEarthingConductor] = useState(
    earthinMaterials[0]
  );

  const [insulationType, setInsulationType] = useState("");
  const [finalTemprature, setFinalTemprature] = useState(finalTemp[0]);

  // const [gi_earth_conductor, setGI_earth_conductor] = useState(80);
  // const [gi_earth_electrode, setGI_earth_electrode] = useState(80);
  const [current_rating_e_conductor, setCurrent_rating_e_conductor] =
    useState(80);
  const [resis_earth_grid, setResis_earth_grid] = useState(1.0);

  // Fault Current Calculations DC
  const [isc_of_module, setIsc_of_module] = useState(8.44);
  const [marginPercent, setMarginPercent] = useState(25);
  const [margin_over_isc, setMargin_over_isc] = useState(0.0);
  const [no_of_string_connc_cb, setNo_of_string_connc_cb] = useState(32.0);
  const [no_of_cb_connc_inver, setNo_of_cb_connc_inver] = useState(8.0);
  const [fault_curr_at_one_inv, setFault_curr_at_one_inv] = useState(0.0);
  const [max_fault_curr, setMax_fault_curr] = useState(0.0);

  // CONDUCTOR SELECTION
  const [min_conduc_cross_area, setMin_conduc_cross_area] = useState(0.0);
  const [area_of_conduc_req, setArea_of_conduc_req] = useState(0.0);
  const [width_of_conduc, setWidth_of_conduc] = useState(25.0);
  const [thickness_of_conduc, setThickness_of_conduc] = useState(3.0);
  const [area_of_conduc, setArea_of_conduc] = useState(0.0);
  const [max_permi_current_densi, setMax_permi_current_densi] = useState(0.0);
  const [min_earth_electrode_area, setMin_earth_electrode_area] = useState(0.0);
  const [min_earth_electrode_area_req, setMin_earth_electrode_area_req] =
    useState(0.0);
  const [len_of_GI_strip_considered, setLen_of_GI_strip_considered] =
    useState(10000.0);
  const [no_of_run, setNo_of_run] = useState(1.0);
  const [area_of_strip_electrode, setArea_of_strip_electrode] = useState(0.0);
  const [no_of_electrode, setNo_of_electrode] = useState(24.0);
  const [len_of_electrode, setLen_of_electrode] = useState(3.0);
  const [effec_len_of_electrode, setEffec_len_of_electrode] = useState(2.5);
  const [dia_of_electrode, setDia_of_electrode] = useState(0.0175);
  const [area_of_each_pipe_electrode, setArea_of_each_pipe_electrode] =
    useState(0.0);
  const [area_of_total_pipe_electrode, setArea_of_total_pipe_electrode] =
    useState(0.0);
  const [
    total_area_of_stripe_and_pipe_electrode,
    setTotal_area_of_stripe_and_pipe_electrode,
  ] = useState(0.0);
  const [combine_area_of_electrode, setCombine_area_of_electrode] =
    useState(0.0);
  const [is_pipe_stripe_electrode, setIs_pipe_stripe_electrode] = useState("");

  // CALCULATION OF EARTH RESISTANCE
  const [depth_buried_electrode, setDepth_buried_electrode] = useState(60.0);
  const [len_of_strip, setLen_of_strip] = useState(500000);
  const [width_of_strip, setWidth_of_strip] = useState(2.5);
  const [resis_of_strip_electrode, setResis_of_strip_electrode] = useState(0.0);
  const [len_of_electrode_below_g, setLen_of_electrode_below_g] =
    useState(250.0);
  const [diameter_of_electrode, setDiameter_of_electrode] = useState(1.75);
  const [resis_of_gi_pipe, setResis_of_gi_pipe] = useState(0.0);
  const [combi_resis_of_total_pipe_elc, setCombi_resis_of_total_pipe_elc] =
    useState(0.0);
  const [
    toal_grid_resis_due_pipe_stripe_elec,
    setToal_grid_resis_due_pipe_stripe_elec,
  ] = useState(0.0);

  // Earthing Design C8.6.1alculation - Results & Conclusions
  const [prop_size_of_conduc, setProp_size_of_conduc] = useState(0.0);
  const [req_size_of_conduc, setReq_size_of_conduc] = useState(0.0);
  const [conductor_size_result, setResult] = useState("");
  const [premitted_grid_resis_val, setPremitted_grid_resis_val] = useState(0.0);
  const [achived_combi_grid_resis, setAchived_combi_grid_resis] = useState(0.0);
  const [grid_resis_result, setGrid_resis_result] = useState("");

  // modal
  const [imgPath, setImgPath] = useState("");
  const [imgShow, setImgShow] = useState(false);

  // cal type
  const [calcType, setCalcType] = useState(earthingCalcType[0]);

  // fault current calc for ac
  const [powerTrafoPowerRating, setPowerTrafoPowerRating] = useState(40);
  const [mvVoltageLevel, setMvVoltageLevel] = useState(11);
  const [mvCurrent, setMvCurrent] = useState(40);
  const [powerTrafoZ, setPowerTrafoZ] = useState(10);
  const [faultCurrentAC, setFaultCurrentAC] = useState(0.0);

  // Calc type Switch Yard:
  // Design Data
  const [
    shortCircuitCurrForConductorSizing,
    setShortCircuitCurrForConductorSizing,
  ] = useState(36.0);
  const [systemShortCircuitCurrent, setSystemShortCircuitCurrent] =
    useState(36000.0);
  const [diversionFactor, setDiversionFactor] = useState(1.0);
  const [sc_currThroughEarthingElectode, setSC_currThroughEarthingElectode] =
    useState(0.0);
  const [tc, setTC] = useState(1.0);
  const [tz, setTZ] = useState(1.0);
  const [lengthOfEarthmat, setLengthOfEarthmat] = useState(80.0);
  const [breadthOfEarthmat, setBreadthOfEarthmat] = useState(85.0);
  const [areaOfEarthmat, setAreaOfEarthmat] = useState(0.0);
  const [spacingBetweenTheConductors, setSpacingBetweenTheConductors] =
    useState(2.5);
  const [depth, setDepth] = useState(0.6);
  const [soilResistivity_switchyard, setSoilResistivity_switchyard] =
    useState(87.83);
  const [surfaceLayerResistivity, setSurfaceLayerResistivity] = useState(4000);
  const [surfaceLayerThickness, setSurfaceLayerThickness] = useState(0.15);

  // SIZING OF EARTHING CONDUCTOR
  const [refTempForMaterialConstants, setRefTempForMaterialConstants] =
    useState(20.0);
  const [tempCoeffOfTheMaterial, setRefTempCoeffOfTheMaterial] =
    useState(0.0016);
  const [fusingTempOfConductor, setFusingTempOfConductor] = useState(1510.0);
  const [designAmbientTemperature, setDesignAmbientTemperature] =
    useState(50.0);
  const [Ko, setKo] = useState(0.0);
  const [resisOfConductorAtRefeTemp, setResisOfConductorAtRefeTemp] =
    useState(15.9);
  const [specificHeatOfMaterial, setSpecificHeatOfMaterial] = useState(0.122);
  const [densityOfMaterial, setDensityOfMaterial] = useState(7.85);
  const [thermalCapacityPerUnitVol, setThermalCapacityPerUnitVol] =
    useState(0.0);
  const [
    currentForSizingEarthMatConductor,
    setCurrentForSizingEarthMatConductor,
  ] = useState(0.0);
  const [
    areaOfCrossSectionOfEarthConductor,
    setAreaOfCrossSectionOfEarthConductor,
  ] = useState(0.0);
  const [
    reqAreaEarthingConductorIncludingCorrosion,
    setReqAreaEarthingConductorIncludingCorrosion,
  ] = useState(0.0);

  const [selectedConductorWidth, setSelectedConductorWidth] = useState(50.0);
  const [selectedConductorThickenss, setSelectedConductorThickenss] =
    useState(10.0);

  const [crossSectionOfSelectedConductor, setCrossSectionOfSelectedConductor] =
    useState(0.0);

  const [conductorSizingCriteria, setConductorSizingCriteria] = useState("");

  // Calculation for tolerable touch and step potential
  const [cs_Hs, setCs_Hs] = useState(0.0);
  const [tolerableEstep, setTolerableEstep] = useState(0.0);
  const [tolerableEtouch, setTolerableEtouch] = useState(0.0);

  useEffect(() => {
    getServiceById(props.match.params.service_id);
  });

  useEffect(() => {
    // CAlC Type AC or DC Calcs
    if (calcType.value !== "switchyard") {
      setCurrent_rating_e_conductor(
        getEarthingConductor_K(
          earthingConductorType?.value,
          fault_duration?.value
        )
      );

      let mvCurrent = (powerTrafoPowerRating * 1000) / sqrt_3 / mvVoltageLevel;
      setMvCurrent(parseFloat(mvCurrent).toFixed(2));

      setMargin_over_isc(
        parseFloat(isc_of_module * (1 + marginPercent / 100)).toFixed(2)
      );

      setFault_curr_at_one_inv(
        parseFloat(
          margin_over_isc * no_of_string_connc_cb * no_of_cb_connc_inver
        ).toFixed(2)
      );

      let faultCurrentAC = mvCurrent / (powerTrafoZ / 100);
      setFaultCurrentAC(parseFloat(faultCurrentAC).toFixed(2));

      setMax_fault_curr(fault_curr_at_one_inv);

      setMin_conduc_cross_area(
        parseFloat(
          (calcType.value === "dc"
            ? max_fault_curr
            : faultCurrentAC * Math.sqrt(parseFloat(fault_duration.value))) /
            current_rating_e_conductor
        ).toFixed(2)
      );
      let corrosionFactor =
        soil_resistivity > 100 ? 0 : soil_resistivity > 25 ? 0.15 : 0.3;

      setArea_of_conduc_req(
        parseFloat(min_conduc_cross_area * (1 + corrosionFactor)).toFixed(2)
      );

      setArea_of_conduc(
        parseFloat(width_of_conduc * thickness_of_conduc).toFixed(2)
      );

      setMax_permi_current_densi(
        parseFloat(
          (7.57 * 1000) /
            Math.sqrt(parseFloat(fault_duration.value) * soil_resistivity)
        ).toFixed(2)
      );
      setMin_earth_electrode_area(
        parseFloat(
          parseFloat(
            calcType.value === "dc" ? max_fault_curr : faultCurrentAC
          ) / parseFloat(max_permi_current_densi)
        ).toFixed(2)
      );

      setMin_earth_electrode_area_req(
        parseFloat(min_earth_electrode_area * (1 + corrosionFactor)).toFixed(2)
      );

      setArea_of_each_pipe_electrode(
        parseFloat(3.14 * effec_len_of_electrode * dia_of_electrode).toFixed(2)
      );

      setArea_of_total_pipe_electrode(
        parseFloat(
          3.14 * effec_len_of_electrode * dia_of_electrode * no_of_electrode
        ).toFixed(2)
      );

      setTotal_area_of_stripe_and_pipe_electrode(
        parseFloat(area_of_total_pipe_electrode) +
          parseFloat(area_of_strip_electrode)
      );

      setCombine_area_of_electrode(
        parseFloat(min_earth_electrode_area_req).toFixed(2)
      );

      setIs_pipe_stripe_electrode(
        parseFloat(total_area_of_stripe_and_pipe_electrode) >
          parseFloat(min_earth_electrode_area_req)
          ? "OK"
          : "NOT OK"
      );

      // setLen_of_strip(len_of_GI_strip_considered * 100);

      setResis_of_strip_electrode(
        parseFloat(
          ((100 * soil_resistivity) / (2 * 3.14 * len_of_strip)) *
            Math.log(
              (2 * len_of_strip * len_of_strip) /
                (depth_buried_electrode * width_of_strip)
            )
        ).toFixed(2)
      );

      setEffec_len_of_electrode((len_of_electrode * 0.85).toFixed(2));
      setLen_of_electrode_below_g((effec_len_of_electrode * 100).toFixed(2));
      setDiameter_of_electrode((dia_of_electrode * 100).toFixed(2));
      setResis_of_gi_pipe(
        parseFloat(
          ((100 * soil_resistivity) / (2 * 3.14 * len_of_electrode_below_g)) *
            Math.log((4 * len_of_electrode_below_g) / diameter_of_electrode)
        ).toFixed(2)
      );

      setCombi_resis_of_total_pipe_elc(
        parseFloat(resis_of_gi_pipe / no_of_electrode).toFixed(2)
      );

      setToal_grid_resis_due_pipe_stripe_elec(
        (
          parseFloat(
            parseFloat(resis_of_strip_electrode) *
              parseFloat(combi_resis_of_total_pipe_elc)
          ) /
          parseFloat(
            parseFloat(combi_resis_of_total_pipe_elc) +
              parseFloat(resis_of_strip_electrode)
          )
        ).toFixed(2)
      );

      setProp_size_of_conduc(area_of_conduc);
      setReq_size_of_conduc(area_of_conduc_req);
      setResult(
        Number(area_of_conduc) > Number(area_of_conduc_req)
          ? "Safe"
          : "Not Safe"
      );
      // console.log(area_of_conduc, area_of_conduc_req);
      setPremitted_grid_resis_val(1);
      setAchived_combi_grid_resis(toal_grid_resis_due_pipe_stripe_elec);
      setGrid_resis_result(
        Number(premitted_grid_resis_val) > Number(achived_combi_grid_resis)
          ? "Safe"
          : "Not Safe"
      );
    }
    // CAlC Type SWITCH YARD
    else if (calcType.value === "switchyard") {
      setSystemShortCircuitCurrent(
        parseFloat(Number(shortCircuitCurrForConductorSizing) * 1000).toFixed(2)
      );
      setSC_currThroughEarthingElectode(
        (
          Number(shortCircuitCurrForConductorSizing) * Number(diversionFactor)
        ).toFixed(2)
      );
      setAreaOfEarthmat((lengthOfEarthmat * breadthOfEarthmat).toFixed(2));

      setKo(
        (
          parseFloat(1 / tempCoeffOfTheMaterial) - refTempForMaterialConstants
        ).toFixed(2)
      );

      let tcap =
        Number(specificHeatOfMaterial) * Number(densityOfMaterial) * 4.184;
      setThermalCapacityPerUnitVol(parseFloat(tcap).toFixed(2));

      setCurrentForSizingEarthMatConductor(sc_currThroughEarthingElectode);

      setAreaOfCrossSectionOfEarthConductor(
        parseFloat(
          Math.sqrt(
            Number(currentForSizingEarthMatConductor) *
              ((Number(tc) *
                Number(tempCoeffOfTheMaterial) *
                Number(resisOfConductorAtRefeTemp) *
                10000) /
                Number(tcap) /
                Math.log(
                  (Number(fusingTempOfConductor) -
                    Number(designAmbientTemperature)) /
                    (1 + Number(Ko) + Number(designAmbientTemperature))
                ))
          )
        ).toFixed(2)
      );

      let corrosionFactor =
        Number(soilResistivity_switchyard) > 100
          ? 0
          : Number(soilResistivity_switchyard) > 25
          ? 0.15
          : 0.3;
      setReqAreaEarthingConductorIncludingCorrosion(
        (
          parseFloat(areaOfCrossSectionOfEarthConductor) *
          (1 + corrosionFactor)
        ).toFixed(2)
      );

      setCrossSectionOfSelectedConductor(
        parseFloat(
          Number(selectedConductorWidth) * Number(selectedConductorThickenss)
        ).toFixed(2)
      );

      setConductorSizingCriteria(
        Number(crossSectionOfSelectedConductor) >
          Number(reqAreaEarthingConductorIncludingCorrosion)
          ? "Safe"
          : "Not Safe"
      );

      let CS_HS =
        1 -
        (0.09 * (1 - soilResistivity_switchyard / surfaceLayerResistivity)) /
          (2 * surfaceLayerThickness + 0.09);

      setCs_Hs(parseFloat(CS_HS).toFixed(2));
      setTolerableEstep(
        (
          ((1000 + parseFloat(6 * CS_HS * Number(surfaceLayerResistivity))) *
            0.116) /
          Math.sqrt(tz)
        ).toFixed(2)
      );

      setTolerableEtouch(
        (
          ((1000 + 1.5 * CS_HS * Number(surfaceLayerResistivity)) * 0.116) /
          Math.sqrt(tz)
        ).toFixed(2)
      );
    }
  }, [
    fault_duration,
    soil_resistivity,
    earthingConductor,
    current_rating_e_conductor,
    resis_earth_grid,
    isc_of_module,
    margin_over_isc,
    no_of_string_connc_cb,
    no_of_cb_connc_inver,
    fault_curr_at_one_inv,
    max_fault_curr,
    min_conduc_cross_area,
    area_of_conduc_req,
    width_of_conduc,
    thickness_of_conduc,
    area_of_conduc,
    max_permi_current_densi,
    min_earth_electrode_area,
    min_earth_electrode_area_req,
    len_of_GI_strip_considered,
    no_of_run,
    area_of_strip_electrode,
    no_of_electrode,
    len_of_electrode,
    effec_len_of_electrode,
    dia_of_electrode,
    area_of_each_pipe_electrode,
    area_of_total_pipe_electrode,
    total_area_of_stripe_and_pipe_electrode,
    combine_area_of_electrode,
    is_pipe_stripe_electrode,
    depth_buried_electrode,
    len_of_strip,
    width_of_strip,
    resis_of_strip_electrode,
    len_of_electrode_below_g,
    diameter_of_electrode,
    resis_of_gi_pipe,
    combi_resis_of_total_pipe_elc,
    toal_grid_resis_due_pipe_stripe_elec,
    prop_size_of_conduc,
    req_size_of_conduc,
    conductor_size_result,
    premitted_grid_resis_val,
    achived_combi_grid_resis,
    grid_resis_result,
    earthingConductorType,
    earthingConductor,
    finalTemprature,
    insulationType,
    marginPercent,
    calcType,
    powerTrafoPowerRating,
    mvVoltageLevel,
    mvCurrent,
    powerTrafoZ,
    faultCurrentAC,
    shortCircuitCurrForConductorSizing,
    systemShortCircuitCurrent,
    diversionFactor,
    sc_currThroughEarthingElectode,
    tc,
    tz,
    lengthOfEarthmat,
    breadthOfEarthmat,
    areaOfEarthmat,
    spacingBetweenTheConductors,
    depth,
    soilResistivity_switchyard,
    surfaceLayerResistivity,
    surfaceLayerThickness,
    refTempForMaterialConstants,
    tempCoeffOfTheMaterial,
    fusingTempOfConductor,
    designAmbientTemperature,
    Ko,
    resisOfConductorAtRefeTemp,
    specificHeatOfMaterial,
    densityOfMaterial,
    thermalCapacityPerUnitVol,
    currentForSizingEarthMatConductor,
    areaOfCrossSectionOfEarthConductor,
    reqAreaEarthingConductorIncludingCorrosion,
    crossSectionOfSelectedConductor,
    tolerableEstep,
    tolerableEtouch,
    selectedConductorWidth,
    selectedConductorThickenss,
    conductorSizingCriteria,
  ]);

  const getEarthingConductor_K = (type, duration) => {
    try {
      if (type === "6A") {
        return _6AData[duration][earthingConductor?.value];
      } else if (type === "6B" || type === "6C") {
        return insulationType?.value[duration][earthingConductor?.value];
      } else if (type === "6D") {
        return finalTemprature?.value[duration][earthingConductor?.value];
      }
    } catch (e) {
      console.log(e);
      return 0;
    }
  };

  const getServiceById = (service_id) => {
    if (firstLoad) {
      axios
        .get(GET_SERVICE_BY_ID(service_id))
        .then((res) => {
          setFirstLoad(false);
          if (res.data.service[0].name) {
            setService(res.data.service);
            setContentLoading(false);
          } else {
            setContentLoading(false);
            handleToast("error", "Service Not Found!");
            props.history.replace("/services");
          }
        })
        .catch((error) => {
          checkSignature(error);
          setFirstLoad(false);
          setContentLoading(false);
          props.history.replace("/services");
        });
    }
  };

  const handleToast = (event_type, message) => {
    return event_type === "success"
      ? toast.success(message)
      : toast.error(message);
  };

  const backBtnHandler = () => {
    return props.history.push("/services");
  };

  const onRefrenceClick = (type) => {
    if (type === "12.2.2") {
      setImgPath(_img1222);
    } else if (type === "10.3") {
      setImgPath(_img103);
    } else if (type === "9.2.2") {
      setImgPath(_img922);
    } else if (type === "9.2.3") {
      setImgPath(_img923);
    } else if (type === "8.6.1") {
      setImgPath(_img861);
    } else if (type === "12.2.2.1") {
      setImgPath(_img12221);
    } else if (type === "geotechnical") {
      setImgPath(_geotechnical);
    } else if (type === "k-formula") {
      setImgPath(_k_formula);
    }
    setImgShow(true);
  };

  return (
    <div className="main-wrapper">
      <Header {...props} />
      {isContentLoading && <MyLoader />}
      {!isContentLoading && (
        <Container id="stringSizer" className="mb-5">
          <Row className="mb-3">
            <Col lg={8} md={12} className="mb-3">
              <div className="custom_card p-4">
                <div className="mb-3 d-flex justify-content-between">
                  <div>
                    <span>
                      <img
                        src={backLogo}
                        alt="back"
                        className="backBtn"
                        onClick={backBtnHandler}
                      />
                    </span>
                    <span className="mx-3 sscalcHeading">
                      {service[0]?.name}
                    </span>
                  </div>
                  <div className="w-25 mt-2">
                    <SingleSelect
                      data={earthingCalcType}
                      value={calcType}
                      placeholder={""}
                      isDisabled={false}
                      isLoading={false}
                      handleChange={setCalcType}
                    />
                  </div>
                </div>

                {calcType.value !== "switchyard" && (
                  <React.Fragment>
                    <div className="mt-4 mb-3 dynamicOverflow">
                      <h6>Design Data:</h6>
                      <div>
                        <table className="table table-striped table-bordered mb-0 ">
                          <thead className="thead-dark">
                            <tr>
                              <th
                                scope="col"
                                className="text-center p-2"
                                style={{ width: "38%" }}
                              >
                                Name
                              </th>
                              <th
                                scope="col"
                                className="text-center p-2 w-25 earthingInputs"
                              >
                                Value
                              </th>
                              <th scope="col" className="text-center p-2">
                                Remarks
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="align-middle">
                                System Designed for Fault Duration{" "}
                              </td>
                              <td className="p-1 align-middle">
                                <SingleSelect
                                  data={faultDuration}
                                  value={fault_duration}
                                  placeholder={""}
                                  isDisabled={false}
                                  isLoading={false}
                                  handleChange={setFaultDuration}
                                />
                              </td>
                              <td className="align-middle"></td>
                            </tr>
                            {/* <tr>
                              <td className="align-middle">
                                Voltage Level of DC array Yard in kV{" "}
                              </td>
                              <td className="p-1 ">
                                <NumericInput
                                  className="form-control"
                                  format={(v) => String(v) + " kV"}
                                  step={0.1}
                                  precision={2}
                                  value={v_level_dc}
                                  onChange={setV_level_dc}
                                />
                              </td>
                              <td className="align-middle"></td>
                            </tr> */}
                            <tr>
                              <td className="align-middle">
                                Soil Resistivity "ρ"
                              </td>
                              <td className="p-1 align-middle">
                                <NumericInput
                                  className="form-control"
                                  format={(v) => String(v) + " ohm.m"}
                                  step={0.1}
                                  precision={2}
                                  value={soil_resistivity}
                                  onChange={setSoil_resistivity}
                                />
                              </td>
                              <td className="align-middle">
                                Geotechnical Report/
                                <span
                                  className="makeLink"
                                  onClick={() =>
                                    onRefrenceClick("geotechnical")
                                  }
                                >
                                  Common Values
                                </span>
                              </td>
                            </tr>
                            {/* <tr>
                          <td className="align-middle" colSpan="3">
                            Bare Conductor with No Risk of Fire or Danger to Any
                            Other Touching or Surrounding Material
                          </td>
                        </tr> */}

                            <tr>
                              <td className="align-middle">
                                Earthing Material Type
                              </td>
                              <td className="p-1 align-middle" colSpan="2">
                                <SingleSelect
                                  data={earthingConductorTypes}
                                  value={earthingConductorType}
                                  placeholder={""}
                                  isDisabled={false}
                                  isLoading={false}
                                  handleChange={(data) => {
                                    setEarthingConductorType(data);
                                    setEarthingConductor(
                                      earthingConductorType.value === "6C"
                                        ? earthinMaterials.slice(0, 2)[0]
                                        : earthinMaterials[0]
                                    );
                                    setInsulationType("");
                                    setFinalTemprature(finalTemp[0]);
                                  }}
                                />
                              </td>
                              {/* <td className="align-middle"></td> */}
                            </tr>

                            <tr>
                              <td className="align-middle">
                                Earthing Material Conductor
                              </td>
                              <td className="p-1 align-middle">
                                <SingleSelect
                                  data={
                                    earthingConductorType.value === "6C"
                                      ? earthinMaterials.slice(0, 2)
                                      : earthinMaterials
                                  }
                                  value={earthingConductor}
                                  placeholder={""}
                                  isDisabled={false}
                                  isLoading={false}
                                  handleChange={setEarthingConductor}
                                />
                              </td>
                              <td className="align-middle"></td>
                            </tr>

                            {(earthingConductorType.value === "6B" ||
                              earthingConductorType.value === "6C") && (
                              <tr>
                                <td className="align-middle">
                                  Insulation Type
                                </td>
                                <td className="p-1 align-middle">
                                  <SingleSelect
                                    data={
                                      insulationTypes[
                                        earthingConductorType.value
                                      ]
                                    }
                                    value={insulationType}
                                    placeholder={"Select..."}
                                    isDisabled={false}
                                    isLoading={false}
                                    handleChange={setInsulationType}
                                  />
                                </td>
                                <td className="align-middle"></td>
                              </tr>
                            )}

                            {earthingConductorType.value === "6D" && (
                              <tr>
                                <td className="align-middle">
                                  Insulation Type
                                </td>
                                <td className="p-1 align-middle">
                                  <SingleSelect
                                    data={finalTemp}
                                    value={finalTemprature}
                                    placeholder={"Select..."}
                                    isDisabled={false}
                                    isLoading={false}
                                    handleChange={setFinalTemprature}
                                  />
                                </td>
                                <td className="align-middle"></td>
                              </tr>
                            )}

                            <tr>
                              <td className="align-middle">
                                Current Rating of Earthing Conductor(k)
                              </td>
                              <td className="p-1 align-middle">
                                <NumericInput
                                  className="form-control"
                                  format={(v) => String(v) + " A/mm2"}
                                  step={0.1}
                                  precision={2}
                                  value={current_rating_e_conductor}
                                  onChange={setCurrent_rating_e_conductor}
                                />
                              </td>
                              <td className="align-middle">
                                <span
                                  className="makeLink"
                                  onClick={() => onRefrenceClick("12.2.2")}
                                >
                                  Cl:12.2.2 & 19.2
                                </span>
                                /
                                <span
                                  className="makeLink"
                                  onClick={() => onRefrenceClick("k-formula")}
                                >
                                  k-formula
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td className="align-middle">
                                Desired Resistance of the Earth Grid
                              </td>
                              <td className="p-1 align-middle">
                                <NumericInput
                                  className="form-control"
                                  format={(v) => String(v) + " ohm"}
                                  step={0.1}
                                  precision={2}
                                  value={resis_earth_grid}
                                  onChange={setResis_earth_grid}
                                />
                              </td>
                              <td className="align-middle">
                                {" "}
                                (&lt; {parseFloat(resis_earth_grid).toFixed(
                                  2
                                )}{" "}
                                ohm)
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div className="mt-4 mb-3 overflow-auto">
                      <h6>Fault Current Calculations:</h6>
                      <div>
                        {calcType.value === "dc" && (
                          <table className="table table-striped table-bordered mb-0 ">
                            <thead className="thead-dark">
                              <tr>
                                <th
                                  scope="col"
                                  className="text-center p-2"
                                  style={{ width: "38%" }}
                                >
                                  Name
                                </th>
                                <th
                                  scope="col"
                                  className="text-center p-2 w-25 earthingInputs"
                                >
                                  Value
                                </th>
                                <th scope="col" className="text-center p-2">
                                  Remarks
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td className="align-middle">
                                  Avg. Isc of Module (as per datasheet)
                                </td>
                                <td className="p-1 align-middle">
                                  <NumericInput
                                    className="form-control"
                                    format={(v) => String(v) + " A"}
                                    step={0.1}
                                    precision={2}
                                    value={isc_of_module}
                                    onChange={(v) => {
                                      setIsc_of_module(v);
                                    }}
                                  />
                                </td>
                                <td className="align-middle"></td>
                              </tr>
                              <tr>
                                <td className="align-middle">Margin (%)</td>
                                <td className="p-1 align-middle">
                                  <NumericInput
                                    className="form-control"
                                    format={(v) => String(v) + " %"}
                                    step={0.1}
                                    precision={2}
                                    value={marginPercent}
                                    onChange={(v) => {
                                      setMarginPercent(v);
                                    }}
                                  />
                                </td>
                                <td className="align-middle"></td>
                              </tr>
                              <tr>
                                <td className="align-middle">
                                  {marginPercent}% Margin over Isc
                                </td>
                                <td className="p-1 align-middle">
                                  <input
                                    type="text"
                                    className="form-control text-danger"
                                    value={String(margin_over_isc) + " A"}
                                    readOnly={true}
                                  />
                                </td>
                                <td className="align-middle"></td>
                              </tr>
                              <tr>
                                <td className="align-middle">
                                  No. of strings connected to CB
                                </td>
                                <td className="p-1 align-middle">
                                  <NumericInput
                                    className="form-control"
                                    format={(v) => String(v) + " A"}
                                    step={0.1}
                                    precision={2}
                                    value={no_of_string_connc_cb}
                                    onChange={(v) => {
                                      setNo_of_string_connc_cb(v);
                                    }}
                                  />
                                </td>
                                <td className="align-middle"></td>
                              </tr>
                              <tr>
                                <td className="align-middle">
                                  No. of CBs connected to Inverter
                                </td>
                                <td className="p-1 align-middle">
                                  <NumericInput
                                    className="form-control"
                                    format={(v) => String(v) + " A"}
                                    step={0.1}
                                    precision={2}
                                    value={no_of_cb_connc_inver}
                                    onChange={(v) => {
                                      setNo_of_cb_connc_inver(v);
                                    }}
                                  />
                                </td>
                                <td className="align-middle"></td>
                              </tr>

                              <tr>
                                <td className="align-middle">
                                  Hence fault current at one inverter{" "}
                                </td>
                                <td className="p-1 align-middle">
                                  <input
                                    type="text"
                                    className="form-control text-danger"
                                    value={String(fault_curr_at_one_inv) + " A"}
                                    readOnly={true}
                                  />
                                </td>
                                <td className="align-middle"></td>
                              </tr>

                              <tr>
                                <td className="align-middle">
                                  Maximum Fault current
                                </td>
                                <td className="p-1 align-middle">
                                  <input
                                    type="text"
                                    className="form-control text-danger"
                                    value={String(max_fault_curr) + " A"}
                                    readOnly={true}
                                  />
                                </td>
                                <td className="align-middle">
                                  For PV yard per block
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        )}

                        {calcType.value === "ac" && (
                          <table className="table table-striped table-bordered mb-0 ">
                            <thead className="thead-dark">
                              <tr>
                                <th
                                  scope="col"
                                  className="text-center p-2"
                                  style={{ width: "38%" }}
                                >
                                  Name
                                </th>
                                <th
                                  scope="col"
                                  className="text-center p-2 w-25 earthingInputs"
                                >
                                  Value
                                </th>
                                <th scope="col" className="text-center p-2">
                                  Remarks
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td className="align-middle">
                                  Power trafo power rating (MVA)
                                </td>
                                <td className="p-1 align-middle">
                                  <NumericInput
                                    className="form-control"
                                    format={(v) => String(v) + " MVA"}
                                    step={0.1}
                                    precision={2}
                                    value={powerTrafoPowerRating}
                                    onChange={(v) => {
                                      setPowerTrafoPowerRating(v);
                                    }}
                                  />
                                </td>
                                <td className="align-middle"></td>
                              </tr>
                              <tr>
                                <td className="align-middle">
                                  MV voltage level (kV)
                                </td>
                                <td className="p-1 align-middle">
                                  <NumericInput
                                    className="form-control"
                                    format={(v) => String(v) + " kV"}
                                    step={0.1}
                                    precision={2}
                                    value={mvVoltageLevel}
                                    onChange={(v) => {
                                      setMvVoltageLevel(v);
                                    }}
                                  />
                                </td>
                                <td className="align-middle"></td>
                              </tr>
                              <tr>
                                <td className="align-middle">MV current (A)</td>
                                <td className="p-1 align-middle">
                                  <input
                                    type="text"
                                    className="form-control text-danger"
                                    value={String(mvCurrent) + " A"}
                                    readOnly={true}
                                  />
                                </td>
                                <td className="align-middle"></td>
                              </tr>
                              <tr>
                                <td className="align-middle">
                                  Power trafo Z (%)
                                </td>
                                <td className="p-1 align-middle">
                                  <NumericInput
                                    className="form-control"
                                    format={(v) => String(v) + " %"}
                                    step={0.1}
                                    precision={2}
                                    value={powerTrafoZ}
                                    onChange={(v) => {
                                      setPowerTrafoZ(v);
                                    }}
                                  />
                                </td>
                                <td className="align-middle"></td>
                              </tr>
                              <tr>
                                <td className="align-middle">
                                  Fault current (A)
                                </td>
                                <td className="p-1 align-middle">
                                  <input
                                    type="text"
                                    className="form-control text-danger"
                                    value={String(faultCurrentAC) + " A"}
                                    readOnly={true}
                                  />
                                </td>
                                <td className="align-middle">For ICR area</td>
                              </tr>
                            </tbody>
                          </table>
                        )}
                      </div>
                    </div>

                    <div className="mt-4 mb-3 overflow-auto">
                      <h6>Conductor Selection:</h6>
                      <div>
                        <table className="table table-striped table-bordered mb-0 ">
                          <thead className="thead-dark">
                            <tr>
                              <th
                                scope="col"
                                className="text-center p-2"
                                style={{ width: "38%" }}
                              >
                                Name
                              </th>
                              <th
                                scope="col"
                                className="text-center p-2 w-25 earthingInputs"
                              >
                                Value
                              </th>
                              <th scope="col" className="text-center p-2">
                                Remarks
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="align-middle" colSpan="3">
                                Calculation for the size earth strips
                              </td>
                            </tr>
                            <tr>
                              <td className="align-middle">
                                Minumum Conductor Cross Section Area "A"
                                required is
                              </td>
                              <td className="p-1 align-middle">
                                <input
                                  className="form-control text-danger"
                                  type="text"
                                  value={String(min_conduc_cross_area) + " mm2"}
                                  readOnly={true}
                                />
                              </td>
                              <td className="align-middle">
                                <span
                                  className="makeLink"
                                  onClick={() => onRefrenceClick("12.2.2.1")}
                                >
                                  As per Clause no. 12.2.2.1
                                </span>
                              </td>
                            </tr>

                            <tr>
                              <td className="align-middle">
                                Area of the conductor required, with allowance
                                for corrosion is
                              </td>
                              <td className="p-1 align-middle">
                                <input
                                  className="form-control align-middle text-danger"
                                  type="text"
                                  value={String(area_of_conduc_req) + " mm2"}
                                  readOnly={true}
                                />
                              </td>
                              <td className="align-middle">
                                <span
                                  className="makeLink d-block"
                                  onClick={() => onRefrenceClick("8.6.1")}
                                >
                                  Cl: 8.6.1 Corrosion Allowance
                                </span>
                                Corrosion Factor ={" "}
                                {soil_resistivity > 100
                                  ? "0%"
                                  : soil_resistivity > 25
                                  ? "15%"
                                  : "30%"}
                              </td>
                            </tr>

                            <tr>
                              <td className="align-middle" colSpan="3">
                                Selection of Earthing Conductor
                              </td>
                            </tr>

                            <tr>
                              <td className="align-middle">
                                Width of the condcutor selected
                              </td>
                              <td className="p-1 align-middle">
                                <NumericInput
                                  className="form-control"
                                  format={(v) => String(v) + " mm"}
                                  step={0.1}
                                  precision={2}
                                  value={width_of_conduc}
                                  onChange={setWidth_of_conduc}
                                />
                              </td>
                              <td className="align-middle"></td>
                            </tr>

                            <tr>
                              <td className="align-middle">
                                Thickness of the conductor selected
                              </td>
                              <td className="p-1 align-middle">
                                <NumericInput
                                  className="form-control"
                                  format={(v) => String(v) + " mm"}
                                  step={0.1}
                                  precision={2}
                                  value={thickness_of_conduc}
                                  onChange={setThickness_of_conduc}
                                />
                              </td>
                              <td className="align-middle"></td>
                            </tr>

                            <tr>
                              <td className="align-middle">
                                Therefore area of the conductor selected
                              </td>
                              <td className="p-1 align-middle">
                                <input
                                  className="form-control text-danger"
                                  type="text"
                                  value={String(area_of_conduc) + " mm2"}
                                  readOnly={true}
                                />
                              </td>
                              <td className="align-middle">
                                {/* <span
                              className="makeLink"
                              onClick={() => onRefrenceClick("8.6.1")}
                            >
                              Cl: 8.6.1 Corrosion Allowance
                            </span> */}
                              </td>
                            </tr>

                            <tr>
                              <td className="align-middle" colSpan="3">
                                Total Earth Electrode Area Considered
                              </td>
                            </tr>

                            <tr>
                              <td className="align-middle">
                                The Maximum permissible current density "I"
                              </td>
                              <td className="p-1 align-middle">
                                <input
                                  className="form-control text-danger"
                                  type="text"
                                  value={
                                    String(max_permi_current_densi) + " A/m2"
                                  }
                                  readOnly={true}
                                />
                              </td>
                              <td className="align-middle">
                                <span
                                  className="makeLink"
                                  onClick={() => onRefrenceClick("10.3")}
                                >
                                  As per Clause no. 10.3
                                </span>
                              </td>
                            </tr>

                            <tr>
                              <td className="align-middle">
                                Minumum Earth Electrode Area = (Fault current/
                                current density)
                              </td>
                              <td className="p-1 align-middle">
                                <input
                                  className="form-control text-danger"
                                  type="text"
                                  value={
                                    String(min_earth_electrode_area) + " m2"
                                  }
                                  readOnly={true}
                                />
                              </td>
                              <td className="align-middle"></td>
                            </tr>

                            <tr>
                              <td className="align-middle">
                                Minumum earth electrode area required, with
                                allowance for corrosion is
                              </td>
                              <td className="p-1 align-middle">
                                <input
                                  className="form-control text-danger"
                                  type="text"
                                  value={
                                    String(min_earth_electrode_area_req) + " m2"
                                  }
                                  readOnly={true}
                                />
                              </td>
                              <td className="align-middle">
                                <span
                                  className="makeLink d-block"
                                  onClick={() => onRefrenceClick("8.6.1")}
                                >
                                  Cl: 8.6.1 Corrosion Allowance
                                </span>
                                Corrosion Factor ={" "}
                                {soil_resistivity > 100
                                  ? "0%"
                                  : soil_resistivity > 25
                                  ? "15%"
                                  : "30%"}
                                {/* {` (IF(soil resistivity(${soil_resistivity}) >100,0,IF(soil resistivity(${soil_resistivity}) >25,0.15,0.3)))`} */}
                              </td>
                            </tr>

                            <tr>
                              <td className="align-middle">
                                length of GI strip considered
                              </td>
                              <td className="p-1 align-middle">
                                <NumericInput
                                  className="form-control"
                                  format={(v) => String(v) + " m"}
                                  step={0.1}
                                  precision={2}
                                  value={len_of_GI_strip_considered}
                                  onChange={setLen_of_GI_strip_considered}
                                />
                              </td>
                              <td className="align-middle">
                                {calcType?.value === "dc"
                                  ? "For PV yard per block"
                                  : "For ICR area"}
                              </td>
                            </tr>

                            <tr>
                              <td className="align-middle">No. of Run</td>
                              <td className="p-1 align-middle">
                                <NumericInput
                                  className="form-control"
                                  step={0.1}
                                  precision={2}
                                  value={no_of_run}
                                  onChange={setNo_of_run}
                                />
                              </td>
                              <td className="align-middle"></td>
                            </tr>

                            <tr>
                              <td className="align-middle">
                                Area of the strip electrode{" "}
                              </td>
                              <td className="p-1 align-middle">
                                <NumericInput
                                  className="form-control"
                                  format={(v) => String(v) + " m2"}
                                  step={0.1}
                                  precision={2}
                                  value={area_of_strip_electrode}
                                  onChange={setArea_of_strip_electrode}
                                />
                              </td>
                              <td className="align-middle">
                                {calcType?.value === "dc"
                                  ? "For PV yard per block"
                                  : "For ICR area"}
                              </td>
                            </tr>

                            <tr>
                              <td className="align-middle">
                                Number of Electrodes
                              </td>
                              <td className="p-1 align-middle">
                                <NumericInput
                                  className="form-control"
                                  format={(v) => String(v) + " No's"}
                                  step={0.1}
                                  precision={2}
                                  value={no_of_electrode}
                                  onChange={setNo_of_electrode}
                                />
                              </td>
                              <td className="align-middle">
                                For Pipe Electrode
                              </td>
                            </tr>
                            <tr>
                              <td className="align-middle">
                                Length of Electrodes
                              </td>
                              <td className="p-1 align-middle">
                                <NumericInput
                                  className="form-control"
                                  format={(v) => String(v) + " m"}
                                  step={0.1}
                                  precision={2}
                                  value={len_of_electrode}
                                  onChange={setLen_of_electrode}
                                />
                              </td>
                              <td className="align-middle"></td>
                            </tr>
                            <tr>
                              <td className="align-middle">
                                Effective Length of Electrode "L"
                              </td>
                              <td className="p-1 align-middle">
                                {/* <NumericInput
                              className="form-control"
                              format={(v) => String(v) + " m"}
                              step={0.1}
                              precision={2}
                              value={effec_len_of_electrode}
                              onChange={setEffec_len_of_electrode}
                            /> */}
                                <input
                                  className="form-control text-danger"
                                  type="text"
                                  value={String(effec_len_of_electrode) + " m"}
                                  readOnly={true}
                                />
                              </td>
                              <td className="align-middle">
                                {`85% of Electrode Length(${len_of_electrode})`}
                              </td>
                            </tr>
                            <tr>
                              <td className="align-middle">
                                Dia of Electrode in Meter "D"
                              </td>
                              <td className="p-1 align-middle">
                                <NumericInput
                                  className="form-control"
                                  format={(v) => String(v) + " m"}
                                  step={0.001}
                                  precision={4}
                                  value={dia_of_electrode}
                                  onChange={setDia_of_electrode}
                                />
                              </td>
                              <td className="align-middle"></td>
                            </tr>
                            <tr>
                              <td className="align-middle">
                                Area of each pipe electrode{" "}
                              </td>
                              <td className="p-1 align-middle">
                                <input
                                  className="form-control text-danger"
                                  type="text"
                                  value={
                                    String(area_of_each_pipe_electrode) + " m2"
                                  }
                                  readOnly={true}
                                />
                              </td>
                              <td className="align-middle"></td>
                            </tr>
                            <tr>
                              <td className="align-middle">
                                Area of Total pipe Electrodes{" "}
                              </td>
                              <td className="p-1 align-middle">
                                <input
                                  className="form-control text-danger"
                                  type="text"
                                  value={
                                    String(area_of_total_pipe_electrode) + " m2"
                                  }
                                  readOnly={true}
                                />
                              </td>
                              <td className="align-middle"></td>
                            </tr>
                            <tr>
                              <td className="align-middle">
                                Total Combined Area of Strip Electrode and pipe
                                electrode
                              </td>
                              <td className="p-1 align-middle">
                                <input
                                  className="form-control text-danger"
                                  type="text"
                                  value={
                                    String(
                                      total_area_of_stripe_and_pipe_electrode
                                    ) + " m2"
                                  }
                                  readOnly={true}
                                />
                              </td>
                              <td className="align-middle"></td>
                            </tr>
                            <tr>
                              <td className="align-middle">
                                The Combine area of electrode should be more
                                than the required area of electrode
                              </td>
                              <td className="p-1 align-middle">
                                <input
                                  className="form-control text-danger"
                                  type="text"
                                  value={
                                    "> " +
                                    String(combine_area_of_electrode) +
                                    " m2"
                                  }
                                  readOnly={true}
                                />
                              </td>
                              <td className="align-middle"></td>
                            </tr>
                            <tr>
                              <td className="align-middle">
                                Current dissipation criteria
                              </td>
                              <td className="p-1 align-middle">
                                <input
                                  className="form-control text-danger"
                                  type="text"
                                  value={is_pipe_stripe_electrode}
                                  readOnly={true}
                                />
                              </td>
                              <td className="align-middle"></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div className="mt-4 mb-3 overflow-auto">
                      <h6>Calculation Of Earth Resistance:</h6>
                      <div>
                        <table className="table table-striped table-bordered mb-0 ">
                          <thead className="thead-dark">
                            <tr>
                              <th
                                scope="col"
                                className="text-center p-2"
                                style={{ width: "38%" }}
                              >
                                Name
                              </th>
                              <th
                                scope="col"
                                className="text-center p-2 w-25 earthingInputs"
                              >
                                Value
                              </th>
                              <th scope="col" className="text-center p-2">
                                Remarks
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="align-middle">
                                Resistance of Strip Electrode is
                              </td>
                              <td className="p-1 align-middle">
                                (100ρ/2πL ) * ln(2L²/wt) ohm
                              </td>
                              <td className="align-middle">
                                {" "}
                                <span
                                  className="makeLink"
                                  onClick={() => onRefrenceClick("9.2.3")}
                                >
                                  As per Cl: 9.2.3
                                </span>
                              </td>
                            </tr>

                            <tr>
                              <td className="align-middle">
                                Depth of Buried Electrode in cm "w"
                              </td>
                              <td className="p-1 align-middle">
                                <NumericInput
                                  className="form-control"
                                  format={(v) => String(v) + " cm"}
                                  step={0.1}
                                  precision={2}
                                  value={depth_buried_electrode}
                                  onChange={setDepth_buried_electrode}
                                />
                              </td>
                              <td className="align-middle"></td>
                            </tr>

                            <tr>
                              <td className="align-middle">
                                Length of Strip "L"
                              </td>
                              <td className="p-1 align-middle">
                                {/* <input
                              className="form-control text-danger"
                              type="text"
                              value={String(len_of_strip) + " cm"}
                              readOnly={true}
                            /> */}
                                <NumericInput
                                  className="form-control"
                                  format={(v) => String(v) + " cm"}
                                  step={0.1}
                                  precision={2}
                                  value={len_of_strip}
                                  onChange={setLen_of_strip}
                                />
                              </td>
                              <td className="align-middle"></td>
                            </tr>
                            <tr>
                              <td className="align-middle">
                                Width of strip in cm "t"
                              </td>
                              <td className="p-1 align-middle">
                                <NumericInput
                                  className="form-control"
                                  format={(v) => String(v) + " cm"}
                                  step={0.1}
                                  precision={2}
                                  value={width_of_strip}
                                  onChange={setWidth_of_strip}
                                />
                              </td>
                              <td className="align-middle"></td>
                            </tr>

                            <tr>
                              <td className="align-middle">
                                Resistance of Strip Electrode "R1"{" "}
                              </td>
                              <td className="p-1 align-middle">
                                <input
                                  className="form-control text-danger"
                                  type="text"
                                  value={
                                    String(resis_of_strip_electrode) + " ohm"
                                  }
                                  readOnly={true}
                                />
                              </td>
                              <td className="align-middle"></td>
                            </tr>
                            <tr>
                              <td className="align-middle">
                                Resistance of GI Pipe/rod Electrode
                              </td>
                              <td className="p-1 align-middle">
                                (100ρ/2πL ) * ln(4L/d) ohm
                              </td>
                              <td className="align-middle">
                                {" "}
                                <span
                                  className="makeLink"
                                  onClick={() => onRefrenceClick("9.2.2")}
                                >
                                  As per Cl: 9.2.2
                                </span>
                              </td>
                            </tr>

                            <tr>
                              <td className="align-middle">
                                Length of electrode below ground "L"
                              </td>
                              <td className="p-1 align-middle">
                                {/* <NumericInput
                              className="form-control"
                              format={(v) => String(v) + " cm"}
                              step={0.1}
                              precision={2}
                              value={len_of_electrode_below_g}
                              onChange={setLen_of_electrode_below_g}
                            /> */}
                                <input
                                  className="form-control text-danger"
                                  type="text"
                                  value={
                                    String(len_of_electrode_below_g) + " cm"
                                  }
                                  readOnly={true}
                                />
                              </td>
                              <td className="align-middle"></td>
                            </tr>

                            <tr>
                              <td className="align-middle">
                                Diameter of electrode in cm "d"
                              </td>
                              <td className="p-1 align-middle">
                                {/* <NumericInput
                              className="form-control"
                              format={(v) => String(v) + " cm"}
                              step={0.1}
                              precision={2}
                              value={diameter_of_electrode}
                              onChange={setDiameter_of_electrode}
                            /> */}
                                <input
                                  className="form-control text-danger"
                                  type="text"
                                  value={String(diameter_of_electrode) + " cm"}
                                  readOnly={true}
                                />
                              </td>
                              <td className="align-middle"></td>
                            </tr>

                            <tr>
                              <td className="align-middle">
                                Resistance of GI pipe "R2"
                              </td>
                              <td className="p-1 align-middle">
                                <input
                                  className="form-control text-danger"
                                  type="text"
                                  value={String(resis_of_gi_pipe) + " ohm"}
                                  readOnly={true}
                                />
                              </td>
                              <td className="align-middle"></td>
                            </tr>

                            <tr>
                              <td className="align-middle">
                                Combine resistance of total considered pipe
                                electrode
                              </td>
                              <td className="p-1 align-middle">
                                <input
                                  className="form-control text-danger"
                                  type="text"
                                  value={
                                    String(combi_resis_of_total_pipe_elc) +
                                    " ohm"
                                  }
                                  readOnly={true}
                                />
                              </td>
                              <td className="align-middle"></td>
                            </tr>

                            <tr>
                              <td className="align-middle">
                                Total Grid Resistance due to pipe and strip
                                electrode "R"
                              </td>
                              <td className="p-1 align-middle">
                                <input
                                  className="form-control text-danger"
                                  type="text"
                                  value={
                                    String(
                                      toal_grid_resis_due_pipe_stripe_elec
                                    ) + " ohm"
                                  }
                                  readOnly={true}
                                />
                              </td>
                              <td className="align-middle"></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div className="mt-4 mb-3 overflow-auto">
                      <h6>
                        Earthing Design Calculation - Results & Conclusions:
                      </h6>
                      <div>
                        <table className="table table-striped table-bordered mb-0 ">
                          <thead className="thead-dark">
                            <tr>
                              <th
                                scope="col"
                                className="text-center p-2"
                                style={{ width: "38%" }}
                              >
                                Name
                              </th>
                              <th
                                scope="col"
                                className="text-center p-2 w-25 earthingInputs"
                              >
                                Value
                              </th>
                              <th scope="col" className="text-center p-2">
                                Remarks
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="align-middle" colSpan="3">
                                Conductor Sizing Calculation :
                              </td>
                            </tr>

                            <tr>
                              <td className="align-middle">
                                Proposed Size of the Conductor
                              </td>
                              <td className="p-1 align-middle">
                                <input
                                  className="form-control text-danger"
                                  type="text"
                                  value={String(prop_size_of_conduc) + " mm2"}
                                  readOnly={true}
                                />
                              </td>
                              <td className="align-middle">
                                {width_of_conduc} x {thickness_of_conduc} sq.mm.
                                GI tape
                              </td>
                            </tr>

                            <tr>
                              <td className="align-middle">
                                Required Size of the Conductor{" "}
                              </td>
                              <td className="p-1 align-middle">
                                <input
                                  className="form-control text-danger"
                                  type="text"
                                  value={String(req_size_of_conduc) + " mm2"}
                                  readOnly={true}
                                />
                              </td>
                              <td className="align-middle"></td>
                            </tr>
                            <tr>
                              <td className="align-middle">Result</td>
                              <td className="p-1 align-middle">
                                <input
                                  className="form-control text-danger"
                                  type="text"
                                  value={conductor_size_result}
                                  readOnly={true}
                                />
                              </td>
                              <td className="align-middle"></td>
                            </tr>

                            <tr>
                              <td className="align-middle" colSpan="3">
                                Grid Resistance Calculation:
                              </td>
                            </tr>

                            <tr>
                              <td className="align-middle">
                                Permitted Grid Resistance(Rgp) value
                              </td>
                              <td className="p-1 align-middle">
                                <input
                                  className="form-control text-danger"
                                  type="text"
                                  value={
                                    String(premitted_grid_resis_val) + " ohm"
                                  }
                                  readOnly={true}
                                />
                              </td>
                              <td className="align-middle"></td>
                            </tr>

                            <tr>
                              <td className="align-middle">
                                Achieved Combined Grid Resistance(Rgc)
                              </td>
                              <td className="p-1 align-middle">
                                <input
                                  className="form-control text-danger"
                                  type="text"
                                  value={
                                    String(achived_combi_grid_resis) +
                                    " < 1.0 Ohms…"
                                  }
                                  readOnly={true}
                                />
                              </td>
                              <td className="align-middle"></td>
                            </tr>
                            <tr>
                              <td className="align-middle">Result</td>
                              <td className="p-1 align-middle">
                                <input
                                  className="form-control text-danger"
                                  type="text"
                                  value={grid_resis_result}
                                  readOnly={true}
                                />
                              </td>
                              <td className="align-middle"></td>
                            </tr>

                            <tr>
                              <td className="align-middle" colSpan="3">
                                <span className="d-block">
                                  Current dissipation criteria:{" "}
                                  {is_pipe_stripe_electrode === "OK" ? (
                                    <span className="text-success">Pass</span>
                                  ) : (
                                    <span className="text-danger">Fail</span>
                                  )}
                                </span>
                                <span className="d-block">
                                  Conductor size criteria:{" "}
                                  {conductor_size_result === "Safe" ? (
                                    <span className="text-success">Pass</span>
                                  ) : (
                                    <span className="text-danger">Fail</span>
                                  )}
                                </span>
                                <span className="d-block">
                                  Grid resistance criteria:{" "}
                                  {grid_resis_result === "Safe" ? (
                                    <span className="text-success">Pass</span>
                                  ) : (
                                    <span className="text-danger">Fail</span>
                                  )}
                                </span>
                                <span className="d-block">
                                  Overall:{" "}
                                  {is_pipe_stripe_electrode === "OK" &&
                                  conductor_size_result === "Safe" &&
                                  conductor_size_result === "Safe" ? (
                                    <span className="text-success">Pass</span>
                                  ) : (
                                    <span className="text-danger">Fail</span>
                                  )}
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </React.Fragment>
                )}

                {calcType.value === "switchyard" && (
                  <React.Fragment>
                    <div className="mt-4 mb-3 dynamicOverflow">
                      <h6>Design Data:</h6>
                      <div>
                        <table className="table table-striped table-bordered mb-0 ">
                          <thead className="thead-dark">
                            <tr>
                              <th scope="col" className="text-center p-2 w-50">
                                Name
                              </th>
                              <th
                                scope="col"
                                className="text-center p-2 w-25 earthingInputs"
                              >
                                Value
                              </th>
                              <th scope="col" className="text-center p-2">
                                Remarks
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="align-middle">
                                Short circuit Current for conductor sizing (Is)
                              </td>
                              <td className="p-1 ">
                                <NumericInput
                                  className="form-control"
                                  format={(v) => String(v) + " kA"}
                                  step={0.1}
                                  precision={2}
                                  value={shortCircuitCurrForConductorSizing}
                                  onChange={
                                    setShortCircuitCurrForConductorSizing
                                  }
                                />
                              </td>
                              <td className="align-middle"></td>
                            </tr>
                            <tr>
                              <td className="align-middle">
                                System Short circuit current
                              </td>
                              <td className="p-1 align-middle">
                                <input
                                  className="form-control text-danger"
                                  value={
                                    String(systemShortCircuitCurrent) + " A"
                                  }
                                  disabled={true}
                                />
                              </td>
                              <td className="align-middle"></td>
                            </tr>
                            <tr>
                              <td className="align-middle">Diversion Factor</td>
                              <td className="p-1 align-middle">
                                <NumericInput
                                  className="form-control"
                                  // format={(v) => String(v) + " oh"}
                                  step={0.1}
                                  precision={2}
                                  value={diversionFactor}
                                  onChange={setDiversionFactor}
                                />
                              </td>
                              <td className="align-middle"></td>
                            </tr>
                            <tr>
                              <td className="align-middle">
                                Short circuit Current through earthing electode{" "}
                              </td>
                              <td className="p-1 align-middle">
                                <input
                                  className="form-control text-danger"
                                  value={
                                    String(sc_currThroughEarthingElectode) +
                                    " kA"
                                  }
                                  disabled={true}
                                />
                              </td>
                              <td className="align-middle"></td>
                            </tr>
                            <tr>
                              <td className="align-middle">
                                Durtion of fault for calculation of the
                                X-sectional area of the conductor (Tc)
                              </td>
                              <td className="p-1 align-middle">
                                <NumericInput
                                  className="form-control"
                                  format={(v) => String(v) + " sec"}
                                  step={0.1}
                                  precision={2}
                                  value={tc}
                                  onChange={setTC}
                                />
                              </td>
                              <td className="align-middle"></td>
                            </tr>
                            <tr>
                              <td className="align-middle">
                                Duration of fault Current for Calculation of
                                Step and Touch Pottentials (Tz)
                              </td>
                              <td className="p-1 align-middle">
                                <NumericInput
                                  className="form-control"
                                  format={(v) => String(v) + " sec"}
                                  step={0.1}
                                  precision={2}
                                  value={tz}
                                  onChange={setTZ}
                                />
                              </td>
                              <td className="align-middle"></td>
                            </tr>
                            <tr>
                              <td className="align-middle">
                                Length of Earthmat
                              </td>
                              <td className="p-1 align-middle">
                                <NumericInput
                                  className="form-control"
                                  format={(v) => String(v) + " m"}
                                  step={0.1}
                                  precision={2}
                                  value={lengthOfEarthmat}
                                  onChange={setLengthOfEarthmat}
                                />
                              </td>
                              <td className="align-middle"></td>
                            </tr>
                            <tr>
                              <td className="align-middle">
                                Breadth of Earthmat
                              </td>
                              <td className="p-1 align-middle">
                                <NumericInput
                                  className="form-control"
                                  format={(v) => String(v) + " m"}
                                  step={0.1}
                                  precision={2}
                                  value={breadthOfEarthmat}
                                  onChange={setBreadthOfEarthmat}
                                />
                              </td>
                              <td className="align-middle"></td>
                            </tr>
                            <tr>
                              <td className="align-middle">Area of Earthmat</td>
                              <td className="p-1 align-middle">
                                <input
                                  className="form-control text-danger"
                                  value={String(areaOfEarthmat) + " Sq. mm"}
                                  disabled={true}
                                />
                              </td>
                              <td className="align-middle"></td>
                            </tr>
                            <tr>
                              <td className="align-middle">
                                Spacing between the conductors
                              </td>
                              <td className="p-1 align-middle">
                                <NumericInput
                                  className="form-control"
                                  format={(v) => String(v) + " m"}
                                  step={0.1}
                                  precision={2}
                                  value={spacingBetweenTheConductors}
                                  onChange={setSpacingBetweenTheConductors}
                                />
                              </td>
                              <td className="align-middle"></td>
                            </tr>
                            <tr>
                              <td className="align-middle">Depth</td>
                              <td className="p-1 align-middle">
                                <NumericInput
                                  className="form-control"
                                  format={(v) => String(v) + " m"}
                                  step={0.1}
                                  precision={2}
                                  value={depth}
                                  onChange={setDepth}
                                />
                              </td>
                              <td className="align-middle"></td>
                            </tr>
                            <tr>
                              <td className="align-middle">
                                Soil Resistivity (F)
                              </td>
                              <td className="p-1 align-middle">
                                <NumericInput
                                  className="form-control"
                                  format={(v) => String(v) + " ohm. m"}
                                  step={0.1}
                                  precision={2}
                                  value={soilResistivity_switchyard}
                                  onChange={setSoilResistivity_switchyard}
                                />
                              </td>
                              <td className="align-middle"></td>
                            </tr>
                            <tr>
                              <td className="align-middle">
                                Surface layer resistivity (Fs)
                              </td>
                              <td className="p-1 align-middle">
                                <NumericInput
                                  className="form-control"
                                  format={(v) => String(v) + " ohm. m"}
                                  step={0.1}
                                  precision={2}
                                  value={surfaceLayerResistivity}
                                  onChange={setSurfaceLayerResistivity}
                                />
                              </td>
                              <td className="align-middle"></td>
                            </tr>
                            <tr>
                              <td className="align-middle">
                                Surface layer thickness (Hs)
                              </td>
                              <td className="p-1 align-middle">
                                <NumericInput
                                  className="form-control"
                                  format={(v) => String(v) + " m"}
                                  step={0.1}
                                  precision={4}
                                  value={surfaceLayerThickness}
                                  onChange={setSurfaceLayerThickness}
                                />
                              </td>
                              <td className="align-middle"></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div className="mt-4 mb-3 dynamicOverflow">
                      <h6>Sizing of Earthing Conductor:</h6>
                      <div>
                        <table className="table table-striped table-bordered mb-0 ">
                          <thead className="thead-dark">
                            <tr>
                              <th scope="col" className="text-center p-2 w-50">
                                Name
                              </th>
                              <th
                                scope="col"
                                className="text-center p-2 w-25 earthingInputs"
                              >
                                Value
                              </th>
                              <th scope="col" className="text-center p-2">
                                Remarks
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="align-middle">
                                Type of earthing Conductor
                              </td>
                              <td className="p-1 align-middle">
                                M.S. FLAT Steel, 1020
                              </td>
                              <td className="align-middle"></td>
                            </tr>

                            <tr>
                              <td className="align-middle">
                                Reference temperature for material constants
                                (Tr)
                              </td>
                              <td className="p-1 align-middle">
                                <NumericInput
                                  className="form-control"
                                  format={(v) => String(v) + " °C"}
                                  step={0.1}
                                  precision={2}
                                  value={refTempForMaterialConstants}
                                  onChange={setRefTempForMaterialConstants}
                                />
                              </td>
                              <td className="align-middle"></td>
                            </tr>

                            <tr>
                              <td className="align-middle">
                                Temperature coefficient of the material (α)
                              </td>
                              <td className="p-1 align-middle">
                                <NumericInput
                                  className="form-control"
                                  format={(v) => String(v) + " per °C"}
                                  step={0.1}
                                  precision={4}
                                  value={tempCoeffOfTheMaterial}
                                  onChange={setRefTempCoeffOfTheMaterial}
                                />
                              </td>
                              <td className="align-middle">IEEE 80</td>
                            </tr>

                            <tr>
                              <td className="align-middle">
                                Fusing temperature of conductor (Tm)
                              </td>
                              <td className="p-1 align-middle">
                                <NumericInput
                                  className="form-control"
                                  format={(v) => String(v) + " °C"}
                                  step={0.1}
                                  precision={2}
                                  value={fusingTempOfConductor}
                                  onChange={setFusingTempOfConductor}
                                />
                              </td>
                              <td className="align-middle"></td>
                            </tr>

                            <tr>
                              <td className="align-middle">
                                Design ambient temperature (Ta)
                              </td>
                              <td className="p-1 align-middle">
                                <NumericInput
                                  className="form-control"
                                  format={(v) => String(v) + " °C"}
                                  step={0.1}
                                  precision={2}
                                  value={designAmbientTemperature}
                                  onChange={setDesignAmbientTemperature}
                                />
                              </td>
                              <td className="align-middle"></td>
                            </tr>

                            <tr>
                              <td className="align-middle">
                                Ko = ( 1 / α ) - Tr
                              </td>
                              <td className="p-1 align-middle">
                                <input
                                  className="form-control text-danger"
                                  value={String(Ko) + " °C"}
                                  disabled={true}
                                />
                              </td>
                              <td className="align-middle"></td>
                            </tr>
                            <tr>
                              <td className="align-middle">
                                Resistivity of conductor at reference
                                temperature (ρr)
                              </td>
                              <td className="p-1 align-middle">
                                <NumericInput
                                  className="form-control"
                                  format={(v) => String(v) + " µΩ-cm"}
                                  step={0.1}
                                  precision={2}
                                  value={resisOfConductorAtRefeTemp}
                                  onChange={setResisOfConductorAtRefeTemp}
                                />
                              </td>
                              <td className="align-middle"></td>
                            </tr>

                            <tr>
                              <td className="align-middle">
                                Specific heat of material (SH)
                              </td>
                              <td className="p-1 align-middle">
                                <NumericInput
                                  className="form-control"
                                  format={(v) => String(v) + " Cal/gm/°C"}
                                  step={0.1}
                                  precision={4}
                                  value={specificHeatOfMaterial}
                                  onChange={setSpecificHeatOfMaterial}
                                />
                              </td>
                              <td className="align-middle"></td>
                            </tr>
                            <tr>
                              <td className="align-middle">
                                Density of material (SW)
                              </td>
                              <td className="p-1 align-middle">
                                <NumericInput
                                  className="form-control"
                                  format={(v) => String(v) + " gm/cc"}
                                  step={0.1}
                                  precision={4}
                                  value={densityOfMaterial}
                                  onChange={setDensityOfMaterial}
                                />
                              </td>
                              <td className="align-middle"></td>
                            </tr>

                            <tr>
                              <td className="align-middle">
                                Thermal capacity per unit volume (4.184 x SH x
                                SW) TCAP
                              </td>
                              <td className="p-1 align-middle">
                                <input
                                  className="form-control text-danger"
                                  value={
                                    String(thermalCapacityPerUnitVol) +
                                    " J/cm³/°C"
                                  }
                                  disabled={true}
                                />
                              </td>
                              <td className="align-middle"></td>
                            </tr>

                            <tr>
                              <td className="align-middle">
                                Current for sizing of earth mat conductor (I)
                              </td>
                              <td className="p-1 align-middle">
                                <input
                                  className="form-control text-danger"
                                  value={
                                    String(currentForSizingEarthMatConductor) +
                                    " kA"
                                  }
                                  disabled={true}
                                />
                              </td>
                              <td className="align-middle"></td>
                            </tr>

                            <tr>
                              <td className="align-middle">
                                Area of cross section of earth conductor (A) =
                                {` √I *[ {(Tc * α * ρr * 1e4) / Tcap}/ln
                                {(Tm - Ta) / (1 + K0 + Ta)}]
                                `}
                              </td>
                              <td className="p-1 align-middle">
                                <input
                                  className="form-control text-danger"
                                  value={
                                    String(areaOfCrossSectionOfEarthConductor) +
                                    " mm2"
                                  }
                                  disabled={true}
                                />
                              </td>
                              <td className="align-middle"></td>
                            </tr>

                            <tr>
                              <td className="align-middle">
                                Required Area of earthing Conductor including
                                Corrosion Allowance
                              </td>
                              <td className="p-1 align-middle">
                                <input
                                  className="form-control text-danger"
                                  value={String(
                                    reqAreaEarthingConductorIncludingCorrosion
                                  )}
                                  disabled={true}
                                />
                              </td>
                              <td className="align-middle">
                                <span
                                  className="makeLink d-block"
                                  onClick={() => onRefrenceClick("8.6.1")}
                                >
                                  Cl: 8.6.1 Corrosion Allowance
                                </span>
                                <span>
                                  Corrosion Factor ={" "}
                                  {Number(soilResistivity_switchyard) > 100
                                    ? "0%"
                                    : Number(soilResistivity_switchyard) > 25
                                    ? "15%"
                                    : "30%"}
                                </span>
                              </td>
                            </tr>

                            <tr>
                              <td className="align-middle">
                                Selected conductor width
                              </td>
                              <td className="p-1 align-middle">
                                <NumericInput
                                  className="form-control"
                                  format={(v) => String(v) + " mm"}
                                  step={0.1}
                                  precision={2}
                                  value={selectedConductorWidth}
                                  onChange={setSelectedConductorWidth}
                                />
                              </td>
                              <td className="align-middle"></td>
                            </tr>

                            <tr>
                              <td className="align-middle">
                                Selected conductor thickness
                              </td>
                              <td className="p-1 align-middle">
                                <NumericInput
                                  className="form-control"
                                  format={(v) => String(v) + " mm"}
                                  step={0.1}
                                  precision={2}
                                  value={selectedConductorThickenss}
                                  onChange={setSelectedConductorThickenss}
                                />
                              </td>
                              <td className="align-middle"></td>
                            </tr>

                            <tr>
                              <td className="align-middle">
                                Cross section of selected conductor
                              </td>
                              <td className="p-1 align-middle">
                                <input
                                  className="form-control text-danger"
                                  value={
                                    String(crossSectionOfSelectedConductor) +
                                    " mm2"
                                  }
                                  disabled={true}
                                />
                              </td>
                              <td className="align-middle"></td>
                            </tr>

                            <tr>
                              <td className="align-middle">
                                Conductor sizing criteria
                              </td>
                              <td className="p-1 align-middle">
                                <input
                                  className="form-control text-danger"
                                  value={String(conductorSizingCriteria)}
                                  disabled={true}
                                />
                              </td>
                              <td className="align-middle"></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div className="mt-4 mb-3 dynamicOverflow">
                      <h6>
                        Calculation for Tolerable Touch and Step Potential:
                      </h6>
                      <div>
                        <table className="table table-striped table-bordered mb-0 ">
                          <thead className="thead-dark">
                            <tr>
                              <th scope="col" className="text-center p-2 w-50">
                                Name
                              </th>
                              <th
                                scope="col"
                                className="text-center p-2 w-25 earthingInputs"
                              >
                                Value
                              </th>
                              <th scope="col" className="text-center p-2">
                                Remarks
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="align-middle">
                                The reduction factor (de-rating factor) Cs can
                                be approximated by the equation
                              </td>
                              <td className="p-1 align-middle" colSpan={2}>
                                {`1-[{0.09(1-(F/Fs)}/(2*Hs+0.09)]`}
                              </td>
                            </tr>

                            <tr>
                              <td className="align-middle">Cs(Hs)</td>
                              <td className="p-1 align-middle">
                                <input
                                  className="form-control text-danger"
                                  value={String(cs_Hs)}
                                  disabled={true}
                                />
                              </td>
                              <td className="align-middle"></td>
                            </tr>
                            <tr>
                              <td className="align-middle">
                                Tolerable Estep = (1000+6*Cs(Hs)*Fs)*0.116/√Tz
                              </td>
                              <td className="p-1 align-middle">
                                <input
                                  className="form-control text-danger"
                                  value={String(tolerableEstep) + " Volts"}
                                  disabled={true}
                                />
                              </td>
                              <td className="align-middle"></td>
                            </tr>
                            <tr>
                              <td className="align-middle">
                                Tolerable Etouch =
                                (1000+1.5*Cs(Hs)*Fs)*0.116/√Tz
                              </td>
                              <td className="p-1 align-middle">
                                <input
                                  className="form-control text-danger"
                                  value={String(tolerableEtouch) + " Volts"}
                                  disabled={true}
                                />
                              </td>
                              <td className="align-middle"></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </React.Fragment>
                )}
              </div>
            </Col>

            <Col lg={4} md={12}>
              <div className="custom_card p-4">
                <div>
                  <h2 className="my-2">Help</h2>

                  <div>
                    <b className="text-justify my-3 text-bold">
                      Reference Standard IS:{" "}
                      <a href={IS3043PDF} download>
                        3043
                      </a>
                    </b>

                    <p className="text-justify my-3">
                      <b>What is {service[0]?.name} service?</b>
                      <br />
                      {service[0]?.description}
                    </p>

                    <p className="my-3 text-justify">
                      <b>How to use {service[0]?.name} service?</b>
                      <br />
                      {service[0]?.how_to_use}
                    </p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      )}

      <ImageModal
        img_path={imgPath}
        show={imgShow}
        onHide={() => setImgShow(false)}
      />

      <ToastContainer />

      <Footer />
    </div>
  );
};

export default EarthinCalcService;
