import axios from "axios";
import React, { useEffect, useState } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { GET_SERVICE_BY_ID } from "../shared/ApiURLs";
import { checkSignature, stdLocalTime, tooltipTheme } from "../utils/Helpers";
import MyLoader from "../components/ContentLoader";
import { toast, ToastContainer } from "react-toastify";
import backLogo from "../assets/images/back.png";
import infoIcon from "../assets/images/info.png";
import schematic from "../assets/images/schematic.png";
import {
  Backdrop,
  CircularProgress,
  Container,
  makeStyles,
  Button,
  MuiThemeProvider,
  Tooltip,
  Checkbox,
} from "@material-ui/core";
import { Col, Row } from "react-bootstrap";
import { SingleSelect } from "../components/SingleSelect";
import { timeZones } from "../utils/timeZones";
import LineChart from "../shared/LineChart";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const ShadingCalcService = (props) => {
  const d = new Date();
  const year = d.getFullYear();

  const classes = useStyles();
  const [service, setService] = useState([]);
  const [loading, setLoading] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const [isContentLoading, setContentLoading] = useState(true);

  const [lat, setLat] = useState("");
  const [lon, setLon] = useState("");
  const [timezone, setTimezone] = useState({
    value: "+05.50",
    label: "Asia/Kolkata",
  });
  const [refLon, setRefLon] = useState("");
  const [dayOfYear, setDayOfYear] = useState(354);
  const [collectorWidth, setCollectorWidth] = useState("");
  const [isSeasonalTilt, setIsSeasonalTilt] = useState(false);
  const [designTilt, setDesignTilt] = useState("");
  const [selectedTilt, setSelectedTilt] = useState("");
  const [shadowFreeHrs, setShadowFreeHrs] = useState("");
  const [gap, setGap] = useState("");
  const [pitch, setPitch] = useState("");
  const [GCR, setGCR] = useState("");
  const [lat_a, setLat_a] = useState(0);
  const [lat_b, setLat_b] = useState(0);
  const [declinationAngle, setDeclinationAngle] = useState(0);
  const [B, setB] = useState(0);
  const [ET, setET] = useState(0);
  const [seasonalTilts, setSeasonalTilts] = useState([0, 0, 0]);
  const [h, setH] = useState(0);
  const [hourAngle, setHourAngle] = useState(0);
  const [solarEleAng, setSolarEleAng] = useState(0);
  const [southWestVal, setSouthWestVal] = useState(0);
  const [shadowLenData, setShadowLenData] = useState([]);
  const [solarEleWarning, setSolarEleWarning] = useState(false);

  useEffect(() => {
    getServiceById(props.match.params.service_id);
    setDayOfYear(getDay());
    setRefLon((parseFloat(timezone.value) * 15).toFixed(2));
    setDeclinationAngle(
      23.45 * Math.sin(((2 * Math.PI) / 365) * (dayOfYear - 81))
    );
    setLat_a(parseFloat(lat) ? parseFloat(lat) - 23.45 : 0 - 23.45);
    setLat_b(parseFloat(lat) ? parseFloat(lat) + 23.45 : 0 + 23.45);
    setB(((2 * Math.PI) / 364) * (dayOfYear - 81));
    setET(9.87 * Math.sin(2 * B) - 7.53 * Math.cos(B) - 1.5 * Math.sin(B));

    if (parseFloat(lat)) {
      let lat_o = parseFloat(lat);
      let st1 =
        Math.abs((lat_o + lat_o) / 2) < 5
          ? 5 * Math.sign((lat_o + lat_o) / 2)
          : (lat_o + lat_o) / 2;
      let st2 =
        Math.abs((lat_a + lat_o) / 2) < 5
          ? 5 * Math.sign((lat_a + lat_o) / 2)
          : (lat_a + lat_o) / 2;
      let st3 =
        Math.abs((lat_b + lat_o) / 2) < 5
          ? 5 * Math.sign((lat_b + lat_o) / 2)
          : (lat_b + lat_o) / 2;

      setSeasonalTilts(
        [
          Math.round(Math.abs(st1)),
          Math.round(Math.abs(st2)),
          Math.round(Math.abs(st3)),
        ].sort((a, b) => b - a)
      );
    }

    setDesignTilt(
      isSeasonalTilt
        ? seasonalTilts[0] * Math.sign(parseFloat(lat))
        : seasonalTilts[1] * Math.sign(parseFloat(lat))
    );

    if (parseFloat(collectorWidth) && parseInt(selectedTilt)) {
      setH(
        Math.abs(
          parseFloat(collectorWidth) *
            Math.sin((parseInt(selectedTilt) * Math.PI) / 180)
        )
      );
    }

    if (parseFloat(shadowFreeHrs)) {
      setHourAngle(15 * parseFloat(shadowFreeHrs));
    }

    if (parseFloat(lat) && parseFloat(hourAngle)) {
      let eleAngle =
        (180 / Math.PI) *
        Math.asin(
          Math.sin((declinationAngle * Math.PI) / 180) *
            Math.sin((parseFloat(lat) * Math.PI) / 180) +
            Math.cos((declinationAngle * Math.PI) / 180) *
              Math.cos((parseFloat(lat) * Math.PI) / 180) *
              Math.cos((hourAngle * Math.PI) / 180)
        );
      setSolarEleAng(eleAngle);

      if (solarEleAng < 0) {
        // toast.info(
        //   "Please lower the value of Shadow Free Hours it's exceeding limit"
        // );
        setGap("Error");
        setPitch("Error");
        setGCR("Error");
        setSolarEleWarning(true);
      } else {
        setGap("");
        setPitch("");
        setGCR("");
        setSolarEleWarning(false);
      }
    }

    if (solarEleAng > 0) {
      let southWstVal =
        hourAngle < 0
          ? -180 +
            (180 / Math.PI) *
              Math.acos(
                (Math.sin((declinationAngle * Math.PI) / 180) *
                  Math.cos((parseFloat(lat) * Math.PI) / 180) -
                  Math.cos((declinationAngle * Math.PI) / 180) *
                    Math.sin((parseFloat(lat) * Math.PI) / 180) *
                    Math.cos((hourAngle * Math.PI) / 180)) /
                  Math.cos((solarEleAng * Math.PI) / 180)
              )
          : 180 -
            (180 / Math.PI) *
              Math.acos(
                (Math.sin((declinationAngle * Math.PI) / 180) *
                  Math.cos((parseFloat(lat) * Math.PI) / 180) -
                  Math.cos((declinationAngle * Math.PI) / 180) *
                    Math.sin((parseFloat(lat) * Math.PI) / 180) *
                    Math.cos((hourAngle * Math.PI) / 180)) /
                  Math.cos((solarEleAng * Math.PI) / 180)
              );

      setSouthWestVal(southWstVal);

      let gap_ =
        (h / Math.tan((solarEleAng * Math.PI) / 180)) *
        Math.cos((southWestVal * Math.PI) / 180) *
        Math.sign(parseFloat(lat));

      let pitch_ =
        gap_ +
        parseFloat(collectorWidth) * Math.cos((selectedTilt * Math.PI) / 180);

      setGap(gap_.toFixed(2));
      setPitch(parseFloat(pitch_).toFixed(2));
      setGCR(((1 - gap_ / pitch_) * 100).toFixed(2));
    }

    // console.log(
    //   dayOfYear,
    //   declinationAngle,
    //   parseFloat(lat),
    //   `seasonal tilt: ${isSeasonalTilt}`,
    //   lat_a,
    //   lat_b,
    //   B,
    //   ET,
    //   designTilt,
    //   h,
    //   selectedTilt,
    //   parseFloat(shadowFreeHrs),
    //   hourAngle,
    //   solarEleAng,
    //   southWestVal
    // );
    // console.log(seasonalTilts);
  }, [
    timezone,
    dayOfYear,
    declinationAngle,
    lat,
    lat_a,
    lat_b,
    B,
    isSeasonalTilt,
    h,
    collectorWidth,
    selectedTilt,
    shadowFreeHrs,
    solarEleAng,
    southWestVal,
    hourAngle,
  ]);

  const getServiceById = (service_id) => {
    if (firstLoad) {
      axios
        .get(GET_SERVICE_BY_ID(service_id))
        .then((res) => {
          setFirstLoad(false);
          if (res.data.service[0].name) {
            setService(res.data.service);
            setContentLoading(false);
          } else {
            setContentLoading(false);
            handleToast("error", "Service Not Found!");
            props.history.replace("/services");
          }
        })
        .catch((error) => {
          checkSignature(error);
          setFirstLoad(false);
          setContentLoading(false);
          props.history.replace("/services");
        });
    }
  };

  const handleToast = (event_type, message) => {
    return event_type === "success"
      ? toast.success(message)
      : toast.error(message);
  };

  const backBtnHandler = () => {
    return props.history.push("/services");
  };

  const getDay = () => {
    if (parseFloat(lat) < 0) {
      return new Date(year, 1, 29).getDate() === 29 ? 174 : 173;
    } else {
      return new Date(year, 1, 29).getDate() === 29 ? 356 : 355;
    }
  };

  const handleTextInputChange = (event, type) => {
    var regExp = /[a-zA-Z]/g;

    if (regExp.test(event.target.value)) return;
    if (type === "lat") {
      setLat(event.target.value);
    } else if (type === "lon") {
      setLon(event.target.value);
    } else if (type === "collector") {
      setCollectorWidth(event.target.value);
    } else if (type === "tilt") {
      setSelectedTilt(event.target.value);
    } else if (type === "freeHrs") {
      setShadowFreeHrs(event.target.value);
    }
  };

  const calcShadowLength = () => {
    let shadowLenArr = [];
    if (!lon) {
      return handleToast("error", "Please enter longitude!");
    }

    stdLocalTime.forEach((time) => {
      let solarTime =
        (4 * (parseFloat(lon) - parseFloat(refLon)) + ET) / 60 + time;

      let hrAngle = 15 * (solarTime - 12);

      let solarElAngle =
        (180 / Math.PI) *
        Math.asin(
          Math.sin((declinationAngle * Math.PI) / 180) *
            Math.sin((parseFloat(lat) * Math.PI) / 180) +
            Math.cos((declinationAngle * Math.PI) / 180) *
              Math.cos((parseFloat(lat) * Math.PI) / 180) *
              Math.cos((hrAngle * Math.PI) / 180)
        );

      let gamma =
        hrAngle < 0
          ? -180 +
            (180 / Math.PI) *
              Math.acos(
                (Math.sin((declinationAngle * Math.PI) / 180) *
                  Math.cos((parseFloat(lat) * Math.PI) / 180) -
                  Math.cos((declinationAngle * Math.PI) / 180) *
                    Math.sin((parseFloat(lat) * Math.PI) / 180) *
                    Math.cos((hrAngle * Math.PI) / 180)) /
                  Math.cos((solarElAngle * Math.PI) / 180)
              )
          : 180 -
            (180 / Math.PI) *
              Math.acos(
                (Math.sin((declinationAngle * Math.PI) / 180) *
                  Math.cos((parseFloat(lat) * Math.PI) / 180) -
                  Math.cos((declinationAngle * Math.PI) / 180) *
                    Math.sin((parseFloat(lat) * Math.PI) / 180) *
                    Math.cos((hrAngle * Math.PI) / 180)) /
                  Math.cos((solarElAngle * Math.PI) / 180)
              );

      let shadowLen =
        (h / Math.tan((solarElAngle * Math.PI) / 180)) *
          Math.cos((gamma * Math.PI) / 180) *
          Math.sign(parseFloat(lat)) <
        0
          ? null
          : (h / Math.tan((solarElAngle * Math.PI) / 180)) *
            Math.cos((gamma * Math.PI) / 180) *
            Math.sign(parseFloat(lat));

      shadowLenArr.push(shadowLen);
    });

    setShadowLenData(shadowLenArr);
  };

  const resetData = () => {
    setLat("");
    setLon("");
    setTimezone({
      value: "+05.50",
      label: "Asia/Kolkata",
    });
    setRefLon("");
    setDayOfYear(354);
    setCollectorWidth("");
    setIsSeasonalTilt(false);
    setDesignTilt("");
    setSelectedTilt("");
    setShadowFreeHrs("");
    setGap("");
    setPitch("");
    setGCR("");
    setLat_a(0);
    setLat_b(0);
    setDeclinationAngle(0);
    setET(0);
    setSeasonalTilts([0, 0, 0]);
    setH(0);
    setHourAngle(0);
    setSolarEleAng(0);
    setSouthWestVal(0);
    setShadowLenData([]);
    setSolarEleWarning(false);
  };

  return (
    <div className="main-wrapper">
      <Header {...props} />
      {isContentLoading && <MyLoader />}
      {!isContentLoading && (
        <Container id="stringSizer" className="mb-5">
          <Row className="mb-3">
            <Col lg={7} md={12} className="mb-3">
              <div className="custom_card p-4">
                <div className="mb-3 d-flex justify-content-between">
                  <div>
                    <span>
                      <img
                        src={backLogo}
                        alt="back"
                        className="backBtn"
                        onClick={backBtnHandler}
                      />
                    </span>
                    <span className="mx-3 sscalcHeading">
                      {service[0]?.name}
                    </span>
                  </div>

                  <button
                    className="btn btn-danger h-50 mt-2"
                    onClick={resetData}
                  >
                    Reset
                  </button>
                </div>

                <div>
                  <Row>
                    <Col lg={4} md={12}>
                      <div className="d-flex justify-content-between mb-2">
                        <h6>Latitude (°):</h6>
                        <span className="">
                          <MuiThemeProvider theme={tooltipTheme}>
                            <Tooltip
                              title={`For Northern hemisphere, latitude is positive`}
                            >
                              <img
                                src={infoIcon}
                                className="infoIcon px-1"
                                alt="info"
                                style={{ verticalAlign: "initial" }}
                              />
                            </Tooltip>
                          </MuiThemeProvider>
                        </span>
                      </div>
                      <input
                        type="text"
                        value={String(lat)}
                        onChange={(e) => handleTextInputChange(e, "lat")}
                        className="form-control"
                      />
                    </Col>
                    <Col lg={4} md={12}>
                      <div className="d-flex justify-content-between mb-2">
                        <h6>Longitude (°):</h6>
                        <span className="">
                          <MuiThemeProvider theme={tooltipTheme}>
                            <Tooltip
                              title={`For eastern hemisphere, longitude is positive`}
                            >
                              <img
                                src={infoIcon}
                                className="infoIcon px-1"
                                alt="info"
                                style={{ verticalAlign: "initial" }}
                              />
                            </Tooltip>
                          </MuiThemeProvider>
                        </span>
                      </div>
                      <input
                        type="text"
                        value={String(lon)}
                        onChange={(e) => handleTextInputChange(e, "lon")}
                        className="form-control"
                      />
                    </Col>
                    <Col lg={4} md={12}>
                      <div className="d-flex justify-content-between mb-2">
                        <h6>Timezone:</h6>
                      </div>
                      <SingleSelect
                        data={timeZones}
                        value={timezone}
                        placeholder={"Select one of them..."}
                        isDisabled={false}
                        isLoading={false}
                        handleChange={setTimezone}
                      />
                    </Col>
                  </Row>
                </div>

                <div className="mt-4 mb-3 overflow-auto">
                  <div>
                    <table className="table table-striped table-bordered mb-0 ">
                      <thead className="thead-dark">
                        <tr>
                          <th scope="col" className="text-center w-25 p-2">
                            Ref Longitude (°)
                          </th>
                          <th scope="col" className="text-center w-25 p-2">
                            Seasonal tilt?
                          </th>
                          <th scope="col" className="text-center w-25 p-2">
                            Design tilt
                          </th>
                          <th scope="col" className="text-center w-25 p-2">
                            Collector width A (m)
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="p-1">
                            <input
                              type="text"
                              className="form-control text-center"
                              value={refLon}
                              disabled={true}
                            />
                          </td>
                          <td className="d-flex justify-content-center p-0">
                            <Checkbox
                              color="primary"
                              checked={isSeasonalTilt}
                              onChange={(e) =>
                                setIsSeasonalTilt(e.target.checked)
                              }
                            />
                          </td>
                          <td className="p-1">
                            <input
                              type="text"
                              className="form-control text-center"
                              value={designTilt}
                              disabled={true}
                            />
                          </td>
                          <td className="p-1">
                            <input
                              type="text"
                              className="form-control text-center"
                              value={collectorWidth}
                              onChange={(e) =>
                                handleTextInputChange(e, "collector")
                              }
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <div className="mt-4 mb-3 overflow-auto">
                  <div>
                    <table className="table table-striped table-bordered mb-0 ">
                      <thead className="thead-dark">
                        <tr>
                          <th scope="col" className="text-center p-2">
                            Selected tilt
                          </th>
                          <th scope="col" className="text-center p-2">
                            Shadow free hrs
                          </th>
                          <th scope="col" className="text-center p-2">
                            Gap (m)
                          </th>
                          <th scope="col" className="text-center p-2">
                            Pitch = y (m)
                          </th>
                          <th scope="col" className="text-center p-2">
                            GCR (%)
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="p-1 ">
                            <input
                              type="text"
                              className="form-control text-center"
                              value={selectedTilt}
                              onChange={(e) => handleTextInputChange(e, "tilt")}
                            />
                          </td>
                          <td className="p-1">
                            <input
                              type="text"
                              className="form-control text-center"
                              value={shadowFreeHrs}
                              onChange={(e) =>
                                handleTextInputChange(e, "freeHrs")
                              }
                            />
                          </td>

                          <td className="p-1">
                            <input
                              type="text"
                              className="form-control text-center"
                              value={gap}
                              disabled={true}
                            />
                          </td>
                          <td className="p-1">
                            <input
                              type="text"
                              className="form-control text-center"
                              value={pitch}
                              disabled={true}
                            />
                          </td>
                          <td className="p-1">
                            <input
                              type="text"
                              className="form-control text-center"
                              value={GCR}
                              disabled={true}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div className="custom_card my-3 p-4">
                <div>
                  <h2 className="my-2">Help</h2>
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <p className="text-justify my-3">
                        <b>What is {service[0]?.name} service?</b>
                        <br />
                        {service[0]?.description}
                      </p>
                    </div>
                    <div className="col-12 col-md-6">
                      <p className="my-3 text-justify">
                        <b>How to use {service[0]?.name} service?</b>
                        <br />
                        {service[0]?.how_to_use}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>

            <Col lg={5} md={12}>
              <div className="custom_card px-3 py-4">
                <div className="mb-3">
                  <h6 className="mb-2">Schematic : </h6>
                  <img src={schematic} alt="schematic" className="img-fluid" />
                </div>

                <div className="text-center">
                  {solarEleWarning && (
                    <small className="text-danger text-center my-2">
                      <b>
                        Please decrease shadow free hours!,Current Solar
                        Elevation Angle (α) : {solarEleAng.toFixed(2)}
                      </b>
                    </small>
                  )}

                  {!isNaN(parseFloat(shadowFreeHrs)) &&
                    GCR &&
                    GCR !== "Error" &&
                    !solarEleWarning && (
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={calcShadowLength}
                        className="mb-3 w-100"
                      >
                        Generate Shadow Length Graph
                      </Button>
                    )}
                  {!isNaN(parseFloat(shadowFreeHrs)) &&
                    shadowLenData.length > 0 &&
                    !solarEleWarning && (
                      <div>
                        <LineChart
                          data={shadowLenData}
                          yAxisTitle={"Shadow Length (m)"}
                          xAxisTitle={"Time"}
                          name={"Shadow Profile"}
                          title={`Shadow profile at ${parseFloat(lat).toFixed(
                            2
                          )}, ${parseFloat(lon).toFixed(2)} on ${
                            parseFloat(lat) > 0 ? "21/12" : "22/06"
                          }`}
                        />
                      </div>
                    )}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      )}

      <ToastContainer />
      <Backdrop
        className={classes.backdrop}
        style={{ zIndex: "10" }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Footer />
    </div>
  );
};

export default ShadingCalcService;
