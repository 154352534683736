import React, { Fragment, useEffect, useState } from "react";
import Header from "../components/Header";
import {
  Backdrop,
  Button,
  CircularProgress,
  Container,
  makeStyles,
  FormControl,
} from "@material-ui/core";
import Footer from "../components/Footer";
import { InputGroup, Row, Col } from "react-bootstrap";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import downloadIco from "../assets/images/cloud-computing.png";
import backLogo from "../assets/images/back.png";
import MyLoader from "../components/ContentLoader";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import axios from "axios";
import {
  GET_ALL_IVEL_PROCESSED_PROCESS,
  GET_SERVICE_BY_ID,
  START_IVEL_SERVICE,
} from "../shared/ApiURLs";
import {
  addHeaderInAxios,
  checkSignature,
  getNameFromUrl,
  sliceName,
} from "../utils/Helpers";
import { toast, ToastContainer } from "react-toastify";
import CloudListModal from "../components/Modal";
import ReactPaginate from "react-paginate";

const ReactFromControl = require("react-bootstrap").FormControl;

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const IVReportGen = (props) => {
  const classes = useStyles();

  const [service, setService] = useState([]);
  const [ivProcessedData, setIVProcessedData] = useState([]);

  const [uploadType, setUploadType] = useState(null);

  const [selectedZipFile, setSelectedZipFile] = useState(null);
  const [selectedXLFile, setSelectedXLFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [process, setProcess] = useState([]);
  const [isContentLoading, setContentLoading] = useState(true);
  const [searchBarValue, setSearchBarValue] = useState("");
  const [filteredProcessArr, setFilteredProcessArr] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [ivCloudData, setIVCloudData] = useState(null);
  const [experimentName, setExperimentName] = useState("");
  const [pageNumber, setPageNumber] = useState(0);

  const dataPerPage = 4;
  const pageVisited = pageNumber * dataPerPage;
  const pageCount = Math.ceil(filteredProcessArr.length / dataPerPage);

  useEffect(() => {
    addHeaderInAxios(props)
      ? getServiceById(props.match.params.service_id)
      : props.history.replace("/");
  }, []);

  const getServiceById = (service_id) => {
    axios
      .get(GET_SERVICE_BY_ID(service_id))
      .then((res) => {
        setService(res.data.service);
        // getAllProcess(res.data.service[0].id);
        getAllIVProcessedData(service_id);
        setContentLoading(false);
      })
      .catch((error) => {
        checkSignature(error);
        setContentLoading(false);
        props.history.replace("/services");
      });
  };

  const fileChangeHandler = (event, type) => {
    if (type === "zip_upload") {
      setSelectedZipFile(event.target.files[0]);
    } else if (type === "xl_upload") {
      setSelectedXLFile(event.target.files[0]);
    }
  };

  // const getAllProcess = (service_id) => {
  //   axios
  //     .get(GET_ALL_IVEL_PROCESSED_PROCESS)
  //     .then((res) => {
  //       setProcess(res.data.process);
  //       setFilteredProcessArr(res.data.process);
  //     })
  //     .catch((error) => {
  //       checkSignature(error);
  //     });
  // };

  const SubmitService = () => {
    setLoading(true);
    if (!validateProcessData()) {
      return setLoading(false);
    }

    const formdata = new FormData();
    formdata.append("experiment_name", experimentName);
    formdata.append("upload_type", uploadType);
    formdata.append("service_id", service[0].id);

    if (uploadType === "cloud_files") {
      formdata.append("iv_cloud_data", JSON.stringify(ivCloudData));
    } else if (uploadType === "local_files") {
      formdata.append("zip_file", selectedZipFile);
      // formdata.append("xl_file", selectedXLFile);
    }
    axios
      .post(START_IVEL_SERVICE, formdata)
      .then((res) => {
        setSelectedXLFile(null);
        setSelectedZipFile(null);
        setExperimentName("");
        setIVCloudData(null);
        setUploadType(null);
        // getAllProcess(service[0].id);
        setLoading(false);
        handleToast("success", res.data.message);
      })
      .catch((e) => {
        setSelectedXLFile(null);
        setSelectedZipFile(null);
        setExperimentName("");
        setIVCloudData(null);
        checkSignature(e);
        setUploadType(null);
        setLoading(false);
        if (e?.response?.data?.message) {
          handleToast("error", e?.response?.data?.message);
        } else {
          handleToast("error", JSON.stringify(e?.response?.data));
        }
      });
  };

  const handleToast = (event_type, message) => {
    return event_type === "success"
      ? toast.success(message)
      : toast.error(message);
  };

  const backBtnHandler = () => {
    return props.history.push("/services");
  };

  const handleSearch = () => {
    let newProcessArr = process.filter((el) => {
      return String(el?.original_zip_name)
        .toLowerCase()
        .includes(searchBarValue.toLowerCase());
    });

    setFilteredProcessArr(newProcessArr);
    return newProcessArr;
  };

  const setFilteredProcessToOriginal = () => setFilteredProcessArr(process);

  const handleUploadTypeChange = (event) => {
    setUploadType(event.target.value);
    setIVCloudData(null);
    setSelectedZipFile(null);
    setSelectedXLFile(null);
  };

  const getAllIVProcessedData = (service_id) => {
    axios
      .get(GET_ALL_IVEL_PROCESSED_PROCESS)
      .then((res) => {
        setIVProcessedData(res.data.processed_data.reverse());
      })
      .catch((error) => {
        checkSignature(error);
      });
  };

  const handleIVDataSelect = (data) => {
    setModalShow(false);
    setIVCloudData(data);
  };

  const validateProcessData = () => {
    if (experimentName === "") {
      handleToast("error", "Please Enter Experiment Name!");
      return false;
    } else if (uploadType === null) {
      handleToast("error", "Please Choose File Upload Type!");
      return false;
    } else if (uploadType === "local_files" && selectedZipFile === null) {
      handleToast("error", "Please Select A Zip File!");
      return false;
    } else if (uploadType === "cloud_files" && ivCloudData === null) {
      handleToast(
        "error",
        "Please Select A Data From Your IV Processed Data List!"
      );
      return false;
    }

    return true;
  };

  const onPageChange = ({ selected }) => {
    setPageNumber(selected);
  };

  return (
    <div className="main-wrapper">
      <Header {...props} />
      {isContentLoading && <MyLoader />}
      {!isContentLoading && (
        <Container className="mb-5">
          <div className="mb-3"></div>
          <Row>
            <Col sm={12} md={7} className="my-2 h-auto">
              <div className="custom_card px-4 py-3 h-100">
                <div>
                  <div>
                    <span>
                      <img
                        src={backLogo}
                        alt="back"
                        className="backBtn"
                        onClick={backBtnHandler}
                      />
                    </span>
                    <span className="mx-3 sscalcHeading d-inline pb-2">
                      {service[0]?.name}
                    </span>
                  </div>

                  <div className="py-1 px-2 my-2">
                    <div className="my-3">
                      <div className="mb-3">
                        <div className="">
                          <Row className="mt-3 mb-4">
                            <div className="w-100 px-3">
                              <input
                                type="text"
                                placeholder="Enter Experiment Name..."
                                className="form-control w-md-75 w-100"
                                maxLength={100}
                                value={experimentName}
                                onChange={(e) => {
                                  setExperimentName(e.target.value);
                                }}
                              />
                            </div>
                          </Row>
                          <Row className="">
                            <FormControl component="fieldset" className="px-3">
                              <h6>Upload Zip and XL File From :</h6>
                              <div className="px-3">
                                <FormControl
                                  className="w-100"
                                  component="fieldset"
                                >
                                  <RadioGroup
                                    value={uploadType}
                                    onChange={handleUploadTypeChange}
                                  >
                                    <div className="d-flex justify-content-around">
                                      <div>
                                        {" "}
                                        <FormControlLabel
                                          key="local_files"
                                          value="local_files"
                                          control={<Radio color="primary" />}
                                          label="Local Files"
                                          labelPlacement="start"
                                        />
                                      </div>
                                      <div>
                                        <FormControlLabel
                                          key="cloud_files"
                                          value="cloud_files"
                                          control={<Radio color="primary" />}
                                          label="Cloud Files"
                                          labelPlacement="start"
                                        />
                                      </div>
                                    </div>
                                  </RadioGroup>
                                </FormControl>
                              </div>
                            </FormControl>
                          </Row>

                          {uploadType === "local_files" && (
                            <Fragment>
                              <Row>
                                <Col sm={12} md={12} lg={4}>
                                  <label htmlFor="upload_zip" className="w-100">
                                    <input
                                      style={{ display: "none" }}
                                      id="upload_zip"
                                      name="upload_zip"
                                      type="file"
                                      accept=".zip"
                                      onChange={(e) =>
                                        fileChangeHandler(e, "zip_upload")
                                      }
                                      onClick={(event) => {
                                        const { target = {} } = event || {};
                                        target.value = "";
                                      }}
                                    />
                                    <Button
                                      color="secondary"
                                      className="px-4 w-100"
                                      variant="contained"
                                      component="span"
                                    >
                                      Choose Zip File
                                    </Button>
                                  </label>
                                </Col>
                                <Col sm={12} md={12} lg={8}>
                                  <input
                                    className="form-control mb-2"
                                    type="text"
                                    value={
                                      selectedZipFile?.name
                                        ? selectedZipFile?.name
                                        : ""
                                    }
                                    readOnly
                                  />
                                </Col>
                              </Row>
                              <Row className="mb-3">
                                <Col sm={12} md={12} lg={4}>
                                  <label
                                    htmlFor="upload_xlsx"
                                    className="w-100"
                                  >
                                    <input
                                      style={{ display: "none" }}
                                      id="upload_xlsx"
                                      name="upload_xlsx"
                                      type="file"
                                      accept=".xlsx"
                                      onChange={(e) =>
                                        fileChangeHandler(e, "xl_upload")
                                      }
                                      onClick={(event) => {
                                        const { target = {} } = event || {};
                                        target.value = "";
                                      }}
                                    />
                                    <Button
                                      color="secondary"
                                      className="px-4 w-100"
                                      variant="contained"
                                      component="span"
                                    >
                                      Choose XL File
                                    </Button>
                                  </label>
                                </Col>
                                <Col sm={12} md={12} lg={8}>
                                  <input
                                    className="form-control w-100"
                                    type="text"
                                    value={
                                      selectedXLFile?.name
                                        ? selectedXLFile?.name
                                        : ""
                                    }
                                    readOnly
                                  />
                                </Col>
                              </Row>
                            </Fragment>
                          )}

                          {uploadType === "cloud_files" && (
                            <Fragment>
                              <div>
                                <Row className="py-2">
                                  <Col md={12} lg={4}>
                                    <Button
                                      variant="contained"
                                      color="secondary"
                                      className="px-4 w-100"
                                      onClick={() => setModalShow(true)}
                                    >
                                      Check List
                                    </Button>
                                  </Col>
                                  <Col md={12} lg={8}>
                                    {ivCloudData && (
                                      <div className="w-100">
                                        <input
                                          className="form-control mb-2"
                                          type="text"
                                          readOnly
                                          value={ivCloudData?.original_zip_name}
                                        ></input>
                                        <input
                                          className="form-control"
                                          type="text"
                                          readOnly
                                          value={getNameFromUrl(
                                            ivCloudData?.result_xlsx
                                          )}
                                        ></input>
                                      </div>
                                    )}
                                  </Col>
                                </Row>
                              </div>
                            </Fragment>
                          )}
                        </div>
                      </div>
                    </div>
                    <Row>
                      <Col lg={4} md={12}>
                        <Button
                          onClick={SubmitService}
                          variant="contained"
                          color="primary"
                          className="my-3 w-100"
                        >
                          Submit
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </Col>
            <Col sm={12} md={5} className="my-2">
              <div className="custom_card px-4 py-3">
                <div>
                  <h2 className="my-2">Help</h2>
                  <p className="my-3 text-justify">
                    <b>What is {service[0]?.name} service?</b>
                    <br />
                    {service[0]?.description}
                  </p>
                  <p className="my-3 text-justify">
                    <b>How to use {service[0]?.name} service?</b>
                    <br />
                    {service[0]?.how_to_use}
                  </p>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              {/* Table Content */}
              {process.length !== 0 && (
                <div className="custom_card px-3 mt-3 py-3">
                  <Row className="my-3">
                    <Col sm={12} md={6}>
                      <div className="mb-3">
                        <InputGroup className="search-bar">
                          <ReactFromControl
                            placeholder="Search by zip name..."
                            aria-label="Search by name"
                            aria-describedby="basic-addon2"
                            value={searchBarValue}
                            onChange={(e) => {
                              setSearchBarValue(e.target.value);
                            }}
                          />
                          <Button
                            style={{ borderRadius: 0 }}
                            variant="contained"
                            color="primary"
                            id="button-addon2"
                            onClick={handleSearch}
                          >
                            Search
                          </Button>
                        </InputGroup>
                      </div>
                    </Col>
                  </Row>

                  <TableContainer>
                    <Table aria-label="CustomTable">
                      <TableHead>
                        <TableRow>
                          <TableCell align="left">Zip Name</TableCell>
                          {/* <TableCell align="left">XL Name</TableCell> */}
                          <TableCell align="center">Zip Size</TableCell>
                          <TableCell align="center">Input Type</TableCell>
                          {/* <TableCell align="center">XL Size</TableCell> */}
                          <TableCell align="center">Status</TableCell>
                          <TableCell align="center">Result XL</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {filteredProcessArr
                          .slice(pageVisited, pageVisited + dataPerPage)
                          .map((el) => {
                            return (
                              <TableRow key={el?.id}>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  className="wrapText"
                                  align="left"
                                >
                                  <a
                                    href={el?.uploaded_zip_url}
                                    download
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    {sliceName(el?.original_zip_name)}
                                  </a>
                                </TableCell>

                                {el?.zip_size !== null && (
                                  <TableCell align="center">
                                    {String(
                                      (
                                        parseInt(el?.zip_size) /
                                        1024 /
                                        1024
                                      ).toFixed(2)
                                    ) + " MB"}
                                  </TableCell>
                                )}

                                {el?.zip_size === null && (
                                  <TableCell align="center">NA</TableCell>
                                )}

                                <TableCell align="center">
                                  {String(
                                    el?.upload_type.replace("_", " ")
                                  ).toLocaleUpperCase()}
                                </TableCell>

                                <TableCell align="center">
                                  {el?.status}
                                </TableCell>
                                <TableCell align="center">
                                  <a
                                    href={el?.result_xlsx}
                                    download
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    <img
                                      className="hovImg"
                                      src={downloadIco}
                                      alt="download"
                                    />
                                  </a>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                    {!filteredProcessArr.length && (
                      <Col md={12} className="my-3">
                        <h3 className="text-center">
                          No Process Found with name {searchBarValue}!
                        </h3>
                        <div className="text-center mt-2">
                          <Button
                            variant="outlined"
                            color="primary"
                            onClick={setFilteredProcessToOriginal}
                          >
                            Get All Processes
                          </Button>
                        </div>
                      </Col>
                    )}

                    {filteredProcessArr.length > 0 && (
                      <ReactPaginate
                        previousLabel={"Previous"}
                        nextLabel={"Next"}
                        pageCount={pageCount}
                        onPageChange={onPageChange}
                        containerClassName={"pagnateContainer"}
                        previousLinkClassName={"navigateBtn"}
                        nextLinkClassName={"navigateBtn"}
                        disabledClassName={"disabledBtn"}
                        activeClassName={"activeBtn"}
                      />
                    )}
                  </TableContainer>
                </div>
              )}
            </Col>
          </Row>
        </Container>
      )}
      <CloudListModal
        show={modalShow}
        is_ivel={false}
        onHide={() => setModalShow(false)}
        resetSelectedData={() => setIVCloudData(null)}
        processedData={ivProcessedData}
        handleDataSelect={handleIVDataSelect}
      />
      <ToastContainer />
      <Backdrop
        className={classes.backdrop}
        style={{ zIndex: "10" }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Footer />
    </div>
  );
};

export default IVReportGen;
