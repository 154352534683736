import {
  Backdrop,
  Button,
  CircularProgress,
  Container,
  makeStyles,
} from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import MyLoader from "../components/ContentLoader";
import Footer from "../components/Footer";
import Header from "../components/Header";
import {
  CALC_INTERP_DATA,
  EXPORT_INTERP_DATA,
  GET_SERVICE_BY_ID,
  IMPORT_INTERP_DATA,
} from "../shared/ApiURLs";
import { checkSignature } from "../utils/Helpers";
import backLogo from "../assets/images/back.png";
import { Col, Row } from "react-bootstrap";
import { BrowserView, MobileView } from "react-device-detect";
import _ from "underscore";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const InterpolationToolService = (props) => {
  const classes = useStyles();
  const [service, setService] = useState([]);
  const [loading, setLoading] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const [isContentLoading, setContentLoading] = useState(true);
  const [inputTextAreaOne, setInputTextAreaOne] = useState("");
  const [inputTextAreaTwo, setInputTextAreaTwo] = useState("");
  const [seprator, setSeprator] = useState("");
  const [outputTextArea, setOutputTextArea] = useState("");
  const [importInterpolateFile, setImportInterpolateFile] = useState(null);
  const [importInterpolateT2File, setImportInterpolateT2File] = useState(null);

  useEffect(() => {
    getServiceById(props.match.params.service_id);
    // console.log(inputTextAreaOne);
    // console.log(inputTextAreaTwo);
    // console.log(seprator);
  }, []);

  const getServiceById = (service_id) => {
    if (firstLoad) {
      axios
        .get(GET_SERVICE_BY_ID(service_id))
        .then((res) => {
          setFirstLoad(false);
          setService(res.data.service);
          setContentLoading(false);
        })
        .catch((error) => {
          checkSignature(error);
          setFirstLoad(false);
          setContentLoading(false);
          props.history.replace("/services");
        });
    }
  };

  const handleToast = (event_type, message) => {
    return event_type === "success"
      ? toast.success(message)
      : toast.error(message);
  };

  const backBtnHandler = () => {
    return props.history.push("/services");
  };

  const handleTextareaChange = (event, type) => {
    var regExp = /[a-zA-Z]/g;

    if (regExp.test(event.target.value)) {
      return handleToast("error", "Only Numbers Are Allowed!");
    }
    if (type === "one") {
      setInputTextAreaOne(event.target.value);
    } else if (type === "two") {
      setInputTextAreaTwo(event.target.value);
    }
  };

  const calcInterpolation = () => {
    if (seprator === "") {
      return handleToast("error", "Cannot Leave Seprator Empty!");
    }
    if (inputTextAreaOne === "" || inputTextAreaTwo === "") {
      return handleToast("error", "Cannot Leave Input Area Empty!");
    }

    let inputOneArr = _.compact(inputTextAreaOne.split("\n"));
    let inputTwoArr = _.compact(inputTextAreaTwo.split("\n"));
    let time = [];
    let rad = [];
    let temp = [];
    inputOneArr.forEach((v, i, a) => {
      let arrq_ = v.split(seprator);
      arrq_.forEach((el, in_, ar_) => {
        ar_[in_] = parseFloat(el);
      });
      a[i] = arrq_;
      time.push(a[i][0]);
      rad.push(a[i][1]);
      temp.push(a[i][2]);
    });
    inputTwoArr.forEach((el, i, array) => {
      let ar_ = el.split(seprator);
      array[i] = parseFloat(ar_[0]);
    });
    setLoading(true);
    axios
      .post(CALC_INTERP_DATA, {
        time: time,
        rad: rad,
        temp: temp,
        time2: inputTwoArr,
        seprator: seprator,
      })
      .then((res) => {
        setLoading(false);
        setOutputTextArea(res.data.output);
        handleToast("success", res?.data?.message);
      })
      .catch((e) => {
        setLoading(false);
        handleToast("error", e?.response?.data?.message);
      });
  };

  const importDataToInputs = (e, type) => {
    const file = e.target.files[0];
    setLoading(true);
    let formdata = new FormData();
    formdata.append("file", file);
    formdata.append("type", type);
    axios
      .post(IMPORT_INTERP_DATA, formdata)
      .then((res) => {
        setLoading(false);
        if (type === "time") {
          setInputTextAreaOne(res.data.textareaOne);
        } else if (type === "time2") {
          setInputTextAreaTwo(res.data.textareaTwo);
        }
        setSeprator(",");
        handleToast("success", res?.data?.message);
      })
      .catch((e) => {
        setLoading(false);
        handleToast("error", e?.response?.data?.message);
      });
  };

  const exportData = (e, type) => {
    if (outputTextArea === "") {
      return handleToast("error", "Please Calculate Output First!");
    }
    setLoading(true);
    axios
      .post(
        EXPORT_INTERP_DATA,
        { output: outputTextArea },
        {
          responseType: "blob",
        }
      )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        const timeStamp = new Date().getTime();
        const fileName = `Interpolated_Output_${timeStamp}.csv`;
        link.setAttribute("download", `${fileName}`);
        document.body.appendChild(link);
        link.click();
        // resetInputs();
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        handleToast("error", `Something went wrong, Please try again!`);
      });
  };

  return (
    <div className="main-wrapper">
      <Header {...props} />
      {isContentLoading && <MyLoader />}
      {!isContentLoading && (
        <Container className="mb-5">
          <div className="mb-3">
            <div className="custom_card px-4 py-1">
              <div className="row">
                <div className="mt-2 col-lg-6 col-sm-12">
                  <span>
                    <img
                      src={backLogo}
                      alt="back"
                      className="backBtn"
                      onClick={backBtnHandler}
                    />
                  </span>
                  <span className="mx-3 sscalcHeading d-inline pb-2">
                    {service[0]?.name}
                  </span>

                  <div className="my-4">
                    <div className="mt-2 d-flex ">
                      <input
                        type="text"
                        className="form-control w-100 mr-2"
                        placeholder="Enter Separator..."
                        value={seprator}
                        onChange={(e) => setSeprator(e.target.value)}
                      />
                      <div className="d-inline-block d-flex w-100 h-25">
                        <button
                          className="btn btn-danger w-100 h-25 d-inline-block mr-1"
                          onClick={() => {
                            setInputTextAreaOne("");
                            setInputTextAreaTwo("");
                            setSeprator("");
                          }}
                        >
                          <small>CLEAR INPUTS</small>
                        </button>
                        <button
                          className="btn btn-danger w-100 h-25 d-inline-block"
                          onClick={() => {
                            setOutputTextArea("");
                          }}
                        >
                          <small>CLEAR OUTPUT</small>
                        </button>
                      </div>
                    </div>

                    <div className="mt-3 d-flex ">
                      <label htmlFor="import_data" className="mr-2 w-100">
                        <input
                          style={{ display: "none" }}
                          id="import_data"
                          name="import_data"
                          type="file"
                          accept=".csv"
                          onChange={(e) => importDataToInputs(e, "time")}
                          onClick={(event) => {
                            const { target = {} } = event || {};
                            target.value = null;
                          }}
                          value={importInterpolateFile}
                        />
                        <Button
                          color="secondary"
                          variant="contained"
                          className="w-100"
                          component="span"
                        >
                          Import INTERPOLATE DATA
                        </Button>
                      </label>

                      <label htmlFor="import_data2" className="w-100">
                        <input
                          style={{ display: "none" }}
                          id="import_data2"
                          name="import_data2"
                          type="file"
                          accept=".csv"
                          onChange={(e) => importDataToInputs(e, "time2")}
                          onClick={(event) => {
                            const { target = {} } = event || {};
                            target.value = null;
                          }}
                          value={importInterpolateT2File}
                        />
                        <Button
                          color="secondary"
                          variant="contained"
                          component="span"
                          className="w-100"
                        >
                          Import INTERPOLATE Time2
                        </Button>
                      </label>
                    </div>

                    <div className="mt-2 d-flex ">
                      <Button
                        color="secondary"
                        variant="contained"
                        component="span"
                        className="w-100 mr-2 h-25"
                        onClick={exportData}
                      >
                        EXPORT DATA
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={calcInterpolation}
                        className="w-100 h-25"
                      >
                        Calculate
                      </Button>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 col-sm-12">
                  <h2 className="my-2 ">Help</h2>
                  <p className="my-3 text-justify">
                    <b>What is {service[0]?.name} service?</b>
                    <br />
                    {service[0]?.description}
                  </p>
                  <p className="my-3 text-justify">
                    <b>How to use {service[0]?.name} service?</b>
                    <br />
                    {service[0]?.how_to_use}
                  </p>
                </div>
              </div>
            </div>

            <BrowserView
              className="custom_card px-4 py-1 my-3"
              style={{ height: "300px" }}
            >
              <div className="mt-2 row">
                <Col lg={6} md={6} sm={12} className="px-3 py-2">
                  <Row>
                    <Col lg={8}>
                      <h6 className="mb-2">Time,Rad,Temp</h6>
                      <textarea
                        className="form-control interpol-textarea"
                        value={inputTextAreaOne}
                        onChange={(e) => {
                          handleTextareaChange(e, "one");
                        }}
                      />
                    </Col>
                    <Col lg={4}>
                      <h6 className="mb-2">Time2</h6>
                      <textarea
                        className="form-control interpol-textarea"
                        value={inputTextAreaTwo}
                        onChange={(e) => {
                          handleTextareaChange(e, "two");
                        }}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col lg={6} md={6} sm={12} className="px-3 py-2">
                  <h6 className="mb-2">Output</h6>
                  <textarea
                    className="form-control interpol-textarea"
                    readOnly={true}
                    value={outputTextArea}
                  />
                </Col>
              </div>
            </BrowserView>

            <MobileView className="custom_card px-4 py-1 my-3">
              <div className="mt-2 row">
                <Col lg={6} md={6} sm={12} className="px-3 py-2">
                  <Row>
                    <Col lg={8}>
                      <h6 className="mb-2">Time,Rad,Temp</h6>
                      <textarea
                        className="form-control"
                        value={inputTextAreaOne}
                        onChange={(e) => {
                          handleTextareaChange(e, "one");
                        }}
                      />
                    </Col>
                    <Col lg={4} className="my-2">
                      <h6 className="mb-2">Time2</h6>
                      <textarea
                        className="form-control"
                        value={inputTextAreaTwo}
                        onChange={(e) => {
                          handleTextareaChange(e, "two");
                        }}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col lg={6} md={6} sm={12} className="px-3 py-2">
                  <h6 className="mb-2">Output</h6>
                  <textarea
                    className="form-control"
                    readOnly={true}
                    value={outputTextArea}
                  />
                </Col>
              </div>
            </MobileView>
          </div>
        </Container>
      )}
      <ToastContainer />
      <Backdrop
        className={classes.backdrop}
        style={{ zIndex: "10" }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Footer />
    </div>
  );
};

export default InterpolationToolService;
