import { Modal } from "react-bootstrap";

export function ImageModal(props) {
  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      size={'lg'}
      style={{ zIndex: 1500 }}
    >
      <Modal.Body className="" closeButton>
        <img src={props.img_path} alt="img" className="img-fluid mx-auto d-block" />
      </Modal.Body>
    </Modal>
  );
}
