import { Modal } from "react-bootstrap";
import { Button } from "@material-ui/core";
import { AQLDataArray } from "../utils/Helpers";

export function AQLModal(props) {
  const handleAQLSelect = (data) => {
    props.handledata(data);
    props.onHide();
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Choose AQL</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {AQLDataArray.map((el) => (
          <Button
            key={el}
            color="primary"
            className="m-1"
            variant="outlined"
            onClick={() => handleAQLSelect(el)}
          >
            {el}
          </Button>
        ))}
      </Modal.Body>
    </Modal>
  );
}
