import React, { useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import Login from "./components/Login";
import ForgotPassword from "./components/ForgetPassword";
import ResetPassword from "./components/ResetPassword";
import * as axios from "axios";
import Services from "./Pages/Services";
import ThermagixService from "./Pages/ThermagixService";
import IVSTCService from "./Pages/IVSTCService";
import IVELService from "./Pages/IVELService";
import SSCalcService from "./Pages/SSCalcService";
import UncerCalcService from "./Pages/UncerCalcService";
import SATAngleService from "./Pages/SATAngleService";
import GHI_POA_CalcService from "./Pages/GHI-POA_CalcService";
import { fetchAuthToken } from "./utils/Helpers";
import NotFound from "./Pages/NotFound";
import IVReportGen from "./Pages/IVReportGen";
import InterpolationToolService from "./Pages/InterpolationToolService";
import StringSizerService from "./Pages/StringSizerService";
import EarthinCalcService from "./Pages/EarthingCalcService";
import ShadingCalcService from "./Pages/ShadingCalcService";
import IVSTC2Service from "./Pages/IVSTC2Service";
import ELClassificationRules from "./Pages/ElClassificationRules";
import ThermagixServiceTwo from "./Pages/ThermagixTwo";
import IVOPCQCService from "./Pages/IVOPCQCervice";

const Routes = (props) => {
  useEffect(() => {
    if (fetchAuthToken()) {
      addHeaderInAxios();
    }
  }, []);

  const addHeaderInAxios = () => {
    axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
    const AUTH_TOKEN = localStorage.getItem("token");
    if (AUTH_TOKEN) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${AUTH_TOKEN}`;
      axios.defaults.headers.post["Content-Type"] =
        "application/x-www-form-urlencoded";
      return true;
    }
    return false;
  };

  return (
    <>
      <Switch>
        <Route exact path="/forgot_password" component={ForgotPassword} />
        <Route exact path="/reset/:token" component={ResetPassword} />
        <Route
          exact
          path="/services/ivstc-2/:service_id"
          component={IVSTC2Service}
        />
        <Route
          exact
          path="/services/thermagix/:service_id"
          component={ThermagixService}
        />
        <Route
          exact
          path="/services/thermagix-2/:service_id"
          component={ThermagixServiceTwo}
        />
        <Route
          exact
          path="/services/ivstc/:service_id"
          component={IVSTCService}
        />
        <Route
          exact
          path="/services/ivel/:service_id"
          component={IVELService}
        />
        <Route
          exact
          path="/services/ss_calc/:service_id"
          component={SSCalcService}
        />{" "}
        <Route
          exact
          path="/services/uncertainty/:service_id"
          component={UncerCalcService}
        />{" "}
        <Route
          exact
          path="/services/satAngle/:service_id"
          component={SATAngleService}
        />
        <Route
          exact
          path="/services/ghi-poa_calc/:service_id"
          component={GHI_POA_CalcService}
        />
        <Route
          exact
          path="/services/iv-report-gen/:service_id"
          component={IVReportGen}
        />
        <Route
          exact
          path="/services/interpolation-tool/:service_id"
          component={InterpolationToolService}
        />
        <Route
          exact
          path="/services/string-sizer/:service_id"
          component={StringSizerService}
        />
        <Route
          exact
          path="/services/earthing-calc/:service_id"
          component={EarthinCalcService}
        />
        <Route
          exact
          path="/services/shading-calc/:service_id"
          component={ShadingCalcService}
        />
        <Route
          exact
          path="/services/el-classification-rules/:service_id"
          component={ELClassificationRules}
        />
        <Route
          exact
          path="/services/ivopc-qc/:service_id"
          component={IVOPCQCService}
        />
        <Route exact path="/services" component={Services} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/" component={Services} />
        <Route component={NotFound} />
      </Switch>
    </>
  );
};
export default Routes;
