import React, { Component } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Container } from "@material-ui/core";
import { Row, Col } from "react-bootstrap";
import ServiceCard from "../components/ServiceCard";
import axios from "axios";
import {
  addHeaderInAxios,
  checkSignature,
  fetchAuthToken,
} from "../utils/Helpers";
import { ALL_SERVICES_UNAUTH_USER, GET_SERVICES } from "../shared/ApiURLs";
import MyLoader from "../components/ContentLoader";
import { toast, ToastContainer } from "react-toastify";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import lockIco from "../assets/images/lock.png";

class IATServices extends Component {
  state = {
    services: [],
    is_loggedin: false,
    contentLoading: true,
  };

  componentDidMount = () => {
    fetchAuthToken()
      ? this.setState({ is_loggedin: true }, () => {
          this.state.is_loggedin
            ? this.getAuthServices()
            : this.getUnauthServices();
        })
      : this.setState({ is_loggedin: false }, () => {
          this.state.is_loggedin
            ? this.getAuthServices()
            : this.getUnauthServices();
        });
  };

  getAuthServices = () => {
    addHeaderInAxios(this.props);

    axios
      .get(GET_SERVICES)
      .then((res) => {
        this.setState({
          services: res.data.services,
          contentLoading: false,
        });
      })
      .catch((error) => {
        checkSignature(error);
        this.setState({
          contentLoading: false,
        });
      });
  };

  getUnauthServices = () => {
    axios
      .get(ALL_SERVICES_UNAUTH_USER)
      .then((res) => {
        this.setState({
          services: res.data.services,
          contentLoading: false,
        });
      })
      .catch((error) => {
        checkSignature(error);
        this.setState({
          contentLoading: false,
        });
      });
  };

  handleNavigation = (service) => {
    if (!this.state.is_loggedin && service.available_for !== "Public") {
      return toast.info("Please Login to access this service!");
    }

    var words = ["https", "http"];
    var value = new RegExp(words.join("|")).test(service.frontend_route);

    if (value) {
      return window.open(service.frontend_route);
    }

    console.log("Asd", localStorage.getItem("is_admin") === "false");
    if (
      this.state.is_loggedin &&
      service.available_for === "Superusers" &&
      localStorage.getItem("is_admin") === "false"
    ) {
      return this.handleToast(
        "error",
        "Service available only for superusers!"
      );
    }
    if (service.frontend_route && service.name !== "Cablesizer") {
      return this.props.history.push(
        `services/${service.frontend_route}/${service.id}`
      );
    } else {
      return this.handleToast("error", "Service Routing Not Defined!");
    }
  };

  handleToast = (event_type, message) => {
    return event_type === "success"
      ? toast.success(message)
      : toast.error(message);
  };

  render() {
    return (
      <div className="main-wrapper">
        <Header {...this.props} />
        {this.state.contentLoading && <MyLoader />}

        <Container>
          <Row className="mb-4">
            {this.state.is_loggedin &&
              this.state?.services?.map((service) => {
                return (
                  <Col sm={12} md={4} key={service.id}>
                    <ServiceCard
                      title={`${service.name}`}
                      summery={`${service.description}`}
                      totalProcess={service.total_process}
                      processedProcess={service.processed_process}
                      handleNavigation={() => this.handleNavigation(service)}
                      available_for={service.available_for}
                      {...this.props}
                    ></ServiceCard>
                  </Col>
                );
              })}

            {!this.state.is_loggedin &&
              this.state?.services?.map((service) => {
                return (
                  <Col sm={12} md={4} key={service.id}>
                    <Card
                      style={{ position: "relative", height: 121 }}
                      className={
                        service.available_for !== "Public"
                          ? "lockedCard py-2 my-3"
                          : "py-2 my-3"
                      }
                    >
                      <CardActionArea
                        onClick={() => this.handleNavigation(service)}
                      >
                        {service?.available_for !== "Public" && (
                          <img
                            src={lockIco}
                            alt="lockIco"
                            className="lockICO"
                          />
                        )}

                        <CardContent>
                          <Typography gutterBottom variant="h5" component="h2">
                            {service?.name}
                          </Typography>
                          <Typography
                            className="wrapText"
                            variant="body2"
                            color="textSecondary"
                            component="p"
                          >
                            {service?.description}
                          </Typography>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Col>
                );
              })}

            {!this.state?.services?.length && (
              <Col sm={12}>
                <h2 className="text-center p-3">No Services Found!</h2>
              </Col>
            )}
          </Row>
        </Container>
        <ToastContainer />

        <Footer />
      </div>
    );
  }
}

export default IATServices;
