import { Col, Modal, Row } from "react-bootstrap";
import NumericInput from "react-numeric-input";
import { Button, MuiThemeProvider, Tooltip } from "@material-ui/core";
import { tooltipTheme } from "../utils/Helpers";
import infoIcon from "../assets/images/info.png";

export function SeasonalTiltModal(props) {
  const onDataChange = (month, type, value) => {
    let data = { ...props.data };

    data[month][type] = value;
    props.seasonalDataChange(data);
  };

  const onAllValueAsJanClick = () => {
    let data = { ...props.data };
    let tilt = data.Jan.tilt;
    let azimuth = data.Jan.azimuth;

    for (const month in data) {
      data[month].tilt = tilt;
      data[month].azimuth = azimuth;
    }

    props.seasonalDataChange(data);
  };

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      style={{ zIndex: 1500 }}
    >
      <Modal.Header closeButton className="p-2">
        <Modal.Title id="contained-modal-title-vcenter">
          Enter Seasonal Data
          <MuiThemeProvider theme={tooltipTheme}>
            <Tooltip
              title={`Tilt: Range from 0° to 90° | Azimuth: Range from 0° to 360°`}
            >
              <img
                src={infoIcon}
                className="infoIcon px-1"
                alt="info"
                style={{ verticalAlign: "initial" }}
              />
            </Tooltip>
          </MuiThemeProvider>
        </Modal.Title>
        <Button
          className="mx-auto"
          color="primary"
          variant="contained"
          onClick={onAllValueAsJanClick}
        >
          All Equal to Jan
        </Button>
      </Modal.Header>
      <Modal.Body className="p-1">
        <table className="table table-striped table-bordered mb-0 ">
          <thead className="thead-dark">
            <tr>
              <th scope="col" class="text-center p-2">
                Month
              </th>
              <th scope="col" class="text-center p-2">
                Tilt
              </th>
              <th scope="col" class="text-center p-2">
                Azimuth
              </th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(props.data).map((el, index) => (
              <tr>
                <th scope="row" class="align-middle">
                  {el}
                </th>
                <td class="p-1">
                  <NumericInput
                    value={props.data[el].tilt}
                    onChange={(val) => onDataChange(el, "tilt", val)}
                    className="form-control"
                    min={0}
                    max={90}
                    // format={(val) => val + " Tilt"}
                  />
                </td>
                <td class="p-1">
                  <NumericInput
                    value={props.data[el].azimuth}
                    onChange={(val) => onDataChange(el, "azimuth", val)}
                    className="form-control"
                    min={0}
                    max={360}
                    // format={(val) => val + " Azimuth"}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Modal.Body>
    </Modal>
  );
}
