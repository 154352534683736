import { createMuiTheme } from "@material-ui/core";

export const theme = createMuiTheme({
  palette: {
    primary: {
      light: "#757ce8",
      main: "#3f51b5",
      dark: "#6a7de4",
      contrastText: "#fff",
    },
    secondary: {
      light: "#ff7961",
      main: "#424242",
      dark: "#2b2b2b",
      contrastText: "white",
    },
  },
});
