import React from "react";
import NotFoundImg from "../assets/images/NotFound.png";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";

const NotFound = (props) => (
  <div
    className="main-wrapper bg-white"
    style={{ overflow: "hidden", marginTop: "-2rem" }}
  >
    <Header {...props} />
    <div className="row mb-3">
      <img
        src={NotFoundImg}
        alt="NotFoundImg"
        className="col-12 col-lg-6 col-md-4 mb-md-0 mb-3"
      />
      <div className="my-auto text-center col-12 col-lg-6 col-md-8">
        <h1 style={{ fontSize: "6.5rem" }}>404</h1>
        <h3>This page could not be found.</h3>
        <h3>You should probably</h3>
        <h4>
          go back to 
          <Link to="/"> Homepage</Link>
        </h4>
      </div>
    </div>
    <Footer />
  </div>
);

export default NotFound;
