import axios from "axios";
import React, { useEffect, useState } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { CALCULATE_UNCERTAINTY, GET_SERVICE_BY_ID } from "../shared/ApiURLs";
import {
  checkSignature,
  IV_Device_Make,
  measurementTypes,
  module_iv_value,
  radiation_std_dev,
  radiation_std_dev_solmetric,
  sqrt_3,
  string_iv_value,
  Type_A,
  UncertaintyCalcTypes,
} from "../utils/Helpers";
import MyLoader from "../components/ContentLoader";
import { toast, ToastContainer } from "react-toastify";
import backLogo from "../assets/images/back.png";
import NumericInput from "react-numeric-input";

import {
  Backdrop,
  CircularProgress,
  Container,
  makeStyles,
  Button,
} from "@material-ui/core";
import { Col, Row } from "react-bootstrap";
import { SingleSelect } from "../components/SingleSelect";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const UncerCalcService = (props) => {
  const classes = useStyles();
  const [service, setService] = useState([]);
  const [loading, setLoading] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const [isContentLoading, setContentLoading] = useState(true);

  const [calculationType, setCalculationType] = useState(
    UncertaintyCalcTypes[0]
  );
  const [deviceMake, setDeviceMake] = useState(IV_Device_Make[0]);

  const [measurementType, setMeasurementType] = useState(measurementTypes[0]);
  const [spectrumPercentage, setSpectrumPercentage] = useState(0);
  const [voltagePM, setVoltagePM] = useState(0);
  const [currentPM, setCurrentPM] = useState(0);
  const [mods_in_series, set_mods_in_series] = useState(0);
  const [mods_in_parallel, set_mods_in_parallel] = useState(0);
  const [gamma, setGamma] = useState(0);
  const [file, setFile] = useState(null);
  const [uncertainty, setUncertainty] = useState(0);
  const [loadingToolong, setLoadingTooLong] = useState(false);

  useEffect(() => {
    getServiceById(props.match.params.service_id);
  });

  useEffect(() => {
    console.log(file);
  }, [file]);

  const getServiceById = (service_id) => {
    if (firstLoad) {
      axios
        .get(GET_SERVICE_BY_ID(service_id))
        .then((res) => {
          setFirstLoad(false);
          if (res.data.service[0].name) {
            setService(res.data.service);
            setContentLoading(false);
          } else {
            setContentLoading(false);
            handleToast("error", "Service Not Found!");
            props.history.replace("/services");
          }
        })
        .catch((error) => {
          checkSignature(error);
          setFirstLoad(false);
          setContentLoading(false);
          props.history.replace("/services");
        });
    }
  };

  const handleToast = (event_type, message) => {
    return event_type === "success"
      ? toast.success(message)
      : toast.error(message);
  };

  const backBtnHandler = () => {
    return props.history.push("/services");
  };

  const handleMethodChange = (selectedOption, triggeredAction) => {
    if (triggeredAction.action === "clear") {
      setCalculationType(null);
    } else {
      setCalculationType(selectedOption);
      resetInputs();
    }
  };

  const handleDeviceMakeChange = (selectedOption, triggeredAction) => {
    if (triggeredAction.action === "clear") {
      setCalculationType(null);
    } else {
      setDeviceMake(selectedOption);
      // resetInputs();
    }
  };

  const handleMeasurementChange = (selectedOption, triggeredAction) => {
    if (triggeredAction.action === "clear") {
      setMeasurementType(null);
    } else {
      setMeasurementType(selectedOption);
    }
  };

  const calculateUncertainty = () => {
    console.log(voltagePM, currentPM, gamma);
    const spectrum_std_dev = spectrumPercentage / sqrt_3;
    let voltage_std_dev;
    let current_std_dev;
    let temp_std_dev;
    let radiation_std_dev_;
    if (deviceMake.value === "EKO") {
      temp_std_dev = Math.abs(gamma) / sqrt_3;
      radiation_std_dev_ = radiation_std_dev;

      if (measurementType.value === "module_iv") {
        voltage_std_dev = module_iv_value.voltage / voltagePM / sqrt_3;
        current_std_dev = module_iv_value.current / currentPM / sqrt_3;
      } else if (measurementType.value === "string_iv") {
        voltage_std_dev =
          string_iv_value?.voltage / (mods_in_series * voltagePM) / sqrt_3;

        current_std_dev =
          string_iv_value?.current / (mods_in_parallel * currentPM) / sqrt_3;
      }
    } else if (deviceMake.value === "SOLMETRIC") {
      temp_std_dev = Math.abs(gamma * 2) / sqrt_3;
      radiation_std_dev_ = radiation_std_dev_solmetric;

      if (measurementType.value === "module_iv") {
        voltage_std_dev = ((0.25 / voltagePM + 0.005) * 100) / sqrt_3;
        current_std_dev = ((0.04 / currentPM + 0.005) * 100) / sqrt_3;
      } else if (measurementType.value === "string_iv") {
        voltage_std_dev =
          ((0.25 / (mods_in_series * voltagePM) + 0.005) * 100) / sqrt_3;
        current_std_dev =
          ((0.04 / (mods_in_parallel * currentPM) + 0.005) * 100) / sqrt_3;
      }
    }

    const rms_type_b = Math.sqrt(
      radiation_std_dev_ ** 2 +
        temp_std_dev ** 2 +
        spectrum_std_dev ** 2 +
        voltage_std_dev ** 2 +
        current_std_dev ** 2
    );

    const uncertaintyPercentage =
      Math.sqrt(Type_A ** 2 + rms_type_b ** 2) * 1.96;

    const uncertainty_ = parseFloat(uncertaintyPercentage).toFixed(2);

    console.log("temp_std_dev:", temp_std_dev);
    console.log("spectrum_std_dev:", spectrum_std_dev);
    console.log("radiation_std_dev_:", radiation_std_dev_);
    console.log("voltage_std_dev:", voltage_std_dev);
    console.log("current_std_dev:", current_std_dev);
    console.log("rms_type_b:", rms_type_b);
    console.log("uncertaintyPercentage:", uncertaintyPercentage);
    console.log("uncertainty_:", uncertainty_);

    setUncertainty(uncertainty_);
  };

  const resetInputs = () => {
    setMeasurementType(measurementTypes[0]);
    setSpectrumPercentage(0);
    setVoltagePM(0);
    setCurrentPM(0);
    set_mods_in_series(0);
    set_mods_in_parallel(0);
    setGamma(0);
    setFile(null);
    setUncertainty(0);
  };

  const bulkCalculation = () => {
    if (file === null) {
      return handleToast("error", "Please Select a CSV or XLSX file!");
    }
    if (
      measurementType.value === "string_iv" &&
      (mods_in_parallel === 0 || mods_in_series === 0)
    ) {
      return handleToast(
        "error",
        "Values of Series and Parallel Mods should be greater than 0"
      );
    }

    const formdata = new FormData();
    formdata.append("file", file);
    formdata.append("spectrumPercentage", spectrumPercentage);
    formdata.append("gamma", -Math.abs(gamma));
    formdata.append("measurement_type", measurementType.value);
    formdata.append("device_make", deviceMake.value);
    if (measurementType.value === "string_iv") {
      formdata.append("mods_in_parallel", mods_in_parallel);
      formdata.append("mods_in_series", mods_in_series);
    }

    setLoading(true);
    setTimeout(() => {
      setLoadingTooLong(true);
    }, 180000);
    // 180000 -- 3MIN
    // 300000 -- 5MIN
    axios
      .post(CALCULATE_UNCERTAINTY, formdata, {
        headers: { "Content-Type": file.type },
        responseType: "blob",
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        const timeStamp = new Date().getTime();
        const extension = file.name.split(".").reverse()[0];
        const fileName =
          file.name.split(`.${extension}`)[0] + `_${timeStamp}.${extension}`;
        link.setAttribute("download", `${fileName}`);
        document.body.appendChild(link);
        setLoading(false);
        setLoadingTooLong(false);
        link.click();
      })
      .catch((e) => {
        setLoading(false);
        setLoadingTooLong(false);
        if (e?.response?.data?.message) {
          handleToast("error", e?.response?.data?.message);
        } else {
          const extension = file.name.split(".").reverse()[0];
          handleToast(
            "error",
            `${String(
              extension
            ).toUpperCase()} is invalid. 'Vpm' or 'Ipm' is missing!`
          );
        }
      });
  };

  return (
    <div className="main-wrapper">
      <Header {...props} />
      {isContentLoading && <MyLoader />}
      {!isContentLoading && (
        <Container className="">
          <Row className="mb-3">
            <Col lg={6} md={12} className="mb-3">
              <div className="custom_card px-4 py-1">
                <div className="mt-2">
                  <span>
                    <img
                      src={backLogo}
                      alt="back"
                      className="backBtn"
                      onClick={backBtnHandler}
                    />
                  </span>
                  <span className="mx-3 sscalcHeading">{service[0]?.name}</span>
                </div>

                <div>
                  <div className="my-4">
                    <div className="d-flex justify-content-between mb-2">
                      <h6>Select Type of Calculation: </h6>
                    </div>

                    <SingleSelect
                      data={UncertaintyCalcTypes}
                      value={calculationType}
                      placeholder={"Select one of them..."}
                      isDisabled={false}
                      isLoading={false}
                      handleChange={handleMethodChange}
                    />
                  </div>
                  <div className="my-4">
                    <div className="d-flex justify-content-between mb-2">
                      <h6>Select IV Device Make: </h6>
                    </div>

                    <SingleSelect
                      data={IV_Device_Make}
                      value={deviceMake}
                      placeholder={"Select one of them..."}
                      isDisabled={false}
                      isLoading={false}
                      handleChange={handleDeviceMakeChange}
                    />
                  </div>
                  <div>
                    <p className="text-justify my-3">
                      <b>What is {service[0]?.name} service?</b>
                      <br />
                      {service[0]?.description}
                    </p>

                    <p className="my-3 text-justify">
                      <b>How to use {service[0]?.name} service?</b>
                      <br />
                      {service[0]?.how_to_use}
                    </p>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={6} md={12}>
              <div className="custom_card px-4 py-4">
                <div className="pb-3 mb-2">
                  <h5>{calculationType?.label}</h5>
                </div>

                <Row className="mb-3">
                  <Col lg={6} md={12}>
                    <div className="d-flex justify-content-between mb-2">
                      <h6>Measurement Type:</h6>
                    </div>

                    <SingleSelect
                      data={measurementTypes}
                      value={measurementType}
                      placeholder={"Select one of them..."}
                      isDisabled={false}
                      isLoading={false}
                      handleChange={handleMeasurementChange}
                    />
                  </Col>
                  <Col lg={6} md={12}>
                    <div className="d-flex justify-content-between mb-2">
                      <h6>Solar Spectrum (%):</h6>
                    </div>
                    <NumericInput
                      precision={2}
                      value={spectrumPercentage}
                      onChange={(e) =>
                        e ? setSpectrumPercentage(e) : setSpectrumPercentage(0)
                      }
                      className="form-control"
                      min={0}
                      step={1}
                      max={100}
                      format={(num) => {
                        return num + " %";
                      }}
                    />
                  </Col>
                </Row>

                {calculationType?.value === "single" && (
                  <Row className="pb-3">
                    <Col lg={6} md={12}>
                      <div className="d-flex justify-content-between mb-2">
                        <h6>Voltage (Vpm):</h6>
                      </div>
                      <NumericInput
                        className="form-control"
                        onChange={(e) =>
                          e ? setVoltagePM(e) : setVoltagePM(0)
                        }
                        min={0}
                        max={1000000000}
                        value={voltagePM}
                      />
                    </Col>
                    <Col lg={6} md={12}>
                      <div className="d-flex justify-content-between mb-2">
                        <h6>Current (Ipm):</h6>
                      </div>
                      <NumericInput
                        className="form-control"
                        onChange={(e) =>
                          e ? setCurrentPM(e) : setCurrentPM(0)
                        }
                        min={0}
                        max={1000000000}
                        value={currentPM}
                      />
                    </Col>
                  </Row>
                )}

                {measurementType?.value === "string_iv" && (
                  <Row className="pb-3">
                    <Col lg={6} md={12}>
                      <div className="d-flex justify-content-between mb-2">
                        <h6>Modules in Series:</h6>
                      </div>
                      <NumericInput
                        value={mods_in_series}
                        onChange={(e) =>
                          e ? set_mods_in_series(e) : set_mods_in_series(0)
                        }
                        className="form-control"
                        min={0}
                        max={1000000000}
                        step={1}
                        onKeyDown={(e) => {
                          if (e.key === ".") {
                            e.preventDefault();
                          }
                        }}
                      />
                    </Col>
                    <Col lg={6} md={12}>
                      <div className="d-flex justify-content-between mb-2">
                        <h6>String in Parallel:</h6>
                      </div>

                      <NumericInput
                        className="form-control"
                        onChange={(e) =>
                          e ? set_mods_in_parallel(e) : set_mods_in_parallel(0)
                        }
                        min={0}
                        max={1000000000}
                        value={mods_in_parallel}
                        step={1}
                        onKeyDown={(e) => {
                          if (e.key === ".") {
                            e.preventDefault();
                          }
                        }}
                      />
                    </Col>
                  </Row>
                )}

                {calculationType?.value === "bulk" && (
                  <Row className="pb-2 pt-1">
                    <Col sm={12} md={12} lg={6}>
                      <label htmlFor="upload_zip" className="w-100">
                        <input
                          style={{ display: "none" }}
                          id="upload_zip"
                          name="upload_zip"
                          type="file"
                          accept=".csv, .xlsx"
                          onChange={(e) => setFile(e.target.files[0])}
                          onClick={(event) => {
                            const { target = {} } = event || {};
                            target.value = "";
                          }}
                        />
                        <Button
                          color="secondary"
                          className="px-4 w-100"
                          variant="contained"
                          component="span"
                        >
                          Choose CSV/XLSX File
                        </Button>
                      </label>
                    </Col>
                    <Col sm={12} md={12} lg={6}>
                      <input
                        className="form-control mb-2"
                        type="text"
                        value={file?.name ? file?.name : ""}
                        disabled={true}
                      />
                    </Col>
                  </Row>
                )}

                <Row className="pb-4">
                  <Col lg={6} md={12}>
                    <div className="d-flex justify-content-between mb-2">
                      <h6>Power (temp coeff. %/degC), Abs:</h6>
                    </div>
                    <NumericInput
                      precision={2}
                      value={gamma}
                      onChange={(e) => (e ? setGamma(e) : setGamma(0))}
                      className="form-control"
                      min={0}
                      step={0.1}
                      max={100}
                      format={(num) => {
                        return `${num}%`;
                      }}
                    />
                  </Col>

                  <Col lg={6} md={12}>
                    {calculationType.value === "bulk" ? (
                      <React.Fragment>
                        <div className="d-flex justify-content-between mb-2">
                          <h6 className="py-2">{""}</h6>
                        </div>
                        <Button
                          color="primary"
                          className="px-4 w-100"
                          variant="contained"
                          component="span"
                          onClick={bulkCalculation}
                        >
                          Calculate
                        </Button>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <div className="d-flex justify-content-between mb-2">
                          <h6>Uncertainty (%):</h6>
                        </div>
                        <input
                          type="text"
                          className="form-control"
                          disabled={true}
                          value={
                            String(uncertainty) === "Infinity"
                              ? "Infinity"
                              : +String(uncertainty) + "%"
                          }
                        />
                      </React.Fragment>
                    )}
                  </Col>
                </Row>

                {calculationType.value === "single" && (
                  <Row>
                    <Col lg={6} md={12}>
                      <Button
                        color="primary"
                        className="px-4 w-100"
                        variant="contained"
                        component="span"
                        onClick={calculateUncertainty}
                      >
                        Calculate
                      </Button>
                    </Col>
                  </Row>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      )}

      <ToastContainer />
      <Backdrop
        className={classes.backdrop}
        style={{ zIndex: "10" }}
        open={loading}
      >
        <CircularProgress color="inherit" />
        <h3
          className="m-3 text-white"
          hidden={!loadingToolong}
          style={{
            position: "absolute",
            top: "52%",
          }}
        >
          Please wait, under process
        </h3>
      </Backdrop>
      <Footer />
    </div>
  );
};

export default UncerCalcService;
