import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import {
  Backdrop,
  Button,
  CircularProgress,
  Container,
  makeStyles,
  Tooltip,
} from "@material-ui/core";
import Footer from "../components/Footer";
import { SingleSelect } from "../components/SingleSelect";
import { InputGroup, Row, Col, FormControl } from "react-bootstrap";

import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
// import Paper from "@material-ui/core/Paper";
import downloadIco from "../assets/images/cloud-computing.png";
import backLogo from "../assets/images/back.png";
import MyLoader from "../components/ContentLoader";

import infoIcon from "../assets/images/info.png";
import axios from "axios";
import {
  GET_ALL_THERMAGIX_PROCESS,
  GET_ML_MODEL_BY_SERVICE_ID,
  GET_MODEL_OPTION_BY_ML_ID,
  GET_SERVICE_BY_ID,
  START_THERMAGIX_SERVICE,
} from "../shared/ApiURLs";
import { addHeaderInAxios, checkSignature, sliceName } from "../utils/Helpers";
import { toast, ToastContainer } from "react-toastify";
import ReactPaginate from "react-paginate";

const theme = createMuiTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        color: "black",
        backgroundColor: "white",
        padding: "10px",
        border: "2px solid #3f51b5",
        boxShadow: `0px 2px 4px -1px rgb(0 0 0 / 20%),0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)`,
      },
    },
  },
});

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const ThermagixService = (props) => {
  const classes = useStyles();

  const [service, setService] = useState([]);
  const [MLModelValue, setMLModelValue] = useState("");
  const [MLModelArr, setMLModelArr] = useState([]);
  const [MLModelThermalVal, setMLModelThermalVal] = useState("");
  const [MLModelVisualVal, setMLModelVisualVal] = useState("");
  const [MLModelOptThermal, setMLModelThermalArr] = useState([]);
  const [MLModelVisualArr, setMLModelVisualArr] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [iterationNum, setIterationNum] = useState("");
  const [loading, setLoading] = useState(false);
  const [process, setProcess] = useState([]);
  const [isContentLoading, setContentLoading] = useState(true);
  const [searchBarValue, setSearchBarValue] = useState("");
  const [filteredProcessArr, setFilteredProcessArr] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);

  const dataPerPage = 4;
  const pageVisited = pageNumber * dataPerPage;
  const pageCount = Math.ceil(filteredProcessArr.length / dataPerPage);

  useEffect(() => {
    addHeaderInAxios(props)
      ? getServiceById(props.match.params.service_id)
      : props.history.replace("/");
  }, []);

  useEffect(() => {
    console.log(MLModelOptThermal);
    console.log(MLModelVisualArr);
  }, [MLModelValue]);

  const getServiceById = (service_id) => {
    axios
      .get(GET_SERVICE_BY_ID(service_id))
      .then((res) => {
        setService(res.data.service);
        getML_Models(res.data.service[0].id);
        getAllProcess(res.data.service[0].id);
        setContentLoading(false);
      })
      .catch((error) => {
        checkSignature(error);
        setContentLoading(false);
        props.history.replace("/services");
      });
  };

  const getML_Models = (service_id) => {
    axios
      .get(GET_ML_MODEL_BY_SERVICE_ID(service_id))
      .then((res) => {
        setMLModelArr(res.data.ml_models);
      })
      .catch((error) => {
        checkSignature(error);
      });
  };

  const getML_Model_Optns = (ml_id) => {
    axios
      .get(GET_MODEL_OPTION_BY_ML_ID(ml_id))
      .then((res) => {
        setMLModelThermalArr(res.data.models_thermal);
        setMLModelVisualArr(res.data.models_visual);

        let thermal = res.data.models_thermal.length
          ? res.data.models_thermal[res.data.models_thermal.length - 1]
          : "";
        setMLModelThermalVal(typeof thermal === "string" ? "" : { ...thermal });

        let visual = res.data.models_visual.length
          ? res.data.models_visual[res.data.models_visual.length - 1]
          : "";
        setMLModelVisualVal(typeof visual === "string" ? "" : { ...visual });
      })

      .catch((error) => {
        checkSignature(error);
      });
  };

  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
    console.log(event.target.files[0]);
  };

  const onMLModelSelectChange = (selectedOption, triggeredAction) => {
    if (triggeredAction.action === "clear") {
      setMLModelValue("");
      setMLModelThermalVal("");
      setMLModelVisualVal("");
      setMLModelThermalArr([]);
      setMLModelVisualArr([]);
    } else {
      setMLModelThermalVal("");
      setMLModelVisualVal("");
      setMLModelThermalArr([]);
      setMLModelVisualArr([]);
      setMLModelValue(selectedOption);
      getML_Model_Optns(selectedOption?.id);
    }
  };

  // const onMLModelOptSelectChange = (selectedOption, triggeredAction) => {
  //   if (triggeredAction.action === "clear") {
  //     return setMLModelOptValue(false);
  //   } else {
  //     setMLModelOptValue(selectedOption);
  //   }
  // };

  const getAllProcess = (service_id) => {
    axios
      .post(GET_ALL_THERMAGIX_PROCESS, { service_id: parseInt(service_id) })
      .then((res) => {
        setProcess(res.data.process);
        setFilteredProcessArr(res.data.process);
      })
      .catch((error) => {
        checkSignature(error);
      });
  };

  const SubmitService = () => {
    if (validateService()) {
      let model_thermal = MLModelThermalVal
        ? parseInt(MLModelThermalVal.id)
        : null;

      let model_visual = MLModelVisualVal
        ? parseInt(MLModelVisualVal.id)
        : null;

      if (!model_thermal && !model_visual) {
        return handleToast(
          "error",
          "Selected atleast one from Thermal/Visual Model!"
        );
      }

      setLoading(true);

      const formdata = new FormData();
      formdata.append("ml_model_id", parseInt(MLModelValue.id));
      formdata.append("model_thermal_id", model_thermal);
      formdata.append("model_visual_id", model_visual);
      formdata.append("service_id", parseInt(service[0].id));
      formdata.append("iterationNum", iterationNum);
      formdata.append("file", selectedFile);
      formdata.append("file_name", selectedFile.name);
      axios
        .post(START_THERMAGIX_SERVICE, formdata)
        .then((res) => {
          console.log(res);
          // setSelectedFile(null);
          // setMLModelThermalVal("");
          // setMLModelVisualVal("");
          // setMLModelValue("");
          // setIterationNum("");
          getAllProcess(service[0].id);
          setLoading(false);
          handleToast("success", res.data.message);
        })
        .catch((e) => {
          console.log(e);
          // setSelectedFile(null);
          // setMLModelThermalVal("");
          // setMLModelVisualVal("");
          // setMLModelValue("");
          // setIterationNum("");
          setLoading(false);
          handleToast("error", "Service Not Submitted, Please Try Again");
        });
    } else {
      setLoading(false);
      handleToast("error", "Please fill all the fields!");
    }
  };

  const validateService = () => {
    if (
      MLModelValue === "" ||
      Object.keys(MLModelValue).length === 0 ||
      selectedFile === "" ||
      selectedFile === null ||
      selectedFile === undefined ||
      iterationNum === "" ||
      iterationNum === null ||
      iterationNum === undefined
    ) {
      return false;
    } else {
      return true;
    }
  };

  const handleToast = (event_type, message) => {
    return event_type === "success"
      ? toast.success(message)
      : toast.error(message);
  };

  const backBtnHandler = () => {
    return props.history.push("/services");
  };

  const handleSearch = () => {
    let newProcessArr = process.filter((el) => {
      return String(el?.original_uploaded_zip_filename)
        .toLowerCase()
        .includes(searchBarValue.toLowerCase());
    });

    setFilteredProcessArr(newProcessArr);
    return newProcessArr;
  };

  const setFilteredProcessToOriginal = () => setFilteredProcessArr(process);

  const onPageChange = ({ selected }) => {
    setPageNumber(selected);
    console.log(selected);
  };

  return (
    <div className="main-wrapper">
      <Header {...props} />
      {isContentLoading && <MyLoader />}
      {!isContentLoading && (
        <Container className="mb-5">
          <Row>
            <Col sm={12} md={7} className="my-2 h-auto">
              <div className="custom_card px-4 py-3 h-100">
                <div>
                  <div>
                    <span>
                      <img
                        src={backLogo}
                        alt="back"
                        className="backBtn"
                        onClick={backBtnHandler}
                      />
                    </span>
                    <span className="mx-3 sscalcHeading d-inline pb-2">
                      {service[0]?.name}
                    </span>
                  </div>

                  <div className="py-1 px-2 my-2">
                    <Row>
                      <Col sm={12} md={4} className="my-2">
                        <div>
                          <div className="d-flex justify-content-between mb-2">
                            <h6>Select Analysis Type</h6>
                            {MLModelValue && (
                              <MuiThemeProvider theme={theme}>
                                <Tooltip
                                  title={`${MLModelValue.label} : ${MLModelValue.description}`}
                                  arrow
                                >
                                  <img
                                    src={infoIcon}
                                    className="infoIcon"
                                    alt="info"
                                  />
                                </Tooltip>
                              </MuiThemeProvider>
                            )}
                          </div>
                          <SingleSelect
                            data={MLModelArr}
                            value={MLModelValue}
                            placeholder={"Select Analysis Type..."}
                            isDisabled={false}
                            isLoading={false}
                            handleChange={onMLModelSelectChange}
                            isClearable={true}
                          />
                        </div>
                      </Col>
                      <Col sm={12} md={8} className="my-2">
                        {localStorage.getItem("is_admin") === "true" && (
                          <Row>
                            <Col sm={12} md={6}>
                              <div className="d-flex justify-content-between mb-2">
                                <h6>Thermal Model</h6>
                                {MLModelThermalVal && (
                                  <MuiThemeProvider theme={theme}>
                                    <Tooltip
                                      title={`${MLModelThermalVal.label} : ${MLModelThermalVal.description}`}
                                      arrow
                                    >
                                      <img
                                        src={infoIcon}
                                        className="infoIcon"
                                        alt="info"
                                      />
                                    </Tooltip>
                                  </MuiThemeProvider>
                                )}
                              </div>

                              <SingleSelect
                                data={MLModelOptThermal}
                                value={MLModelThermalVal}
                                placeholder={"Select Model..."}
                                isDisabled={!MLModelValue}
                                isLoading={!MLModelValue}
                                handleChange={setMLModelThermalVal}
                                isClearable={true}
                              />
                            </Col>
                            <Col sm={12} md={6}>
                              <div className="d-flex justify-content-between mb-2">
                                <h6>Visual Model</h6>
                                {MLModelVisualVal && (
                                  <MuiThemeProvider theme={theme}>
                                    <Tooltip
                                      title={`${MLModelVisualVal.label} : ${MLModelVisualVal.description}`}
                                      arrow
                                    >
                                      <img
                                        src={infoIcon}
                                        className="infoIcon"
                                        alt="info"
                                      />
                                    </Tooltip>
                                  </MuiThemeProvider>
                                )}
                              </div>

                              <SingleSelect
                                data={MLModelVisualArr}
                                value={MLModelVisualVal}
                                placeholder={"Select Model..."}
                                isDisabled={!MLModelValue}
                                isLoading={!MLModelValue}
                                handleChange={setMLModelVisualVal}
                                isClearable={true}
                              />
                            </Col>
                          </Row>
                        )}
                      </Col>
                    </Row>
                    <div className="mt-3 mb-4">
                      <input
                        type="text"
                        placeholder="Enter Experiment Name..."
                        className="form-control w-md-75 w-100"
                        maxLength={100}
                        value={iterationNum}
                        onChange={(e) => setIterationNum(e.target.value)}
                      />
                    </div>
                    <div className="my-3">
                      <div className="">
                        <Row>
                          <Col sm={12} md={4}>
                            <label htmlFor="upload-photo" className="w-100">
                              <input
                                style={{ display: "none" }}
                                id="upload-photo"
                                name="upload-photo"
                                type="file"
                                accept=".zip , .png , .jpg"
                                onChange={changeHandler}
                                onClick={(event) => {
                                  const { target = {} } = event || {};
                                  target.value = "";
                                }}
                              />
                              <Button
                                color="secondary"
                                className="px-4 w-100"
                                variant="contained"
                                component="span"
                              >
                                Choose File
                              </Button>
                            </label>
                          </Col>
                          <Col sm={12} md={8}>
                            <input
                              className="form-control w-100"
                              type="text"
                              value={
                                selectedFile?.name ? selectedFile?.name : ""
                              }
                              readOnly
                            />
                          </Col>
                        </Row>
                      </div>
                    </div>
                    <Button
                      onClick={SubmitService}
                      variant="contained"
                      color="primary"
                      className="px-4 my-3"
                    >
                      Submit
                    </Button>
                  </div>
                </div>
              </div>
            </Col>
            <Col sm={12} md={5} className="my-2">
              <div className="custom_card px-4 py-3">
                <div>
                  <h2 className="my-2">Help</h2>
                  <p className="my-3 text-justify">
                    <b>What is {service[0]?.name} service?</b>
                    <br />
                    {service[0]?.description}
                  </p>
                  <p className="my-3 text-justify">
                    <b>How to use {service[0]?.name} service?</b>
                    <br />
                    {service[0]?.how_to_use}
                  </p>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              {/* Table Content */}
              {process.length !== 0 && (
                <div className="custom_card px-3 mt-3 py-3">
                  <Row className="my-3">
                    <Col sm={12} md={6}>
                      <div className="mb-3">
                        <InputGroup className="search-bar">
                          <FormControl
                            placeholder="Search by zip name..."
                            aria-label="Search by name"
                            aria-describedby="basic-addon2"
                            value={searchBarValue}
                            onChange={(e) => {
                              setSearchBarValue(e.target.value);
                            }}
                          />
                          <Button
                            style={{ borderRadius: 0 }}
                            variant="contained"
                            color="primary"
                            id="button-addon2"
                            onClick={handleSearch}
                          >
                            Search
                          </Button>
                        </InputGroup>
                      </div>
                    </Col>
                  </Row>

                  <TableContainer>
                    <Table aria-label="CustomTable">
                      <TableHead>
                        <TableRow>
                          <TableCell align="left">Filename</TableCell>
                          <TableCell align="center">Size</TableCell>
                          <TableCell align="center">Analysis</TableCell>
                          <TableCell align="center">Thermal Model</TableCell>
                          <TableCell align="center">Visual Model</TableCell>
                          <TableCell align="center">Status</TableCell>
                          <TableCell align="center">Download</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {filteredProcessArr
                          .slice(pageVisited, pageVisited + dataPerPage)
                          .map((el) => {
                            return (
                              <TableRow key={el?.id}>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  className="wrapText"
                                  align="left"
                                >
                                  <a
                                    href={el?.uploaded_zip_url}
                                    download
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    {sliceName(
                                      el?.original_uploaded_zip_filename
                                    )}
                                  </a>
                                </TableCell>
                                {el?.uploaded_zip_size !== null && (
                                  <TableCell align="center">
                                    {String(
                                      (
                                        parseInt(el?.uploaded_zip_size) /
                                        1024 /
                                        1024
                                      ).toFixed(2)
                                    ) + " MB"}
                                  </TableCell>
                                )}

                                {el?.uploaded_zip_size === null && (
                                  <TableCell align="center">NA</TableCell>
                                )}

                                <TableCell align="center">
                                  {(el?.ml_model__name).replace("_", " ")}
                                </TableCell>
                                <TableCell align="center">
                                  {el?.ml_model_opt_thermal__name}
                                </TableCell>
                                <TableCell align="center">
                                  {el?.ml_model_opt_visual__name}
                                </TableCell>
                                <TableCell align="center">
                                  {el?.status}
                                </TableCell>
                                <TableCell align="center">
                                  <a
                                    href={el?.processed_zip_url}
                                    download
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    <img
                                      className="hovImg"
                                      src={downloadIco}
                                      alt="download"
                                    />
                                  </a>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                    {!filteredProcessArr.length && (
                      <Col md={12} className="my-3">
                        <h3 className="text-center">
                          No Process Found with name {searchBarValue}!
                        </h3>
                        <div className="text-center mt-2">
                          <Button
                            variant="outlined"
                            color="primary"
                            onClick={setFilteredProcessToOriginal}
                          >
                            Get All Processes
                          </Button>
                        </div>
                      </Col>
                    )}

                    {filteredProcessArr.length > 0 && (
                      <ReactPaginate
                        previousLabel={"Previous"}
                        nextLabel={"Next"}
                        pageCount={pageCount}
                        onPageChange={onPageChange}
                        containerClassName={"pagnateContainer"}
                        previousLinkClassName={"navigateBtn"}
                        nextLinkClassName={"navigateBtn"}
                        disabledClassName={"disabledBtn"}
                        activeClassName={"activeBtn"}
                      />
                    )}
                  </TableContainer>
                </div>
              )}
            </Col>
          </Row>
        </Container>
      )}

      <ToastContainer />
      <Backdrop
        className={classes.backdrop}
        style={{ zIndex: "10" }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Footer />
    </div>
  );
};

export default ThermagixService;
