const serverURL = process.env.REACT_APP_BASE_URL;

// User Management
export const LOGIN = `${serverURL}/login/`;
export const FORGOT_PASSWORD = `${serverURL}/forget/`;
export const RESET_PASSWORD = `${serverURL}/reset/`;

// Services
export const GET_SERVICES = `${serverURL}/services/all`;
export const GET_SERVICE_BY_ID = (id) => `${serverURL}/services/${id}`;

// Thermagix
export const GET_ML_MODEL_BY_SERVICE_ID = (id) =>
  `${serverURL}/services/ml_models/${id}`;
export const GET_MODEL_OPTION_BY_ML_ID = (id) =>
  `${serverURL}/services/ml_model_opt/${id}`;
export const START_THERMAGIX_SERVICE = `${serverURL}/services/thermagix/start`;
export const GET_ALL_THERMAGIX_PROCESS = `${serverURL}/services/thermagix_process/all`;

// IVSTC
export const START_IVSTC_SERVICE = `${serverURL}/services/IVSTC/start`;
export const GET_ALL_IVSTC_PROCESS = `${serverURL}/services/IVSTC_process/all`;
export const GET_ALL_IVSTC_PROCESSED_PROCESS = `${serverURL}/services/IVSTC_process/processed`;

// IVEL-Inference
export const START_IVEL_SERVICE = `${serverURL}/services/IVEL/start`;
export const GET_ALL_IVEL_PROCESS = `${serverURL}/services/IVEL_process/all`;
export const GET_IVEL_MOUNT_AND_MODELS = `${serverURL}/services/IVEL/get_models_and_mount`;
export const GET_ALL_IVEL_PROCESSED_PROCESS = `${serverURL}/services/IVEL_process/processed`;

// GHI-POA Calc
export const START_GHI_POA_CALC = `${serverURL}/services/start_ghi_poa_calc`;
export const GET_Process_GHIPOA = `${serverURL}/services/get_ghi_poa_calc_process`;

// Micro Services
export const CALCULATE_UNCERTAINTY = `${serverURL}/services/calc_uncertainty`;
export const CALCULATE_SATANGLE = `${serverURL}/services/calc_satAngle`;
export const IMPORT_INTERP_DATA = `${serverURL}/services/interp_tool/import`;
export const EXPORT_INTERP_DATA = `${serverURL}/services/interp_tool/export`;
export const CALC_INTERP_DATA = `${serverURL}/services/interp_tool/calculate`;
export const ADD_CLASSIFICATION_RULE = `${serverURL}/services/add-el-class-rule`;
export const GET_CLASSIFICATION_RULE = `${serverURL}/services/get-el-class-rule`;

// IVSTC-2 Service
export const GET_MODULE_DETAILS = `${serverURL}/services/ivstc-2/get-module-details`;
export const START_IVSTC_2_SERVICE = `${serverURL}/services/IVSTC-2/start`;
export const GET_ALL_IVSTC_2_PROCESS = (status) =>
  `${serverURL}/services/IVSTC-2_process/${status}`;

// Public Service
export const ALL_SERVICES_UNAUTH_USER = `${serverURL}/services/unauth`;
export const GET_INVERTER_DETAILS = `${serverURL}/services/get-inverter-data`;

// thermagix-2
export const START_THERMAGIX_2_SERVICE = `${serverURL}/services/thermagix-2/start`;
export const GET_ALL_THERMAGIX_2_PROCESS = `${serverURL}/services/thermagix-2-process/all`;

// common
export const GET_PVD_PROJECTS = `${serverURL}/services/get_all_projects`;

export const GET_PREVIOUS_DETAILS_IVSTC2 = `${serverURL}/services/getSelectedProjectpreviouseDetails/`;

// IVOPC-QC
export const START_IVOPCQC_SERVICE = `${serverURL}/services/IVOPC-QC/start`;
export const GET_ALL_IVOPCQC_PROCESS = `${serverURL}/services/IVOPC_process/all`;
