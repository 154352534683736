export const timeZones = [
  {
    value: "-12.00",
    label: "Etc/GMT-12",
  },
  {
    value: "-11.00",
    label: "Etc/GMT-11",
  },
  {
    value: "-11.00",
    label: "Pacific/Midway",
  },
  {
    value: "-10.00",
    label: "America/Adak",
  },
  {
    value: "-09.00",
    label: "America/Anchorage",
  },
  {
    value: "-09.00",
    label: "Pacific/Gambier",
  },
  {
    value: "-08.00",
    label: "America/Dawson_Creek",
  },
  {
    value: "-08.00",
    label: "America/Ensenada",
  },
  {
    value: "-08.00",
    label: "America/Los_Angeles",
  },
  {
    value: "-07.00",
    label: "America/Chihuahua",
  },
  {
    value: "-07.00",
    label: "America/Denver",
  },
  {
    value: "-06.00",
    label: "America/Belize",
  },
  {
    value: "-06.00",
    label: "America/Cancun",
  },
  {
    value: "-06.00",
    label: "America/Chicago",
  },
  {
    value: "-06.00",
    label: "Chile/EasterIsland",
  },
  {
    value: "-05.00",
    label: "America/Bogota",
  },
  {
    value: "-05.00",
    label: "America/Havana",
  },
  {
    value: "-05.00",
    label: "America/New_York",
  },
  {
    value: "-04.50",
    label: "America/Caracas",
  },
  {
    value: "-04.00",
    label: "America/Campo_Grande",
  },
  {
    value: "-04.00",
    label: "America/Glace_Bay",
  },
  {
    value: "-04.00",
    label: "America/Goose_Bay",
  },
  {
    value: "-04.00",
    label: "America/Santiago",
  },
  {
    value: "-04.00",
    label: "America/La_Paz",
  },
  {
    value: "-03.00",
    label: "America/Argentina/Buenos_Aires",
  },
  {
    value: "-03.00",
    label: "America/Montevideo",
  },
  {
    value: "-03.00",
    label: "America/Araguaina",
  },
  {
    value: "-03.00",
    label: "America/Godthab",
  },
  {
    value: "-03.00",
    label: "America/Miquelon",
  },
  {
    value: "-03.00",
    label: "America/Sao_Paulo",
  },
  {
    value: "-03.50",
    label: "America/St_Johns",
  },
  {
    value: "-02.00",
    label: "America/Noronha",
  },
  {
    value: "-01.00",
    label: "Atlantic/Cape_Verde",
  },
  {
    value: "00.00",
    label: "Europe/Belfast",
  },
  {
    value: "00.00",
    label: "Africa/Abidjan",
  },
  {
    value: "00.00",
    label: "Europe/Dublin",
  },
  {
    value: "00.00",
    label: "Europe/Lisbon",
  },
  {
    value: "00.00",
    label: "Europe/London",
  },
  {
    value: "+01.00",
    label: "Africa/Algiers",
  },
  {
    value: "+01.00",
    label: "Africa/Windhoek",
  },
  {
    value: "+01.00",
    label: "Atlantic/Azores",
  },
  {
    value: "+01.00",
    label: "Atlantic/Stanley",
  },
  {
    value: "+01.00",
    label: "Europe/Amsterdam",
  },
  {
    value: "+01.00",
    label: "Europe/Belgrade",
  },
  {
    value: "+01.00",
    label: "Europe/Brussels",
  },
  {
    value: "+02.00",
    label: "Africa/Cairo",
  },
  {
    value: "+02.00",
    label: "Africa/Blantyre",
  },
  {
    value: "+02.00",
    label: "Asia/Beirut",
  },
  {
    value: "+02.00",
    label: "Asia/Damascus",
  },
  {
    value: "+02.00",
    label: "Asia/Gaza",
  },
  {
    value: "+02.00",
    label: "Asia/Jerusalem",
  },
  {
    value: "+03.00",
    label: "Africa/Addis_Ababa",
  },
  {
    value: "+03.00",
    label: "Asia/Riyadh89",
  },
  {
    value: "+03.00",
    label: "Europe/Minsk",
  },
  {
    value: "+03.50",
    label: "Asia/Tehran",
  },
  {
    value: "+04.00",
    label: "Asia/Dubai",
  },
  {
    value: "+04.00",
    label: "Asia/Yerevan",
  },
  {
    value: "+04.00",
    label: "Europe/Moscow",
  },
  {
    value: "+04.50",
    label: "Asia/Kabul",
  },
  {
    value: "+05.00",
    label: "Asia/Tashkent",
  },
  {
    value: "+05.50",
    label: "Asia/Kolkata",
  },
  {
    value: "+05.75",
    label: "Asia/Katmandu",
  },
  {
    value: "+06.00",
    label: "Asia/Dhaka",
  },
  {
    value: "+06.00",
    label: "Asia/Yekaterinburg",
  },
  {
    value: "+06.50",
    label: "Asia/Rangoon",
  },
  {
    value: "+07.00",
    label: "Asia/Bangkok",
  },
  {
    value: "+07.00",
    label: "Asia/Novosibirsk",
  },
  {
    value: "+08.00",
    label: "Etc/GMT+8",
  },
  {
    value: "+08.00",
    label: "Asia/Hong_Kong",
  },
  {
    value: "+08.00",
    label: "Asia/Krasnoyarsk",
  },
  {
    value: "+08.00",
    label: "Australia/Perth",
  },
  {
    value: "+08.75",
    label: "Australia/Eucla",
  },
  {
    value: "+09.00",
    label: "Asia/Irkutsk",
  },
  {
    value: "+09.00",
    label: "Asia/Seoul",
  },
  {
    value: "+09.00",
    label: "Asia/Tokyo",
  },
  {
    value: "+09.50",
    label: "Australia/Adelaide",
  },
  {
    value: "+09.50",
    label: "Australia/Darwin",
  },
  {
    value: "+09.50",
    label: "Pacific/Marquesas",
  },
  {
    value: "+10.00",
    label: "Etc/GMT+10",
  },
  {
    value: "+10.00",
    label: "Australia/Brisbane",
  },
  {
    value: "+10.00",
    label: "Australia/Hobart",
  },
  {
    value: "+10.00",
    label: "Asia/Yakutsk",
  },
  {
    value: "+10.50",
    label: "Australia/Lord_Howe",
  },
  {
    value: "+11.00",
    label: "Asia/Vladivostok",
  },
  {
    value: "+11.50",
    label: "Pacific/Norfolk",
  },
  {
    value: "+12.00",
    label: "Etc/GMT+12",
  },
  {
    value: "+12.00",
    label: "Asia/Anadyr",
  },
  {
    value: "+12.00",
    label: "Asia/Magadan",
  },
  {
    value: "+12.00",
    label: "Pacific/Auckland",
  },
  {
    value: "+12.75",
    label: "Pacific/Chatham",
  },
  {
    value: "+13.00",
    label: "Pacific/Tongatapu",
  },
  {
    value: "+14.00",
    label: "Pacific/Kiritimati",
  },
];
