import axios from "axios";
import { useEffect, useState } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import {
  ADD_CLASSIFICATION_RULE,
  GET_CLASSIFICATION_RULE,
  GET_SERVICE_BY_ID,
} from "../shared/ApiURLs";
import {
  addHeaderInAxios,
  checkSignature,
  el_classification_rule,
} from "../utils/Helpers";
import MyLoader from "../components/ContentLoader";
import { toast, ToastContainer } from "react-toastify";
import backLogo from "../assets/images/back.png";
import {
  Backdrop,
  CircularProgress,
  Container,
  makeStyles,
  Button,
} from "@material-ui/core";
import { Col, Row } from "react-bootstrap";
import NumericInput from "react-numeric-input";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const ELClassificationRules = (props) => {
  const classes = useStyles();
  const resetObj = el_classification_rule;
  const [service, setService] = useState([]);
  const [loading, setLoading] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const [isContentLoading, setContentLoading] = useState(true);
  const [elClassificationRuleObj, setELClassificationRuleObj] = useState(
    el_classification_rule
  );
  const [savedRules, setSavedRules] = useState([]);

  useEffect(() => {
    addHeaderInAxios(props);
    getServiceById(props.match.params.service_id);
    getClassificationRules();
  }, []);

  const getServiceById = (service_id) => {
    if (firstLoad) {
      axios
        .get(GET_SERVICE_BY_ID(service_id))
        .then((res) => {
          setFirstLoad(false);
          if (res.data.service[0].name) {
            setService(res.data.service);
            setContentLoading(false);
          } else {
            setContentLoading(false);
            handleToast("error", "Service Not Found!");
            props.history.replace("/services");
          }
        })
        .catch((error) => {
          checkSignature(error);
          setFirstLoad(false);
          setContentLoading(false);
          props.history.replace("/services");
        });
    }
  };

  const handleValueUpdate = (key = null, value, defect = null) => {
    let tempObj = elClassificationRuleObj;
    console.log(key, value, defect);
    if (!defect && !key) {
      console.log(value);
      tempObj.ruleName = value;
    } else {
      tempObj.defectTypes[defect][key] = value;
    }
    return setELClassificationRuleObj({ ...tempObj });
  };

  const handleToast = (event_type, message) => {
    return event_type === "success"
      ? toast.success(message)
      : toast.error(message);
  };

  const backBtnHandler = () => {
    return props.history.push("/services");
  };

  const addClassificationRule = () => {
    if (!elClassificationRuleObj.ruleName) {
      return handleToast("error", "Rule name is required!");
    }
    // let error = null;
    // Object.keys(elClassificationRuleObj.defectTypes).map((defect) => {
    //   if (
    //     elClassificationRuleObj?.defectTypes[defect].low > 0 &&
    //     elClassificationRuleObj?.defectTypes[defect].moderate > 0
    //   ) {
    //     if (
    //       elClassificationRuleObj?.defectTypes[defect].low >=
    //       elClassificationRuleObj?.defectTypes[defect].moderate
    //     ) {
    //       error = defect;
    //     }
    //   }
    // });

    // if (error) {
    //   return handleToast(
    //     "error",
    //     `${error} ; Moderate defect value should be greater then low defect value!`
    //   );
    // }

    setContentLoading(true);

    axios
      .post(ADD_CLASSIFICATION_RULE, { data: elClassificationRuleObj })
      .then((res) => {
        setContentLoading(false);
        getClassificationRules();
        handleToast("success", res.data.message);
      })
      .catch((error) => {
        checkSignature(error);
        setFirstLoad(false);
        setContentLoading(false);
        props.history.replace("/services");
      });
  };

  const getClassificationRules = () => {
    axios
      .get(GET_CLASSIFICATION_RULE)
      .then((res) => {
        setSavedRules(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const setClassificationRule = (data) => {
    setELClassificationRuleObj(data);
  };

  const resetData = () => {
    let obj = {};
    Object.keys(resetObj.defectTypes).map((defect) => {
      obj[defect] = { low: 0, moderate: 0 };
    });
    setELClassificationRuleObj({
      ruleName: "",
      defectTypes: obj,
    });
  };

  return (
    <div className="main-wrapper">
      <Header {...props} />
      {isContentLoading && <MyLoader />}
      {!isContentLoading && (
        <Container id="stringSizer" className="mb-5">
          <Row className="mb-3">
            <Col lg={8} md={8} sm={12} className="mb-3">
              <div className="custom_card p-4">
                <div className="mb-3 d-flex justify-content-between">
                  <div>
                    <span>
                      <img
                        src={backLogo}
                        alt="back"
                        className="backBtn"
                        onClick={backBtnHandler}
                      />
                    </span>
                    <span className="mx-3 sscalcHeading">
                      {service[0]?.name}
                    </span>
                  </div>
                  <div>
                    <Button
                      variant="contained"
                      color="secondary"
                      className="mx-2"
                      onClick={resetData}
                    >
                      Reset
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={addClassificationRule}
                    >
                      Save
                    </Button>
                  </div>
                </div>

                <div className="mt-4 mb-3 dynamicOverflow">
                  <div className="my-3 d-flex justify-content-between">
                    <h6 className="mt-2">Rule Name:</h6>
                    <input
                      type="text"
                      className="form-control w-75"
                      value={elClassificationRuleObj?.ruleName}
                      onChange={(e) => {
                        handleValueUpdate(null, e.target.value);
                      }}
                    ></input>
                  </div>
                  <div>
                    <table className="table table-striped table-bordered mb-0 ">
                      <thead className="thead-dark">
                        <tr>
                          <th scope="col" className="text-center p-2 w-50">
                            Defect
                          </th>
                          <th
                            scope="col"
                            className="text-center p-2 w-25 earthingInputs"
                          >
                            Low Defect
                          </th>
                          <th scope="col" className="text-center p-2 w-25">
                            Moderate Defect
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {Object.keys(elClassificationRuleObj?.defectTypes).map(
                          (defect) => (
                            <tr key={defect}>
                              <td
                                className="align-middle py-1"
                                style={{
                                  textTransform: "capitalize",
                                }}
                              >
                                {String(
                                  defect.replaceAll("_", " ")
                                ).toLowerCase()}
                              </td>
                              <td className="align-middle py-1">
                                <NumericInput
                                  className="form-control custMinWidth"
                                  value={
                                    elClassificationRuleObj?.defectTypes[
                                      defect
                                    ]["low"]
                                  }
                                  onChange={(v) =>
                                    handleValueUpdate("low", v, defect)
                                  }
                                  min={0}
                                  onKeyDown={(e) => {
                                    if (e.key === ".") {
                                      e.preventDefault();
                                    }
                                  }}
                                />
                              </td>
                              <td className="align-middle py-1">
                                <NumericInput
                                  className="form-control custMinWidth"
                                  value={
                                    elClassificationRuleObj?.defectTypes[
                                      defect
                                    ]["moderate"]
                                  }
                                  onChange={(v) =>
                                    handleValueUpdate("moderate", v, defect)
                                  }
                                  min={0}
                                  onKeyDown={(e) => {
                                    if (e.key === ".") {
                                      e.preventDefault();
                                    }
                                  }}
                                />
                              </td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <Row>
                <Col sm={12} md={6} className="my-2">
                  <div className="custom_card px-4 py-3">
                    <div>
                      <p className="my-3 text-justify">
                        <b>What is {service[0]?.name} service?</b>
                        <br />
                        {service[0]?.description}
                      </p>
                    </div>
                  </div>
                </Col>
                <Col sm={12} md={6} className="my-2">
                  <div className="custom_card px-4 py-3">
                    <div>
                      <p className="my-3 text-justify">
                        <b>How to use {service[0]?.name} service?</b>
                        <br />
                        {service[0]?.how_to_use}
                      </p>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>

            {savedRules.length > 0 && (
              <Col lg={4} md={4} sm={12}>
                <div className="custom_card px-4 py-3">
                  <div>
                    <h6 className="mt-3">Available Classification Rules:</h6>
                    <ul>
                      {savedRules.map((data) => (
                        <li
                          key={data.ruleName}
                          className="my-3 text-justify makeLink"
                          onClick={() => setClassificationRule(data)}
                        >
                          {data.ruleName}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </Col>
            )}
          </Row>
        </Container>
      )}

      <ToastContainer />
      <Backdrop
        className={classes.backdrop}
        style={{ zIndex: "10" }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Footer />
    </div>
  );
};

export default ELClassificationRules;
