import React from "react";
import { Modal } from "react-bootstrap";
import closeIco from "../assets/images/close.png";
import jsonIco from "../assets/images/json.png";

const ErrorLogModal = (props) => {
  const downloadErrorLogJson = () => {
    const dataStr =
      "data:text/json;charset=utf-8," +
      encodeURIComponent(JSON.stringify(props.errorLog));
    const link = document.createElement("a");
    link.href = dataStr;
    const timeStamp = parseInt(new Date().getTime());
    const fileName = `Error_Log_${timeStamp}.json`;
    link.setAttribute("download", `${fileName}`);
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  return (
    <Modal
      size={props.size}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={props.openErrorModal}
      onHide={() => props.setOpenErrorModal(false)}
      style={{
        // borderRadius: "4px",
        zIndex: 10000,
        maxHeight: "500px",
        padding: 0,
        backgroundColor: "transparent",
      }}
    >
      <Modal.Header
        className="p-1"
        style={{
          backgroundColor: "black",
          border: "none",
          color: "green",
        }}
      >
        {" "}
        <span>Zip Name : {props.zipName}</span>
        <span className="d-flex justify-content-end">
          <img
            className="hovImg"
            src={jsonIco}
            alt="jsonIco"
            style={{ cursor: "pointer", height: 16 }}
            onClick={() => downloadErrorLogJson()}
          />
          <img
            className="hovImg mx-1"
            src={closeIco}
            alt="closeIco"
            style={{ cursor: "pointer", height: 16 }}
            onClick={() => props.setOpenErrorModal(false)}
          />
        </span>
      </Modal.Header>
      <Modal.Body
        className="pb-0"
        style={{
          overflowY: "auto",
          backgroundColor: "black",
          color: "green",
          fontFamily: "monospace",
          fontSize: "small",
          borderBottomLeftRadius: "4px",
          borderBottomRightRadius: "4px",
        }}
      >
        <div>
          {props.errorLog && (
            <div>
              {props.errorLog.map((file) => {
                return (
                  <div key={file.file} className="mb-1">
                    <p className="m-0">File : {file.file}</p>
                    <p className="m-0">Errors :</p>
                    <ul>
                      {file.errors.map((e) => (
                        <li className="ml-2" key={e}>
                          {e}
                        </li>
                      ))}
                    </ul>
                    <hr
                      style={{
                        backgroundColor: "green",
                      }}
                    />
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ErrorLogModal;
