import React from "react";
import { Modal } from "react-bootstrap";

const InfoModal = (props) => {
  // const toTitleCase = (str) => {
  //   return str.replace(/\w\S*/g, function (txt) {
  //     return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  //   });
  // };
  return (
    <Modal
      size={props.size}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={props.openInfoModal_}
      onHide={() => props.setOpenInfoModal_(false)}
      style={{
        borderRadius: "5px",
      }}
    >
      <Modal.Body
        className="pb-0"
        style={{
          borderRadius: "5px",
          height: "400px",
          overflowY: "auto",
        }}
      >
        <div>
          {props.infoData_ && (
            <table className="table table-bordered">
              <thead className="thead-dark">
                <tr>
                  <th scope="col" className="w-50">
                    Input
                  </th>
                  <th scope="col">Value</th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(props.infoData_).map(
                  (key) =>
                    key !== "id" &&
                    key !== "user_id" &&
                    key !== "service_id" && (
                      <tr key={key}>
                        <th scope="row">
                          {key === "lat"
                            ? "Latitude"
                            : key === "lon"
                            ? "Longitude"
                            : key === "ref_lon"
                            ? "Ref Longitude"
                            : key === "IAM_cf"
                            ? "IAM CF"
                            : key}
                        </th>
                        <td>
                          {String(
                            key === "FF"
                              ? parseFloat(props.infoData_[key]).toFixed(4)
                              : props.infoData_[key]
                          )}
                        </td>
                      </tr>
                    )
                )}
              </tbody>
            </table>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default InfoModal;
