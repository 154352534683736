import axios from "axios";
import React, { useEffect, useState } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import {
  GET_Process_GHIPOA,
  GET_SERVICE_BY_ID,
  START_GHI_POA_CALC,
} from "../shared/ApiURLs";
import {
  addHeaderInAxios,
  checkSignature,
  CSVDataFormat,
  seasonalTiltInputs,
  sliceName,
  StructureType,
  tooltipTheme,
  TranspositionModel,
} from "../utils/Helpers";
import MyLoader from "../components/ContentLoader";
import { toast, ToastContainer } from "react-toastify";
import backLogo from "../assets/images/back.png";
import NumericInput from "react-numeric-input";

import {
  Backdrop,
  CircularProgress,
  Container,
  makeStyles,
  Button,
  MuiThemeProvider,
  Tooltip,
} from "@material-ui/core";
import { Col, Row } from "react-bootstrap";
import { SingleSelect } from "../components/SingleSelect";
import { SeasonalTiltModal } from "../components/SeasonalTiltModal";
import ReactPaginate from "react-paginate";

import { InputGroup, FormControl } from "react-bootstrap";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import downloadIco from "../assets/images/cloud-computing.png";
import notepadIco from "../assets/images/notepad.png";
import infoIcon from "../assets/images/info.png";
import InfoModal from "../components/InfoModal";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const GHI_POA_CalcService = (props) => {
  const classes = useStyles();
  const [service, setService] = useState([]);
  const [loading, setLoading] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const [isContentLoading, setContentLoading] = useState(true);

  const [file, setFile] = useState(null);
  const [structureType, setStructureType] = useState(null);
  const [transpositionModel, setTranspositionModel] = useState(null);
  const [inputDataFormat, setInputDataFormat] = useState(null);
  const [albedo, setAlbedo] = useState(0);

  const [tilt, setTilt] = useState(0);
  const [azimuth, setAzimuth] = useState(0);
  const [seasonalData, setSeasonalData] = useState(seasonalTiltInputs);

  const [pitch, setPitch] = useState(0);
  const [collectorLength, setCollectorLength] = useState(0);
  const [trackerLimitingAngle, setTrackerLimitingAngle] = useState(0);

  const [showSeasonalTiltModal, setShowSeasonalTiltModal] = useState(false);

  const [process, setProcess] = useState([]);
  const [searchBarValue, setSearchBarValue] = useState("");
  const [filteredProcessArr, setFilteredProcessArr] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [openInfoModal, setOpenInfoModal] = useState(false);
  const [infoData, setInfoData] = useState(null);

  const dataPerPage = 4;
  const pageVisited = pageNumber * dataPerPage;
  const pageCount = Math.ceil(filteredProcessArr.length / dataPerPage);

  useEffect(() => {
    addHeaderInAxios(props)
      ? getServiceById(props.match.params.service_id)
      : props.history.replace("/");
  });

  useEffect(() => {
    console.log(seasonalData);
  }, [seasonalData]);

  const getServiceById = (service_id) => {
    if (firstLoad) {
      axios
        .get(GET_SERVICE_BY_ID(service_id))
        .then((res) => {
          setFirstLoad(false);
          setService(res.data.service);
          getAllProcess(res.data.service[0].id);
          setContentLoading(false);
        })
        .catch((error) => {
          checkSignature(error);
          setFirstLoad(false);
          setContentLoading(false);
          props.history.replace("/services");
        });
    }
  };

  const handleToast = (event_type, message) => {
    return event_type === "success"
      ? toast.success(message)
      : toast.error(message);
  };

  const backBtnHandler = () => {
    return props.history.push("/services");
  };

  const handleStructureTypeChange = (selectedOption, triggeredAction) => {
    if (triggeredAction.action === "clear") {
      setStructureType(null);
    } else {
      setStructureType(selectedOption);
    }
  };

  const handleTranspositionModelChange = (selectedOption, triggeredAction) => {
    if (triggeredAction.action === "clear") {
      setTranspositionModel(null);
    } else {
      setTranspositionModel(selectedOption);
    }
  };

  const handleInputDataFormatChange = (selectedOption, triggeredAction) => {
    if (triggeredAction.action === "clear") {
      setInputDataFormat(null);
    } else {
      setInputDataFormat(selectedOption);
    }
  };

  const submitService = () => {
    if (file === null) {
      return handleToast("error", "Please Select a CSV file!");
    } else if (structureType === null) {
      return handleToast("error", "Please Select Structure Type!");
    } else if (transpositionModel === null) {
      return handleToast("error", "Please Select Transpositional Model!");
    } else if (inputDataFormat === null) {
      return handleToast("error", "Please Select Input Data Format!");
    }

    const formdata = new FormData();
    formdata.append("file", file);
    formdata.append("structureType", structureType?.value);
    formdata.append("transpositionalModel", transpositionModel?.value);
    formdata.append("inputDataFormat", inputDataFormat.value);
    formdata.append("service_id", service[0].id);
    formdata.append("albedo", albedo);

    if (structureType.value === "fixed_tilt") {
      formdata.append("tilt", tilt);
      formdata.append("azimuth", azimuth);
    } else if (structureType.value === "tracker") {
      if (!pitch || !collectorLength || !trackerLimitingAngle) {
        return handleToast(
          "error",
          "Pitch, Collector Length, Tracker Limiting Angle should be greater than 0!"
        );
      }
      formdata.append("pitch", pitch);
      formdata.append("collectorLength", collectorLength);
      formdata.append("trackerLimitingAngle", trackerLimitingAngle);
    } else if (structureType.value === "seasonal_tilt") {
      formdata.append("seasonalData", JSON.stringify(seasonalData));
    }

    setLoading(true);
    axios
      .post(START_GHI_POA_CALC, formdata)
      .then((res) => {
        setLoading(false);
        handleToast("success", res.data.message);
        getAllProcess(service[0].id);
      })
      .catch((e) => {
        checkSignature(e);
        setLoading(false);
        if (!e.response?.data?.message) {
          return handleToast("error", String(e.response?.data));
        }
        handleToast("error", e?.response?.data?.message);
      });
  };

  const getAllProcess = (service_id) => {
    axios
      .post(GET_Process_GHIPOA, { service_id: parseInt(service_id) })
      .then((res) => {
        setProcess(res.data.process);
        setFilteredProcessArr(res.data.process);
      })
      .catch((error) => {
        checkSignature(error);
      });
  };

  const handleSearch = () => {
    let newProcessArr = process.filter((el) => {
      return String(el?.original_zip_name)
        .toLowerCase()
        .includes(searchBarValue.toLowerCase());
    });

    setFilteredProcessArr(newProcessArr);
    return newProcessArr;
  };

  const setFilteredProcessToOriginal = () => setFilteredProcessArr(process);

  const onPageChange = ({ selected }) => {
    setPageNumber(selected);
  };

  return (
    <div className="main-wrapper">
      <Header {...props} />
      {isContentLoading && <MyLoader />}
      {!isContentLoading && (
        <Container className="">
          <Row>
            <Col lg={6} md={12} className="mb-3">
              <div className="custom_card p-4">
                <div className="mb-3">
                  <span>
                    <img
                      src={backLogo}
                      alt="back"
                      className="backBtn"
                      onClick={backBtnHandler}
                    />
                  </span>
                  <span className="mx-3 sscalcHeading">{service[0]?.name}</span>
                </div>

                <Row className="mb-3">
                  <Col lg={6} md={12}>
                    <div className="d-flex justify-content-between mb-2">
                      <h6>Select Structure Type:</h6>
                    </div>
                    <SingleSelect
                      data={StructureType}
                      value={structureType}
                      placeholder={"Select one of them..."}
                      isDisabled={false}
                      isLoading={false}
                      handleChange={handleStructureTypeChange}
                    />
                  </Col>
                  <Col lg={6} md={12}>
                    <div className="d-flex justify-content-between mb-2">
                      <h6>Select Transposition Model:</h6>
                    </div>
                    <SingleSelect
                      data={TranspositionModel}
                      value={transpositionModel}
                      placeholder={"Select one of them..."}
                      isDisabled={false}
                      isLoading={false}
                      handleChange={handleTranspositionModelChange}
                    />
                  </Col>
                </Row>

                {structureType?.value === "fixed_tilt" && (
                  <Row className="mb-3">
                    <Col lg={6} md={12}>
                      <div className="d-flex justify-content-between mb-2">
                        <div className="d-flex w-100 justify-content-between">
                          <h6>Tilt:</h6>
                          <span>
                            {" "}
                            <MuiThemeProvider theme={tooltipTheme}>
                              <Tooltip title={`Range from 0° to 90°`}>
                                <img
                                  src={infoIcon}
                                  className="infoIcon"
                                  alt="info"
                                />
                              </Tooltip>
                            </MuiThemeProvider>
                          </span>
                        </div>
                      </div>
                      <NumericInput
                        value={tilt}
                        onChange={(val) => setTilt(val)}
                        className="form-control"
                        min={0}
                        max={90}
                      />
                    </Col>
                    <Col lg={6} md={12}>
                      <div className="d-flex justify-content-between mb-2">
                        <div className="d-flex w-100 justify-content-between">
                          <h6>Azimuth:</h6>
                          <span>
                            {" "}
                            <MuiThemeProvider theme={tooltipTheme}>
                              <Tooltip title={`Range from 0° to 360°`}>
                                <img
                                  src={infoIcon}
                                  className="infoIcon"
                                  alt="info"
                                />
                              </Tooltip>
                            </MuiThemeProvider>
                          </span>
                        </div>
                      </div>
                      <NumericInput
                        value={azimuth}
                        onChange={(val) => setAzimuth(val)}
                        className="form-control"
                        min={0}
                        max={360}
                      />
                    </Col>
                  </Row>
                )}

                {structureType?.value === "tracker" && (
                  <React.Fragment>
                    <Row className="mb-3">
                      <Col lg={6} md={12}>
                        <div className="d-flex justify-content-between mb-2">
                          <h6>Pitch (m):</h6>
                        </div>
                        <NumericInput
                          className="form-control"
                          onChange={(val) => setPitch(val)}
                          min={0}
                          max={1000000000}
                          value={pitch}
                          format={(num) => {
                            return num + " m";
                          }}
                        />
                      </Col>
                      <Col lg={6} md={12}>
                        <div className="d-flex justify-content-between mb-2">
                          <h6>collector Length (m):</h6>
                        </div>
                        <NumericInput
                          className="form-control"
                          onChange={(val) => setCollectorLength(val)}
                          min={0}
                          max={1000000000}
                          value={collectorLength}
                          format={(num) => {
                            return num + " m";
                          }}
                        />
                      </Col>
                    </Row>
                    <Row className="mb-4">
                      <Col lg={6} md={12}>
                        <div className="d-flex justify-content-between mb-2">
                          <h6>Tracker Limiting Angle (°):</h6>
                        </div>
                        <NumericInput
                          className="form-control"
                          onChange={(val) => setTrackerLimitingAngle(val)}
                          min={0}
                          max={75}
                          value={trackerLimitingAngle}
                          format={(num) => {
                            return num + "°";
                          }}
                          onKeyDown={(e) => {
                            if (e.key === ".") {
                              e.preventDefault();
                            }
                          }}
                        />
                      </Col>

                      <Col lg={6} md={12}>
                        <div className="d-flex justify-content-between mb-2">
                          <h6>Albedo:</h6>
                        </div>
                        <NumericInput
                          precision={1}
                          value={albedo}
                          onChange={(val) => setAlbedo(val)}
                          className="form-control"
                          step={0.1}
                          min={0}
                          max={1}
                        />
                      </Col>
                    </Row>
                    <Row className="mb-4">
                      <Col lg={12} md={12}>
                        <div className="d-flex justify-content-between mb-2">
                          <h6>Input Data Format:</h6>
                        </div>
                        <SingleSelect
                          data={CSVDataFormat}
                          value={inputDataFormat}
                          placeholder={"Select one of them..."}
                          isDisabled={false}
                          isLoading={false}
                          handleChange={handleInputDataFormatChange}
                        />
                      </Col>
                    </Row>
                  </React.Fragment>
                )}

                {structureType?.value !== "tracker" && (
                  <Row className="mb-4">
                    <Col lg={6} md={12}>
                      <div className="d-flex justify-content-between mb-2">
                        <h6>Albedo:</h6>
                      </div>
                      <NumericInput
                        precision={1}
                        value={albedo}
                        onChange={(val) => setAlbedo(val)}
                        className="form-control"
                        step={0.1}
                        min={0}
                        max={1}
                      />
                    </Col>
                    <Col lg={6} md={12}>
                      <div className="d-flex justify-content-between mb-2">
                        <h6>Input Data Format:</h6>
                      </div>
                      <SingleSelect
                        data={CSVDataFormat}
                        value={inputDataFormat}
                        placeholder={"Select one of them..."}
                        isDisabled={false}
                        isLoading={false}
                        handleChange={handleInputDataFormatChange}
                      />
                    </Col>
                  </Row>
                )}

                {structureType?.value === "seasonal_tilt" && (
                  <Row className="mb-4">
                    <Col lg={12} md={12}>
                      <Button
                        color="secondary"
                        className="px-4 w-100"
                        variant="contained"
                        component="span"
                        onClick={() => setShowSeasonalTiltModal(true)}
                      >
                        Add Seasonal Data
                      </Button>
                    </Col>
                  </Row>
                )}

                <Row className="mb-3">
                  <Col sm={12} md={12} lg={6}>
                    <label htmlFor="uploadCSV" className="w-100">
                      <input
                        style={{ display: "none" }}
                        id="uploadCSV"
                        name="uploadCSV"
                        type="file"
                        accept=".csv"
                        onChange={(e) => setFile(e.target.files[0])}
                        onClick={(event) => {
                          const { target = {} } = event || {};
                          target.value = "";
                        }}
                      />
                      <Button
                        color="secondary"
                        className="px-4 w-100"
                        variant="contained"
                        component="span"
                      >
                        Choose CSV File
                      </Button>
                    </label>
                  </Col>
                  <Col sm={12} md={12} lg={6}>
                    <input
                      className="form-control mb-2"
                      type="text"
                      value={file?.name ? file?.name : ""}
                      disabled={true}
                    />
                  </Col>
                </Row>

                <div className="mb-3">
                  <Button
                    color="primary"
                    className="px-4 w-100"
                    variant="contained"
                    component="span"
                    onClick={submitService}
                  >
                    Calculate
                  </Button>
                </div>
              </div>
            </Col>

            <Col lg={6} md={12}>
              <div className="custom_card p-4">
                <div>
                  <div>
                    <p className="text-justify my-3">
                      <b>What is {service[0]?.name} service?</b>
                      <br />
                      {service[0]?.description}
                    </p>

                    <p className="my-3 text-justify">
                      <b>How to use {service[0]?.name} service?</b>
                      <br />
                      {service[0]?.how_to_use}
                    </p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>

          <Row className="mb-4">
            <Col>
              {/* Table Content */}
              {process.length !== 0 && (
                <div className="custom_card px-3 mt-3 py-3">
                  <Row className="my-3">
                    <Col sm={12} md={6}>
                      <div className="mb-3">
                        <InputGroup className="search-bar">
                          <FormControl
                            placeholder="Search by zip name..."
                            aria-label="Search by name"
                            aria-describedby="basic-addon2"
                            value={searchBarValue}
                            onChange={(e) => {
                              setSearchBarValue(e.target.value);
                            }}
                          />
                          <Button
                            style={{ borderRadius: 0 }}
                            variant="contained"
                            color="primary"
                            id="button-addon2"
                            onClick={handleSearch}
                          >
                            Search
                          </Button>
                        </InputGroup>
                      </div>
                    </Col>
                  </Row>

                  <TableContainer>
                    <Table aria-label="CustomTable">
                      <TableHead>
                        <TableRow>
                          <TableCell align="left">CSV Name</TableCell>
                          <TableCell align="left">Info</TableCell>
                          <TableCell align="center">CSV Type</TableCell>
                          <TableCell align="center">Structure Type</TableCell>
                          <TableCell align="center">
                            Transposition Model
                          </TableCell>
                          <TableCell align="center">TF Gain</TableCell>
                          <TableCell align="center">Status</TableCell>
                          <TableCell align="center">Download CSV</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {filteredProcessArr
                          .slice(pageVisited, pageVisited + dataPerPage)
                          .map((el) => {
                            return (
                              <TableRow key={el?.id}>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  className="wrapText"
                                  align="left"
                                >
                                  <a
                                    href={el?.input_csv_url}
                                    download
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    {sliceName(el?.csv_name)}{" "}
                                  </a>
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  className="wrapText"
                                  align="left"
                                >
                                  <img
                                    className="hovImg"
                                    src={notepadIco}
                                    alt="notepadIco"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      setInfoData(el);
                                      setOpenInfoModal(true);
                                    }}
                                  />
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  className="wrapText"
                                  align="center"
                                  style={{ textTransform: "capitalize" }}
                                >
                                  {el?.input_data_format.split("_").join(" ")}
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  className="wrapText"
                                  align="center"
                                  style={{ textTransform: "capitalize" }}
                                >
                                  {el?.structure_type.split("_").join(" ")}
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  className="wrapText"
                                  align="center"
                                  style={{ textTransform: "capitalize" }}
                                >
                                  {el?.transposition_model.split("_").join(" ")}
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  className="wrapText"
                                  align="center"
                                >
                                  {el?.tf
                                    ? `${parseFloat((el?.tf - 1) * 100).toFixed(
                                        1
                                      )}%`
                                    : "..."}
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  className="wrapText"
                                  align="center"
                                >
                                  {el?.status}
                                </TableCell>
                                <TableCell align="center">
                                  <a
                                    href={el?.processed_csv_url}
                                    download
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    <img
                                      className="hovImg"
                                      src={downloadIco}
                                      alt="download"
                                    />
                                  </a>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                    {!filteredProcessArr.length && (
                      <Col md={12} className="my-3">
                        <h3 className="text-center">
                          No Process Found with name {searchBarValue}!
                        </h3>
                        <div className="text-center mt-2">
                          <Button
                            variant="outlined"
                            color="primary"
                            onClick={setFilteredProcessToOriginal}
                          >
                            Get All Processes
                          </Button>
                        </div>
                      </Col>
                    )}

                    {filteredProcessArr.length > 0 && (
                      <ReactPaginate
                        previousLabel={"Previous"}
                        nextLabel={"Next"}
                        pageCount={pageCount}
                        onPageChange={onPageChange}
                        containerClassName={"pagnateContainer"}
                        previousLinkClassName={"navigateBtn"}
                        nextLinkClassName={"navigateBtn"}
                        disabledClassName={"disabledBtn"}
                        activeClassName={"activeBtn"}
                      />
                    )}
                  </TableContainer>
                </div>
              )}
            </Col>
          </Row>
        </Container>
      )}

      <InfoModal
        openInfoModal_={openInfoModal}
        setOpenInfoModal_={setOpenInfoModal}
        infoData_={infoData}
        size={"lg"}
      />
      <SeasonalTiltModal
        show={showSeasonalTiltModal}
        onHide={() => setShowSeasonalTiltModal(false)}
        data={seasonalData}
        seasonalDataChange={setSeasonalData}
      />

      <ToastContainer />
      <Backdrop
        className={classes.backdrop}
        style={{ zIndex: "10" }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Footer />
    </div>
  );
};

export default GHI_POA_CalcService;
