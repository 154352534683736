import React, { useEffect } from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  IconButton,
  AppBar,
  Drawer,
  CssBaseline,
  Toolbar,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Typography,
  Button,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { Link } from "react-router-dom";
import { delete_cookie, fetchAuthToken } from "../utils/Helpers";
import userImg from "../assets/images/user.png";
import logoutIco from "../assets/images/logout.png";
import PVDLogo from "../assets/images/PVD-Logo.png";
import loginICO from "../assets/images/login_.png";
import { BrowserView, MobileView, TabletView } from "react-device-detect";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(0),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

const Header = (props) => {
  // const navClasses = navStyles();
  const classes = useStyles();
  const theme = useTheme();

  const [open, setOpen] = React.useState(false);
  const [isLoggedIn, setIsLoggedIn] = React.useState(false);
  const [username, setUsername] = React.useState("");

  useEffect(() => {
    let token = fetchAuthToken();
    if (token) {
      setIsLoggedIn(true);
      setUsername(localStorage.getItem("username"));
    } else {
      setIsLoggedIn(false);
    }
  }, []);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleLogout = () => {
    props.history.push("/");
    localStorage.clear();
    delete_cookie("csrftoken");
    window.location.replace("/");
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          {isLoggedIn && (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              className={clsx(classes.menuButton, open && classes.hide)}
            >
              <MenuIcon />
            </IconButton>
          )}

          <Typography variant="h5" noWrap>
            {!isLoggedIn && (
              <Link className="home-link wrapText" to={"/"}>
                <img src={PVDLogo} alt="logo" style={{ width: 40 }} />
                <BrowserView className="d-inline">
                  Internal Analysis Toolpack
                </BrowserView>
                <MobileView className="d-inline px-2">IAT</MobileView>
              </Link>
            )}
            {isLoggedIn && (
              <Link className="home-link wrapText" to="/services">
                <img src={PVDLogo} alt="logo" style={{ width: 40 }} />
                <BrowserView className="d-inline">
                  Internal Analysis Toolpack
                </BrowserView>
                <MobileView className="d-inline px-2">IAT</MobileView>
              </Link>
            )}
          </Typography>

          {isLoggedIn && (
            <Button className="ml-auto d-flex" color="inherit">
              <span>
                {/* <img src={userImg} className="pr-2 pb-2" alt="user" /> */}
                <div className="userFirstLetterIcon mb-2 mr-1">
                  {username.split("")[0].toUpperCase()}
                </div>
              </span>
              <span>
                <p
                  className="font-weight-bold wrapText mt-2"
                  style={{ textTransform: "capitalize" }}
                >
                  {" "}
                  {username}{" "}
                </p>
              </span>{" "}
            </Button>
          )}
          {!isLoggedIn && (
            <Link className="ml-auto mb-0 pb-0" to={"/login"}>
              <h6 className="mb-0 pb-0" style={{ color: "white" }}>
                {/* <img src={loginICO} className="mr-1" alt="logo"/> */}
                Login
              </h6>
            </Link>
          )}
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </div>
        <Divider />

        <List>
          <ListItem button onClick={handleLogout}>
            <ListItemIcon>
              <img src={logoutIco} alt="logout" />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItem>
        </List>
        <Divider />
      </Drawer>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <div className={classes.drawerHeader} />
      </main>
    </div>
  );
};

export default Header;
