import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import {
  Backdrop,
  Button,
  CircularProgress,
  Container,
  makeStyles,
} from "@material-ui/core";
import Footer from "../components/Footer";
import { InputGroup, Row, Col, FormControl } from "react-bootstrap";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import downloadIco from "../assets/images/cloud-computing.png";
import backLogo from "../assets/images/back.png";
import MyLoader from "../components/ContentLoader";

import axios from "axios";
import {
  GET_ALL_IVSTC_2_PROCESS,
  GET_MODULE_DETAILS,
  GET_PVD_PROJECTS,
  GET_SERVICE_BY_ID,
  START_IVSTC_2_SERVICE,
  GET_PREVIOUS_DETAILS_IVSTC2,
} from "../shared/ApiURLs";
import {
  addHeaderInAxios,
  checkSignature,
  IV_Device_Make,
  IV_Translations,
  moduleLibraryFields,
  sliceName,
  STC_Types,
} from "../utils/Helpers";
import { toast, ToastContainer } from "react-toastify";
import ReactPaginate from "react-paginate";
import { SingleSelect } from "../components/SingleSelect";
import { timeZones } from "../utils/timeZones";
import notepadIco from "../assets/images/notepad.png";
import InfoModal from "../components/InfoModal";
import ErrorLogModal from "../components/ErrorLogModal";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const scts = [
  { label: "Normal", value: "normal" },
  { label: "Tracker", value: "tracker" },
];

const temperatures = [
  { label: "T1", value: "T1" },
  { label: "T2", value: "T2" },
  { label: "Auto", value: "Auto" },
];

const IVSTC2Service = (props) => {
  const classes = useStyles();

  const [service, setService] = useState([]);
  const [selectedZipFile, setSelectedZipFile] = useState(null);
  const [selectedLoggersFile, setSelectedLoggersFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [process, setProcess] = useState([]);
  const [isContentLoading, setContentLoading] = useState(true);
  const [searchBarValue, setSearchBarValue] = useState("");
  const [filteredProcessArr, setFilteredProcessArr] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [deviceMake, setDeviceMake] = useState(IV_Device_Make[0]);

  const [lat, setLat] = useState(0);
  const [lon, setLot] = useState(0);
  const [timezone, setTimezone] = useState({
    value: "+05.50",
    label: "Asia/Kolkata",
  });
  const [refLon, setRefLon] = useState(0);
  const [structureType, setStructureType] = useState(scts[0]);
  const [tilt, setTilt] = useState(0);
  const [azimuth, setAzimuth] = useState(0);

  const [moduleDetails, setModuleDetails] = useState(moduleLibraryFields);
  const [dropdownData, setDropdownData] = useState({});
  const [userQuota, setUserQuota] = useState(null);

  const [openInfoModal, setOpenInfoModal] = useState(false);
  const [infoData, setInfoData] = useState(null);
  const [stcType, setStcType] = useState(STC_Types[1]);

  const [ivTrans, setIVTrans] = useState(IV_Translations[0]);
  const [series, setSeries] = useState(0);
  const [parallel, setParallel] = useState(0);

  const [errorLog, setErrorLog] = useState([]);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [temp, setTemp] = useState(temperatures[2]);
  const [allProjects, setAllProjects] = useState([]);
  const [selectedProj, setSelectedProj] = useState(null);

  const dataPerPage = 4;
  const pageVisited = pageNumber * dataPerPage;
  const pageCount = Math.ceil(filteredProcessArr.length / dataPerPage);

  let AfterUpdtion = {
    make: null,
  };

  useEffect(() => {
    addHeaderInAxios(props)
      ? getServiceById(props.match.params.service_id)
      : props.history.replace("/");
  }, []);

  useEffect(() => {
    setRefLon((parseFloat(timezone.value) * 15).toFixed(2));
  }, [timezone]);

  const getServiceById = (service_id) => {
    axios
      .get(GET_SERVICE_BY_ID(service_id))
      .then((res) => {
        setService(res.data.service);
        getAllProcess(res.data.service[0].id);
        axios
          .get(GET_MODULE_DETAILS)
          .then((res) => {
            setDropdownData(res.data.dropdown_data);
            setUserQuota(res.data.UserQuota);
            setContentLoading(false);
            getAllProjects();
          })
          .catch((e) => {
            checkSignature(e);
            toast.error("Error while fetching module details!");
            setContentLoading(false);
          });
      })
      .catch((error) => {
        checkSignature(error);
        setContentLoading(false);
        props.history.replace("/services");
      });
  };

  const fileChangeHandler = (event, type) => {
    if (type === "zip_upload") {
      setSelectedZipFile(event.target.files[0]);
    }
  };

  const fileChangeHandlerForLoggers = (event, type) => {
    if (type === "txtLoggers") {
      setSelectedLoggersFile(event.target.files[0]);
    }
  };

  const getAllProjects = () => {
    axios
      .get(GET_PVD_PROJECTS)
      .then((res) => {
        setAllProjects(res.data.projects);
      })
      .catch((error) => {});
  };

  const getAllProcess = (service_id) => {
    axios
      .get(GET_ALL_IVSTC_2_PROCESS("all"))
      .then((res) => {
        setProcess(res.data.process);
        setFilteredProcessArr(res.data.process);
      })
      .catch((error) => {
        checkSignature(error);
      });
  };

  const SubmitService = () => {
    let validatePayload = validateData();
    if (typeof validatePayload === "object") {
      setLoading(true);
      axios
        .post(START_IVSTC_2_SERVICE, validatePayload)
        .then((res) => {
          setSelectedZipFile(null);
          setSelectedLoggersFile(null);
          getAllProcess(service[0].id);

          setUserQuota(res.data.UserQuota);
          setLoading(false);
          toast.success(res.data.message);
        })
        .catch((e) => {
          checkSignature(e);
          setLoading(false);
          toast.error(e?.response?.data?.message);
          if (e.response.status === 400 && e?.response?.data?.csv_errors) {
            setErrorLog(JSON.parse(e?.response?.data?.csv_errors));
            setOpenErrorModal(true);
          }
        });
    }
  };
  // todo
  const Fecth_Data_Select_From_Project = (value) => {
    let project = { project: value.value };

    axios
      .get(GET_PREVIOUS_DETAILS_IVSTC2, project)
      .then((res) => {
        if (res.data.status) {
          let responce = res.data.result;
          let IVSTC_2_Process = responce.IVSTC_2_Process;
          let module_ = responce.module_details;
          let location_details = responce.location_details;
          // console.log(responce);

          setLat(location_details.Lat);
          setLot(location_details.lon);
          setTilt(location_details.tilt);
          setAzimuth(location_details.azimuth);

          dropdownData?.makes.map((e) => {
            if (e.label === module_.make) {
              handleModuleDetailsChanges("Make", e);
              e.models.map((mod) => {
                if (mod.label === module_.model) {
                  handleModuleDetailsChanges("Model", mod);
                }
              });
            }
          });
        } else {
          toast.error("There is no data related selected project");
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const backBtnHandler = () => {
    return props.history.push("/services");
  };

  const handleSearch = () => {
    let newProcessArr = process.filter((el) => {
      return String(el?.original_zip_name)
        .toLowerCase()
        .includes(searchBarValue.toLowerCase());
    });

    setFilteredProcessArr(newProcessArr);
    return newProcessArr;
  };

  const setFilteredProcessToOriginal = () => setFilteredProcessArr(process);

  const onPageChange = ({ selected }) => {
    setPageNumber(selected);
  };

  const handleDeviceMakeChange = (selectedOption, triggeredAction) => {
    setDeviceMake(selectedOption);
  };

  const handleModuleDetailsChanges = (key, value) => {
    let tempModuleDetails = null;
    if (key === "Model") {
      tempModuleDetails = value.details;
      tempModuleDetails.Model = value;
      tempModuleDetails.Make = moduleDetails.Make;
      setModuleDetails({ ...tempModuleDetails });
      return true;
    } else if (key === "Make") {
      tempModuleDetails = moduleLibraryFields;
    } else {
      tempModuleDetails = moduleDetails;
    }
    tempModuleDetails[key] = value;
    setModuleDetails({ ...tempModuleDetails });
    return true;
  };

  const validateData = () => {
    if (lat === 0 || lon === 0) {
      return toast.error("Invalid Latitude or Longitude");
    } else if (structureType.value === "normal" && !(tilt >= 0 && tilt <= 90)) {
      return toast.error("Tilt value should be between 0 to 90");
    } else if (
      structureType.value === "normal" &&
      !(azimuth >= -180 && azimuth <= 180)
    ) {
      return toast.error("Azimuth value should be between -180 to 180");
    } else if (!moduleDetails.Make || !moduleDetails.Model) {
      return toast.error("Please select an Make and Model!");
    } else if (!selectedZipFile) {
      return toast.error(`Choose a zip file for processing!`);
    } else if (selectedZipFile.size < 100) {
      return toast.error(`Choosen a zip file is empty!`);
    } else if (!selectedProj) {
      return toast.error(`Select a project`);
    }
    ["Voc", "Vm", "Isc", "Im", "Pmax"].forEach((e) => {
      if (!(moduleDetails[e] > 0)) {
        return toast.error(`${e} should be positive number!`);
      }
    });
    ["FF", "alpha_t", "kappa_t"].forEach((e) => {
      if (!(moduleDetails[e] >= 0 && moduleDetails[e] <= 1)) {
        return toast.error(`${e} value should be between 0 to 1`);
      }
    });
    ["beta_t", "gamma_t"].forEach((e) => {
      if (!(moduleDetails[e] >= -1 && moduleDetails[e] <= 0)) {
        return toast.error(`${e} value should be between -1 to 0`);
      }
    });

    let location_details = {
      lat: parseFloat(lat),
      lon: parseFloat(lon),
      ref_lon: parseFloat(refLon),
      structure_type: structureType.value,
      tilt: structureType.value === "normal" ? parseFloat(tilt) : 0,
      azimuth: structureType.value === "normal" ? parseFloat(azimuth) : 0,
      IAM_cf: structureType.value === "normal" ? true : false,
    };
    let module_details = {
      Isc: parseFloat(moduleDetails.Isc),
      make: moduleDetails.Make.value,
      model: moduleDetails.Model.value,
      Pmax: parseFloat(moduleDetails.Pmax),
      technology: moduleDetails.Technology,
      Vm: parseFloat(moduleDetails.Vm),
      Voc: parseFloat(moduleDetails.Voc),
      Im: parseFloat(moduleDetails.Im),
      FF: parseFloat(moduleDetails.FF),
      YOM: parseInt(moduleDetails.YOM),
      alpha_t: parseFloat(moduleDetails.alpha_t),
      beta_t: parseFloat(moduleDetails.beta_t),
      gamma_t: parseFloat(moduleDetails.gamma_t),
      kappa_t: parseFloat(moduleDetails.kappa_t),
    };
    const formdata = new FormData();
    formdata.append("location_details", JSON.stringify(location_details));
    formdata.append("module_details", JSON.stringify(module_details));
    formdata.append("service_id", service[0].id);
    formdata.append("device_make", deviceMake.value);
    formdata.append("stc_type", stcType.value);
    formdata.append("zip_file", selectedZipFile);
    selectedLoggersFile &&
      deviceMake.label !== "SOLMETRIC" &&
      formdata.append("logger_file", selectedLoggersFile);
    formdata.append("iv_translation", ivTrans.value);
    formdata.append("series", ivTrans.value === "String IV" ? series : 1);
    formdata.append("parallel", ivTrans.value === "String IV" ? parallel : 1);
    formdata.append("temperature", temp?.value);
    formdata.append("project", selectedProj?.value);

    return formdata;
  };
  // console.log("project number", deviceMake.label);

  return (
    <div className="main-wrapper">
      <Header {...props} />
      {isContentLoading && <MyLoader />}
      {!isContentLoading && (
        <Container className="mb-5">
          <Row>
            <Col sm={12} md={12} className="my-2 h-auto">
              <div className="custom_card px-4 py-3 h-100">
                <div>
                  <div className="d-flex justify-content-between mb-2">
                    <div>
                      <span>
                        <img
                          src={backLogo}
                          alt="back"
                          className="backBtn"
                          onClick={backBtnHandler}
                        />
                      </span>
                      <span className="mx-3 sscalcHeading d-inline pb-2">
                        {service[0]?.name}
                      </span>
                    </div>
                    <h6>Quota:{userQuota} </h6>
                  </div>

                  <div className="py-1 px-2 my-2">
                    <div className="my-3">
                      <div className="mb-3">
                        <div className="">
                          <div>
                            <div className="d-flex justify-content-between mb-2">
                              <h6>Location Details: </h6>
                            </div>
                            <table className="table table-striped table-bordered mb-4 ">
                              <thead className="thead-dark">
                                <tr>
                                  <th
                                    scope="col"
                                    style={{ width: "10%" }}
                                    className="text-center p-2"
                                  >
                                    Latitude (°)
                                  </th>
                                  <th
                                    scope="col"
                                    style={{ width: "10%" }}
                                    className="text-center p-2"
                                  >
                                    Longitude (°)
                                  </th>
                                  <th
                                    scope="col"
                                    style={{ width: "25%" }}
                                    className="text-center p-2"
                                  >
                                    Timezone
                                  </th>
                                  <th
                                    scope="col"
                                    style={{ width: "15%" }}
                                    className="text-center p-2"
                                  >
                                    Ref Longitude (°)
                                  </th>
                                  <th
                                    scope="col"
                                    style={{ width: "15%" }}
                                    className="text-center p-2"
                                  >
                                    Structure
                                  </th>
                                  {structureType?.value === "normal" ? (
                                    <React.Fragment>
                                      <th
                                        scope="col"
                                        className="text-center p-2"
                                      >
                                        Tilt
                                      </th>
                                      <th
                                        scope="col"
                                        className="text-center p-2"
                                      >
                                        Azimuth
                                      </th>
                                    </React.Fragment>
                                  ) : (
                                    <th scope="col" className="text-center p-2">
                                      IAM_cf
                                    </th>
                                  )}
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td className="p-1">
                                    <input
                                      type="number"
                                      className="form-control text-center"
                                      value={lat}
                                      onChange={(e) => setLat(e.target.value)}
                                    />
                                  </td>
                                  <td className="p-1">
                                    <input
                                      type="number"
                                      className="form-control text-center"
                                      value={lon}
                                      onChange={(e) => setLot(e.target.value)}
                                    />
                                  </td>
                                  <td className="p-1">
                                    <SingleSelect
                                      data={timeZones}
                                      value={timezone}
                                      placeholder={"Select one of them..."}
                                      isDisabled={false}
                                      isLoading={false}
                                      handleChange={setTimezone}
                                    />
                                  </td>
                                  <td className="p-1">
                                    <input
                                      type="text"
                                      className="form-control text-center"
                                      value={refLon}
                                      disabled={true}
                                    />
                                  </td>
                                  <td className="p-1">
                                    <SingleSelect
                                      data={scts}
                                      value={structureType}
                                      placeholder={"Select Structure..."}
                                      isDisabled={false}
                                      isLoading={false}
                                      handleChange={setStructureType}
                                    />
                                  </td>
                                  {structureType?.value === "normal" ? (
                                    <React.Fragment>
                                      <td className="p-1">
                                        <input
                                          type="number"
                                          className="form-control text-center"
                                          value={tilt}
                                          onChange={(e) =>
                                            setTilt(e.target.value)
                                          }
                                        />
                                      </td>
                                      <td className="p-1">
                                        <input
                                          type="number"
                                          className="form-control text-center"
                                          value={azimuth}
                                          onChange={(e) =>
                                            setAzimuth(e.target.value)
                                          }
                                        />
                                      </td>
                                    </React.Fragment>
                                  ) : (
                                    <td className="p-1">
                                      <input
                                        type="text"
                                        className="form-control text-center"
                                        value="False"
                                        disabled={true}
                                      />
                                    </td>
                                  )}
                                </tr>
                              </tbody>
                            </table>
                          </div>

                          <div>
                            <div className="d-flex justify-content-between mb-2">
                              <h6>Module Details: </h6>
                            </div>
                            <table className="table table-striped table-bordered mb-4 ">
                              <thead className="thead-dark">
                                <tr>
                                  <th
                                    scope="col"
                                    style={{ width: "20%" }}
                                    className="text-center p-2"
                                  >
                                    Make
                                  </th>
                                  <th
                                    scope="col"
                                    style={{ width: "20%" }}
                                    className="text-center p-2"
                                  >
                                    Model
                                  </th>
                                  <th
                                    style={{ width: "20%" }}
                                    scope="col"
                                    className="text-center p-2"
                                  >
                                    Year of Make (YYYY)
                                  </th>
                                  <th
                                    style={{ width: "20%" }}
                                    scope="col"
                                    className="text-center p-2"
                                  >
                                    Technology
                                  </th>
                                  <th
                                    scope="col"
                                    style={{ width: "20%" }}
                                    className="text-center p-2"
                                  >
                                    Temperature
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td className="p-1">
                                    <SingleSelect
                                      data={dropdownData?.makes}
                                      value={moduleDetails?.Make}
                                      placeholder={"Select Make..."}
                                      isDisabled={false}
                                      isLoading={false}
                                      handleChange={(e) => {
                                        handleModuleDetailsChanges("Make", e);
                                        // console.log(e);
                                      }}
                                    />
                                  </td>
                                  <td className="p-1">
                                    <SingleSelect
                                      data={moduleDetails?.Make?.models}
                                      value={moduleDetails?.Model}
                                      placeholder={"Select Model..."}
                                      isDisabled={false}
                                      isLoading={false}
                                      handleChange={(e) => {
                                        handleModuleDetailsChanges("Model", e);
                                        // console.log(e);
                                        // todo
                                      }}
                                    />
                                  </td>
                                  <td className="p-1">
                                    <input
                                      type="text"
                                      className="form-control text-center"
                                      value={moduleDetails?.YOM}
                                      onChange={(e) =>
                                        handleModuleDetailsChanges(
                                          "YOM",
                                          e.target.value
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="p-1">
                                    <input
                                      type="text"
                                      className="form-control text-center"
                                      value={moduleDetails?.Technology}
                                      disabled={true}
                                    />
                                  </td>
                                  <td className="p-1">
                                    <SingleSelect
                                      data={temperatures}
                                      value={temp}
                                      placeholder={"Select Temp..."}
                                      isDisabled={false}
                                      isLoading={false}
                                      handleChange={setTemp}
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            {moduleDetails?.Model?.value && (
                              <table className="table table-striped table-bordered mb-4 ">
                                <thead className="thead-dark">
                                  <tr>
                                    <th
                                      scope="col"
                                      style={{ width: "10%" }}
                                      className="text-center p-2"
                                    >
                                      Voc
                                    </th>
                                    <th
                                      scope="col"
                                      style={{ width: "10%" }}
                                      className="text-center p-2"
                                    >
                                      Vm
                                    </th>
                                    <th
                                      style={{ width: "10%" }}
                                      scope="col"
                                      className="text-center p-2"
                                    >
                                      Isc
                                    </th>
                                    <th
                                      style={{ width: "10%" }}
                                      scope="col"
                                      className="text-center p-2"
                                    >
                                      Im
                                    </th>
                                    <th
                                      style={{ width: "10%" }}
                                      scope="col"
                                      className="text-center p-2"
                                    >
                                      Pmax
                                    </th>
                                    <th
                                      style={{ width: "10%" }}
                                      scope="col"
                                      className="text-center p-2"
                                    >
                                      FF
                                    </th>
                                    <th
                                      style={{ width: "10%" }}
                                      scope="col"
                                      className="text-center p-2"
                                    >
                                      alpha_t
                                    </th>
                                    <th
                                      style={{ width: "10%" }}
                                      scope="col"
                                      className="text-center p-2"
                                    >
                                      beta_t
                                    </th>
                                    <th
                                      style={{ width: "10%" }}
                                      scope="col"
                                      className="text-center p-2"
                                    >
                                      gamma_t
                                    </th>
                                    <th
                                      style={{ width: "10%" }}
                                      scope="col"
                                      className="text-center p-2"
                                    >
                                      kappa_t
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td className="p-1">
                                      <input
                                        type="number"
                                        className="form-control text-center"
                                        value={moduleDetails?.Voc}
                                        onChange={(e) =>
                                          handleModuleDetailsChanges(
                                            "Voc",
                                            e.target.value
                                          )
                                        }
                                      />
                                    </td>
                                    <td className="p-1">
                                      <input
                                        type="number"
                                        className="form-control text-center"
                                        value={moduleDetails?.Vm}
                                        onChange={(e) =>
                                          handleModuleDetailsChanges(
                                            "Vm",
                                            e.target.value
                                          )
                                        }
                                      />
                                    </td>
                                    <td className="p-1">
                                      <input
                                        type="number"
                                        className="form-control text-center"
                                        value={moduleDetails?.Isc}
                                        onChange={(e) =>
                                          handleModuleDetailsChanges(
                                            "Isc",
                                            e.target.value
                                          )
                                        }
                                      />
                                    </td>
                                    <td className="p-1">
                                      <input
                                        type="number"
                                        className="form-control text-center"
                                        value={moduleDetails?.Im}
                                        onChange={(e) =>
                                          handleModuleDetailsChanges(
                                            "Im",
                                            e.target.value
                                          )
                                        }
                                      />
                                    </td>
                                    <td className="p-1">
                                      <input
                                        type="number"
                                        className="form-control text-center"
                                        value={moduleDetails?.Pmax}
                                        onChange={(e) =>
                                          handleModuleDetailsChanges(
                                            "Pmax",
                                            e.target.value
                                          )
                                        }
                                      />
                                    </td>
                                    <td className="p-1">
                                      <input
                                        type="number"
                                        className="form-control text-center"
                                        value={moduleDetails?.FF}
                                        onChange={(e) =>
                                          handleModuleDetailsChanges(
                                            "FF",
                                            e.target.value
                                          )
                                        }
                                      />
                                    </td>
                                    <td className="p-1">
                                      <input
                                        type="number"
                                        className="form-control text-center"
                                        value={moduleDetails?.alpha_t}
                                        onChange={(e) =>
                                          handleModuleDetailsChanges(
                                            "alpha_t",
                                            e.target.value
                                          )
                                        }
                                      />
                                    </td>
                                    <td className="p-1">
                                      <input
                                        type="number"
                                        className="form-control text-center"
                                        value={moduleDetails?.beta_t}
                                        onChange={(e) =>
                                          handleModuleDetailsChanges(
                                            "beta_t",
                                            e.target.value
                                          )
                                        }
                                      />
                                    </td>
                                    <td className="p-1">
                                      <input
                                        type="number"
                                        className="form-control text-center"
                                        value={moduleDetails?.gamma_t}
                                        onChange={(e) =>
                                          handleModuleDetailsChanges(
                                            "gamma_t",
                                            e.target.value
                                          )
                                        }
                                      />
                                    </td>
                                    <td className="p-1">
                                      <input
                                        type="number"
                                        className="form-control text-center"
                                        value={moduleDetails?.kappa_t}
                                        onChange={(e) =>
                                          handleModuleDetailsChanges(
                                            "kappa_t",
                                            e.target.value
                                          )
                                        }
                                      />
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            )}
                          </div>

                          <Row>
                            <Col sm={12} md={2} lg={2}>
                              <div className="d-flex justify-content-between mb-1">
                                <h6>Select IV Device Make: </h6>
                              </div>
                              <SingleSelect
                                data={IV_Device_Make}
                                value={deviceMake}
                                placeholder={"Select one of them..."}
                                isDisabled={false}
                                isLoading={false}
                                handleChange={handleDeviceMakeChange}
                              />
                            </Col>

                            <Col sm={12} md={3} lg={3} className="mb-4">
                              <div className="d-flex justify-content-between mb-1">
                                <h6>Translation method: </h6>
                              </div>

                              <SingleSelect
                                data={STC_Types}
                                value={stcType}
                                placeholder={"Select one of them..."}
                                isDisabled={false}
                                isLoading={false}
                                handleChange={setStcType}
                              />
                            </Col>

                            <Col sm={12} md={2} lg={2} className="mb-4">
                              <div className="d-flex justify-content-between mb-1">
                                <h6>IV Translation: </h6>
                              </div>

                              <SingleSelect
                                data={IV_Translations}
                                value={ivTrans}
                                placeholder={"Select one of them..."}
                                isDisabled={false}
                                isLoading={false}
                                handleChange={setIVTrans}
                              />
                            </Col>

                            <Col sm={12} md={5} lg={5}>
                              <div className="d-flex justify-content-between mb-2">
                                <h6>Select Project:</h6>
                              </div>
                              <SingleSelect
                                data={allProjects}
                                value={selectedProj}
                                placeholder={"Select one of them..."}
                                isDisabled={false}
                                isLoading={false}
                                handleChange={
                                  (e) => {
                                    setSelectedProj(e);
                                    Fecth_Data_Select_From_Project(e);
                                  }
                                  // setSelectedProj
                                }
                              />
                            </Col>

                            {ivTrans.value === "String IV" && (
                              <Col sm={12} md={12} lg={5}>
                                <Row>
                                  <Col sm={12} md={12} lg={6}>
                                    <div className="d-flex justify-content-between mb-1">
                                      <h6>Series: </h6>
                                    </div>
                                    <input
                                      className="form-control"
                                      type="number"
                                      value={series}
                                      onChange={(e) =>
                                        setSeries(e.target.value)
                                      }
                                    />
                                  </Col>
                                  <Col sm={12} md={12} lg={6}>
                                    <div className="d-flex justify-content-between mb-1">
                                      <h6>Parallel: </h6>
                                    </div>
                                    <input
                                      className="form-control"
                                      type="number"
                                      value={parallel}
                                      onChange={(e) =>
                                        setParallel(e.target.value)
                                      }
                                    />
                                  </Col>
                                </Row>
                              </Col>
                            )}
                            <Col sm={12} md={12} lg={5}>
                              <div
                                className="d-flex w-100"
                                style={{ marginTop: "31px" }}
                              >
                                <span>
                                  <label htmlFor="upload_zip" className="w-100">
                                    <input
                                      style={{ display: "none" }}
                                      id="upload_zip"
                                      name="upload_zip"
                                      type="file"
                                      accept=".zip"
                                      onChange={(e) =>
                                        fileChangeHandler(e, "zip_upload")
                                      }
                                      onClick={(event) => {
                                        const { target = {} } = event || {};
                                        target.value = "";
                                      }}
                                    />
                                    <Button
                                      color="secondary"
                                      className="px-1"
                                      variant="contained"
                                      component="span"
                                    >
                                      Choose Zip
                                    </Button>
                                  </label>
                                </span>
                                <input
                                  className="form-control w-75"
                                  type="text"
                                  value={
                                    selectedZipFile?.name
                                      ? selectedZipFile?.name
                                      : ""
                                  }
                                  readOnly
                                />
                              </div>
                            </Col>

                            {deviceMake.label !== "SOLMETRIC" && (
                              <Col sm={12} md={12} lg={5}>
                                <div
                                  className="d-flex w-100"
                                  style={{ marginTop: "31px" }}
                                >
                                  <span>
                                    <label
                                      htmlFor="Loggerfile"
                                      className="w-100"
                                    >
                                      <input
                                        style={{ display: "none" }}
                                        id="Loggerfile"
                                        name="Loggerfile"
                                        type="file"
                                        accept="text/*"
                                        onChange={(e) =>
                                          fileChangeHandlerForLoggers(
                                            e,
                                            "txtLoggers"
                                          )
                                        }
                                        onClick={(event) => {
                                          const { target = {} } = event || {};
                                          target.value = "";
                                        }}
                                      />
                                      <Button
                                        color="secondary"
                                        className="px-1"
                                        variant="contained"
                                        component="span"
                                      >
                                        Loggers File
                                      </Button>
                                    </label>
                                  </span>
                                  <input
                                    className="form-control w-75"
                                    type="text"
                                    value={
                                      selectedLoggersFile?.name
                                        ? selectedLoggersFile?.name
                                        : ""
                                    }
                                    readOnly
                                  />
                                </div>
                              </Col>
                            )}
                          </Row>
                        </div>
                      </div>
                    </div>
                    <Button
                      onClick={SubmitService}
                      variant="contained"
                      color="primary"
                      className="px-4 my-3"
                    >
                      Submit
                    </Button>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={6} className="my-2">
              <div className="custom_card px-4 py-3">
                <div>
                  <p className="my-3 text-justify">
                    <b>What is {service[0]?.name} service?</b>
                    <br />
                    {service[0]?.description}
                  </p>
                </div>
              </div>
            </Col>
            <Col sm={12} md={6} className="my-2">
              <div className="custom_card px-4 py-3">
                <div>
                  <p className="my-3 text-justify">
                    <b>How to use {service[0]?.name} service?</b>
                    <br />
                    {service[0]?.how_to_use}
                  </p>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              {/* Table Content */}
              {process.length !== 0 && (
                <div className="custom_card px-3 mt-3 py-3">
                  <Row className="my-3">
                    <Col sm={12} md={6}>
                      <div className="mb-3">
                        <InputGroup className="search-bar">
                          <FormControl
                            placeholder="Search by zip name..."
                            aria-label="Search by name"
                            aria-describedby="basic-addon2"
                            value={searchBarValue}
                            onChange={(e) => {
                              setSearchBarValue(e.target.value);
                            }}
                          />
                          <Button
                            style={{ borderRadius: 0 }}
                            variant="contained"
                            color="primary"
                            id="button-addon2"
                            onClick={handleSearch}
                          >
                            Search
                          </Button>
                        </InputGroup>
                      </div>
                    </Col>
                  </Row>

                  <TableContainer>
                    <Table aria-label="CustomTable">
                      <TableHead>
                        <TableRow>
                          <TableCell align="left">Zip Name</TableCell>
                          <TableCell align="center">Zip Size</TableCell>
                          <TableCell align="center">Device Make</TableCell>
                          <TableCell align="center">
                            Translation method
                          </TableCell>
                          <TableCell align="center">Module Details</TableCell>
                          <TableCell align="center">Location Details</TableCell>
                          <TableCell align="center">Status</TableCell>
                          <TableCell align="center">Download Zip</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {filteredProcessArr
                          .slice(pageVisited, pageVisited + dataPerPage)
                          .map((el) => {
                            return (
                              <TableRow key={el?.id}>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  className="wrapText"
                                  align="left"
                                >
                                  <a
                                    href={el?.uploaded_zip_url}
                                    download
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    {sliceName(el?.original_zip_name)}
                                  </a>
                                </TableCell>

                                {el?.zip_size !== null && (
                                  <TableCell align="center">
                                    {String(
                                      (
                                        parseInt(el?.zip_size) /
                                        1024 /
                                        1024
                                      ).toFixed(2)
                                    ) + " MB"}
                                  </TableCell>
                                )}

                                {el?.zip_size === null && (
                                  <TableCell align="center">NA</TableCell>
                                )}

                                <TableCell align="center">
                                  {el?.iv_device_make}
                                </TableCell>
                                <TableCell align="center">
                                  {el?.stc_type}
                                </TableCell>

                                <TableCell
                                  component="th"
                                  scope="row"
                                  className="wrapText"
                                  align="center"
                                >
                                  <img
                                    className="hovImg"
                                    src={notepadIco}
                                    alt="notepadIco"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      setInfoData(el?.module_details);
                                      setOpenInfoModal(true);
                                    }}
                                  />
                                </TableCell>

                                <TableCell
                                  component="th"
                                  scope="row"
                                  className="wrapText"
                                  align="center"
                                >
                                  <img
                                    className="hovImg"
                                    src={notepadIco}
                                    alt="notepadIco"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      setInfoData(el?.location_details);
                                      setOpenInfoModal(true);
                                    }}
                                  />
                                </TableCell>

                                <TableCell align="center">
                                  {el?.status}
                                </TableCell>
                                <TableCell align="center">
                                  <a
                                    href={el?.processed_zip_url}
                                    download
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    <img
                                      className="hovImg"
                                      src={downloadIco}
                                      alt="download"
                                    />
                                  </a>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                    {!filteredProcessArr.length && (
                      <Col md={12} className="my-3">
                        <h3 className="text-center">
                          No Process Found with name {searchBarValue}!
                        </h3>
                        <div className="text-center mt-2">
                          <Button
                            variant="outlined"
                            color="primary"
                            onClick={setFilteredProcessToOriginal}
                          >
                            Get All Processes
                          </Button>
                        </div>
                      </Col>
                    )}

                    {filteredProcessArr.length > 0 && (
                      <ReactPaginate
                        previousLabel={"Previous"}
                        nextLabel={"Next"}
                        pageCount={pageCount}
                        onPageChange={onPageChange}
                        containerClassName={"pagnateContainer"}
                        previousLinkClassName={"navigateBtn"}
                        nextLinkClassName={"navigateBtn"}
                        disabledClassName={"disabledBtn"}
                        activeClassName={"activeBtn"}
                      />
                    )}
                  </TableContainer>
                </div>
              )}
            </Col>
          </Row>
        </Container>
      )}

      <InfoModal
        openInfoModal_={openInfoModal}
        setOpenInfoModal_={setOpenInfoModal}
        infoData_={infoData}
        size={"md"}
      />
      <ErrorLogModal
        openErrorModal={openErrorModal}
        setOpenErrorModal={setOpenErrorModal}
        errorLog={errorLog}
        zipName={selectedZipFile?.name}
        size={"lg"}
      />
      <ToastContainer />
      <Backdrop
        className={classes.backdrop}
        style={{ zIndex: "10" }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Footer />
    </div>
  );
};

export default IVSTC2Service;
