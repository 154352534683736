import React from "react";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { Col, Row } from "react-bootstrap";
import lockIco from "../assets/images/lock.png";

export default function ServiceCard(props) {
  const { title, summery, totalProcess, processedProcess, available_for } =
    props;
  console.log(localStorage.getItem("is_admin"));
  return (
    <Card
      className={
        available_for === "Superusers" &&
        localStorage.getItem("is_admin") === "false"
          ? "lockedCard py-2 my-3"
          : "py-2 my-3"
      }
      style={{ height: 121 }}
    >
      <CardActionArea onClick={props.handleNavigation}>
        {available_for === "Superusers" &&
          localStorage.getItem("is_admin") === "false" && (
            <img src={lockIco} alt="lockIco" className="lockICO" />
          )}
        <CardContent className={summery ? "py-2" : ""}>
          <Typography gutterBottom variant="h5" component="h2">
            {title}
          </Typography>
          <Typography
            className="wrapText"
            variant="body2"
            color="textSecondary"
            component="p"
          >
            {summery}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        {totalProcess > 0 && (
          <Row className="w-100 p-2">
            <Col className="borderRight">
              <small className="d-flex justify-content-around">
                <span>
                  <b>Total Process:</b>
                </span>
                <span>
                  <b>{totalProcess}</b>
                </span>
              </small>
            </Col>
            <Col>
              <small className="d-flex justify-content-around">
                <span>
                  <b>Processed Process:</b>
                </span>
                <span>
                  <b>{processedProcess}</b>
                </span>
              </small>
            </Col>
          </Row>
        )}
        {totalProcess === 0 && <div className="py-2 my-2"></div>}
      </CardActions>
    </Card>
  );
}
