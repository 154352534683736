import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { LOGIN } from "../shared/ApiURLs";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Backdrop, TextField, Button } from "@material-ui/core";
import "react-toastify/dist/ReactToastify.css";
import logo from "../assets/images/logo.png";
import loginImage from "../assets/images/login.png";
import { fetchAuthToken } from "../utils/Helpers";
import { toast, ToastContainer } from "react-toastify";
import backLogo from "../assets/images/back.png";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const inputStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      width: "100%",
    },
  },
}));

const Login = (props) => {
  const classes = useStyles();
  const inputClasses = inputStyles();

  const [email, setEmail] = useState("");
  const [emailHelper, setEmailHelper] = useState("");
  const [password, setPassword] = useState("");
  const [passwordHelper, setPasswordHelper] = useState("");
  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [login, setLogin] = useState(true);

  useEffect(() => {
    let token = fetchAuthToken();
    if (token) {
      props.history.push("/services");
    }
  }, []);

  const hassError = (key) => {
    return errors.indexOf(key) !== -1;
  };

  const validate = () => {
    var temp_errors = [...errors];

    const emailRegex = /\S+@\S+/;
    var validEmail = emailRegex.test(String(email).toLowerCase());

    if (email === "" && temp_errors.length < 4) {
      temp_errors.push("email_blank");
      setEmailHelper("Email is required");
    } else {
      temp_errors = temp_errors.filter((item) => item !== "email_blank");
      setEmailHelper("");
    }

    if (!validEmail && temp_errors.length < 4) {
      temp_errors.push("email_invalid");
      setEmailHelper("Please enter a valid email");
    } else {
      temp_errors = temp_errors.filter((item) => item !== "email_invalid");
      setEmailHelper("");
    }

    if (password === "" && temp_errors.length < 4) {
      temp_errors.push("password_blank");
      setPasswordHelper("Password is required");
    } else {
      temp_errors = temp_errors.filter((item) => item !== "password_blank");
      setPasswordHelper("");
    }

    if (password.length < 8 && temp_errors.length < 4) {
      temp_errors.push("password_minLength");
      setPasswordHelper("Atleasr 8 characters are required");
    } else {
      temp_errors = temp_errors.filter((item) => item !== "password_minLength");
      setPasswordHelper("");
    }
    setErrors(temp_errors);

    if (errors.length > 0) {
      return false;
    } else {
    }
  };

  const handleEmailChange = (event) => {
    let email = event.target.value;
    if (email === "") {
      // setEmailHelper('Email is required');
    } else {
      setEmail(email);
    }
  };

  const handlePasswordChange = (event) => {
    let password = event.target.value;
    if (password === "") {
      setPasswordHelper("Password is required");
    } else if (password !== "" && email !== "") {
      setPassword(password);
      setLogin(false);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    validate();
    const requestData = {
      email: email,
      password: password,
    };

    setLoading(true);
    axios
      .post(LOGIN, requestData)
      .then((res) => {
        setLoading(false);
        handleToast("success", res.data.message);
        localStorage.setItem("token", res.data.token);
        localStorage.setItem("username", res.data.username);
        localStorage.setItem("email", res.data.email);
        localStorage.setItem("is_admin", res.data.is_superuser);
        setTimeout(() => {
          props.history.replace("/services");
        }, 1000);
      })
      .catch((error) => {
        if (error?.response?.data?.non_field_errors[0]) {
          handleToast("error", error?.response?.data?.non_field_errors[0]);
        } else {
          handleToast("error", "Something went wrong, Please try again!");
        }
        setLoading(false);
      });
  };

  const handleToast = (event_type, message) => {
    return event_type === "success"
      ? toast.success(message)
      : toast.error(message);
  };

  const backBtnHandler = () => {
    return props.history.push("/");
  };

  return (
    <React.Fragment>
      <div className="container px-0" style={{ marginTop: "50px" }}>
        <div className="row">
          <div className="col-12 mb-5">
            <Link to={"/"}>
              <img src={logo} alt="" />
            </Link>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-12">
            <h1>
              <span>
                <img
                  src={backLogo}
                  alt="back"
                  className="backBtn"
                  onClick={backBtnHandler}
                />
              </span>{" "}
              <b>LOGIN</b>
            </h1>
            <form
              className={inputClasses.root + " px-2"}
              onSubmit={handleSubmit}
            >
              <div className="form-group mb-5 mt-5 ">
                <TextField
                  error={hassError("email_blank") || hassError("email_invalid")}
                  onChange={(e) => handleEmailChange(e)}
                  id="outlined-error-helper-text"
                  label="Email"
                  defaultValue=""
                  helperText={emailHelper}
                  variant="outlined"
                />
              </div>
              <div className="form-group mb-5">
                <TextField
                  error={
                    hassError("password_blank") ||
                    hassError("password_minLength")
                  }
                  onChange={(e) => handlePasswordChange(e)}
                  id="outlined-password-input"
                  label="Password"
                  type="password"
                  helperText={passwordHelper}
                  inputProps={{ minLength: 8 }}
                  variant="outlined"
                />

                <Link
                  to={"/forgot_password"}
                  className=""
                  style={{ float: "right" }}
                >
                  Forgot Password
                </Link>
              </div>

              <Button
                disabled={login}
                type="submit"
                variant="contained"
                color="primary"
              >
                Login
              </Button>
            </form>
          </div>
          <div
            className="col-lg-8 col-md-12"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <img src={loginImage} alt="" />
          </div>
        </div>
      </div>
      <ToastContainer />
      <Backdrop
        className={classes.backdrop}
        style={{ zIndex: "10" }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </React.Fragment>
  );
};

export default Login;
