import axios from "axios";
import React, { useEffect, useState } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { CALCULATE_SATANGLE, GET_SERVICE_BY_ID } from "../shared/ApiURLs";
import {
  addHeaderInAxios,
  checkSignature,
  TimeResOptns,
} from "../utils/Helpers";
import MyLoader from "../components/ContentLoader";
import { toast, ToastContainer } from "react-toastify";
import backLogo from "../assets/images/back.png";
import NumericInput from "react-numeric-input";

import {
  Backdrop,
  CircularProgress,
  Container,
  makeStyles,
  Button,
} from "@material-ui/core";
import { Col, Row } from "react-bootstrap";
import { SingleSelect } from "../components/SingleSelect";
import { timeZones } from "../utils/timeZones";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const SATAngleService = (props) => {
  const classes = useStyles();
  const [service, setService] = useState([]);
  const [loading, setLoading] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const [isContentLoading, setContentLoading] = useState(true);

  const [latitude, setLatitude] = useState(0);
  const [longitude, setLongitude] = useState(0);
  const [pitch, setPitch] = useState(0);
  const [collectorLength, setCollectorLength] = useState(0);
  const [trackerAngle, setTrackerAngle] = useState(0);
  const [timeResolution, setTimeResolution] = useState(1);
  const [timeResolutionOptns, setTimeResolutionOptns] = useState(null);
  const [timezone, setTimezone] = useState({
    value: "+05.50",
    label: "Asia/Kolkata",
  });
  const [refLon, setRefLon] = useState(0);

  useEffect(() => {
    getServiceById(props.match.params.service_id);
  }, []);

  useEffect(() => {
    setRefLon((parseFloat(timezone.value) * 15).toFixed(2));
  }, [timezone]);

  const getServiceById = (service_id) => {
    if (firstLoad) {
      axios
        .get(GET_SERVICE_BY_ID(service_id))
        .then((res) => {
          setFirstLoad(false);
          if (res.data.service[0].name) {
            setService(res.data.service);
            setContentLoading(false);
          } else {
            setContentLoading(false);
            handleToast("error", "Service Not Found!");
            props.history.replace("/services");
          }
        })
        .catch((error) => {
          checkSignature(error);
          setFirstLoad(false);
          setContentLoading(false);
          props.history.replace("/services");
        });
    }
  };

  const handleToast = (event_type, message) => {
    return event_type === "success"
      ? toast.success(message)
      : toast.error(message);
  };

  const backBtnHandler = () => {
    return props.history.push("/services");
  };

  const submitCalculation = () => {
    let data;
    if (!timeResolutionOptns?.value) {
      return handleToast("error", `Please Choose Time Resolution!`);
    }
    if (timeResolutionOptns?.value === "custom") {
      if (60 % timeResolution !== 0) {
        return handleToast(
          "error",
          `Please Enter a Valid Time Resolution Value!`
        );
      }

      data = {
        latitude: latitude,
        longitude: longitude,
        pitch: pitch,
        collectorLength: collectorLength,
        trackerAngle: trackerAngle,
        timeResolution: timeResolution,
        ref_lon: refLon,
      };
    } else {
      data = {
        latitude: latitude,
        longitude: longitude,
        pitch: pitch,
        collectorLength: collectorLength,
        trackerAngle: trackerAngle,
        timeResolution: parseInt(timeResolutionOptns?.value),
        ref_lon: refLon,
      };
    }

    setLoading(true);
    axios
      .post(CALCULATE_SATANGLE, data, {
        responseType: "blob",
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        const timeStamp = new Date().getTime();
        const fileName = `Tracker_Angle_${timeStamp}.csv`;
        link.setAttribute("download", `${fileName}`);
        document.body.appendChild(link);
        link.click();
        // resetInputs();
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        handleToast("error", `Something went wrong, Please verify inputs!`);
      });
  };

  const handleTimeResChange = (selectedOption, triggeredAction) => {
    if (triggeredAction.action === "clear") {
      setTimeResolutionOptns(null);
    } else {
      setTimeResolutionOptns(selectedOption);
    }
  };

  return (
    <div className="main-wrapper">
      <Header {...props} />
      {isContentLoading && <MyLoader />}
      {!isContentLoading && (
        <Container className="">
          <Row className="mb-3">
            <Col lg={6} md={12} className="mb-3">
              <div className="custom_card p-4">
                <div className="mb-3">
                  <span>
                    <img
                      src={backLogo}
                      alt="back"
                      className="backBtn"
                      onClick={backBtnHandler}
                    />
                  </span>
                  <span className="mx-3 sscalcHeading">{service[0]?.name}</span>
                </div>

                <Row className="mb-3">
                  <Col lg={6} md={12}>
                    <div className="d-flex justify-content-between mb-2">
                      <h6>Latitude (°):</h6>
                    </div>
                    <NumericInput
                      value={latitude}
                      onChange={(e) => (e ? setLatitude(e) : setLatitude(0))}
                      className="form-control"
                      min={-90}
                      max={90}
                      format={(num) => {
                        return num + "°";
                      }}
                    />
                  </Col>
                  <Col lg={6} md={12}>
                    <div className="d-flex justify-content-between mb-2">
                      <h6>Longitude (°):</h6>
                    </div>
                    <NumericInput
                      value={longitude}
                      onChange={(e) => (e ? setLongitude(e) : setLongitude(0))}
                      className="form-control"
                      min={-180}
                      max={180}
                      format={(num) => {
                        return num + "°";
                      }}
                    />
                  </Col>
                </Row>

                <Row className="mb-3">
                  <Col lg={6} md={12}>
                    <div className="d-flex justify-content-between mb-2">
                      <h6>Timezone:</h6>
                    </div>
                    <SingleSelect
                      data={timeZones}
                      value={timezone}
                      placeholder={"Select one of them..."}
                      isDisabled={false}
                      isLoading={false}
                      handleChange={setTimezone}
                    />
                  </Col>
                  <Col lg={6} md={12}>
                    <div className="d-flex justify-content-between mb-2">
                      <h6>Ref Longitude (°):</h6>
                    </div>
                    <input
                      type="text"
                      className="form-control text-center"
                      value={refLon}
                      disabled={true}
                    />
                  </Col>
                </Row>

                <Row className="mb-3">
                  <Col lg={6} md={12}>
                    <div className="d-flex justify-content-between mb-2">
                      <h6>Pitch (m):</h6>
                    </div>
                    <NumericInput
                      className="form-control"
                      onChange={(e) => (e ? setPitch(e) : setPitch(0))}
                      min={0}
                      max={1000000000}
                      value={pitch}
                      format={(num) => {
                        return num + " m";
                      }}
                    />
                  </Col>
                  <Col lg={6} md={12}>
                    <div className="d-flex justify-content-between mb-2">
                      <h6>collector Length (m):</h6>
                    </div>
                    <NumericInput
                      className="form-control"
                      onChange={(e) =>
                        e ? setCollectorLength(e) : setCollectorLength(0)
                      }
                      min={0}
                      max={1000000000}
                      value={collectorLength}
                      format={(num) => {
                        return num + " m";
                      }}
                    />
                  </Col>
                </Row>

                <Row className="mb-4">
                  <Col lg={6} md={12}>
                    <div className="d-flex justify-content-between mb-2">
                      <h6>Tracker Limiting Angle (°):</h6>
                    </div>
                    <NumericInput
                      className="form-control"
                      onChange={(e) =>
                        e ? setTrackerAngle(e) : setTrackerAngle(0)
                      }
                      min={0}
                      max={75}
                      value={trackerAngle}
                      format={(num) => {
                        return num + "°";
                      }}
                    />
                  </Col>
                  <Col lg={6} md={12}>
                    <div className="d-flex justify-content-between mb-2">
                      <h6>Time Resolution Options:</h6>
                    </div>
                    <SingleSelect
                      data={TimeResOptns}
                      value={timeResolutionOptns}
                      placeholder={"Select one of them..."}
                      isDisabled={false}
                      isLoading={false}
                      handleChange={handleTimeResChange}
                    />
                  </Col>
                </Row>

                <Row className="mb-2">
                  {timeResolutionOptns?.value === "custom" && (
                    <Col lg={6} md={12}>
                      <div className="d-flex justify-content-between mb-2">
                        <h6>Time Resolution (minutes):</h6>
                      </div>
                      <NumericInput
                        className="form-control"
                        onChange={(e) =>
                          e ? setTimeResolution(e) : setTimeResolution(0)
                        }
                        min={1}
                        max={1000000000}
                        value={timeResolution}
                        format={(num) => {
                          return num + " mins";
                        }}
                        onKeyDown={(e) => {
                          if (e.key === ".") {
                            e.preventDefault();
                          }
                        }}
                      />
                    </Col>
                  )}

                  <Col lg={6} md={12}>
                    <Button
                      style={
                        timeResolutionOptns?.value === "custom"
                          ? { marginTop: "2rem" }
                          : {}
                      }
                      color="primary"
                      className="px-4 w-100"
                      variant="contained"
                      component="span"
                      onClick={submitCalculation}
                    >
                      Calculate
                    </Button>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col lg={6} md={12}>
              <div className="custom_card p-4">
                <div>
                  <div>
                    <p className="text-justify my-3">
                      <b>What is {service[0]?.name} service?</b>
                      <br />
                      {service[0]?.description}
                    </p>

                    <p className="my-3 text-justify">
                      <b>How to use {service[0]?.name} service?</b>
                      <br />
                      {service[0]?.how_to_use}
                    </p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      )}

      <ToastContainer />
      <Backdrop
        className={classes.backdrop}
        style={{ zIndex: "10" }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Footer />
    </div>
  );
};

export default SATAngleService;
