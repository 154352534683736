import axios from "axios";
import React, { useEffect, useState } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import {
  GET_INVERTER_DETAILS,
  GET_MODULE_DETAILS,
  GET_SERVICE_BY_ID,
} from "../shared/ApiURLs";
import {
  checkSignature,
  inverterLibraryFields,
  moduleLibraryFields,
} from "../utils/Helpers";
import MyLoader from "../components/ContentLoader";
import { toast, ToastContainer } from "react-toastify";
import backLogo from "../assets/images/back.png";
import NumericInput from "react-numeric-input";

import {
  Backdrop,
  CircularProgress,
  Container,
  makeStyles,
  Button,
} from "@material-ui/core";
import { Col, Row } from "react-bootstrap";
import { SingleSelect } from "../components/SingleSelect";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const StringSizerService = (props) => {
  const classes = useStyles();
  const [service, setService] = useState([]);
  const [loading, setLoading] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const [isContentLoading, setContentLoading] = useState(true);

  // const [vMax, setVmax] = useState(0);
  // const [v_mpp_min, setV_mpp_min] = useState(0);
  // const [v_mpp_max, setV_mpp_max] = useState(0);
  // const [i_max, setI_max] = useState(0);
  // const [i_sc, setI_sc] = useState(0);
  // const [max_inputs, setMax_inputs] = useState(0);

  const [tmod_min, setTmod_min] = useState(10);
  const [tmod_nom, setTmod_nom] = useState(50);
  const [tmod_max, setTmod_max] = useState(65);

  const [seriesLowerBound, setSeriesLowerBound] = useState(0);
  const [seriesUpperBound, setSeriesUpperBound] = useState(0);

  const [parallelLowerBound, setParallelLowerBound] = useState(0);
  const [parallelUpperBound, setParallelUpperBound] = useState(0);

  const [seriesStringSize, setSeriesStringSize] = useState(0);
  const [parallelStringSize, setParallelStringSize] = useState(0);

  const [stringVoc, setStringVoc] = useState([0, 0, 0]);
  const [stringVm, setStringVm] = useState([0, 0, 0]);
  const [stringIsc, setStringIsc] = useState([0, 0, 0]);
  const [stringIm, setStringIm] = useState([0, 0, 0]);

  const [showStringPara, setShowStringPara] = useState(false);
  const [showOutput, setShowOutput] = useState(false);
  const [moduleDropdownData, setModuleDropdownData] = useState({});
  const [moduleDetails, setModuleDetails] = useState(moduleLibraryFields);
  const [inverterDetails, setInverterDetails] = useState(inverterLibraryFields);
  const [inverterDropdownData, setInverterDropdownData] = useState({});

  useEffect(() => {
    getServiceById(props.match.params.service_id);
  });

  // useEffect(() => {
  //   console.log(t_v, t_p, t_i);
  //   console.log(seriesStringSize, parallelStringSize);
  // }, [t_v, t_p, t_i, seriesStringSize, parallelStringSize]);

  const getServiceById = (service_id) => {
    if (firstLoad) {
      axios
        .get(GET_SERVICE_BY_ID(service_id))
        .then((res) => {
          setFirstLoad(false);
          if (res.data.service[0].name) {
            setService(res.data.service);
            axios
              .get(GET_MODULE_DETAILS)
              .then((res) => {
                setModuleDropdownData(res.data.dropdown_data);
                setContentLoading(false);
              })
              .catch((e) => {
                checkSignature(e);
                toast.error("Error while fetching module details!");
                setContentLoading(false);
              });
            axios
              .get(GET_INVERTER_DETAILS)
              .then((res) => {
                setInverterDropdownData(res.data.dropdown_data);
                setContentLoading(false);
              })
              .catch((e) => {
                checkSignature(e);
                toast.error("Error while fetching inverter details!");
                setContentLoading(false);
              });
          } else {
            setContentLoading(false);
            handleToast("error", "Service Not Found!");
            props.history.replace("/services");
          }
        })
        .catch((error) => {
          checkSignature(error);
          setFirstLoad(false);
          setContentLoading(false);
          props.history.replace("/services");
        });
    }
  };

  const handleToast = (event_type, message) => {
    return event_type === "success"
      ? toast.success(message)
      : toast.error(message);
  };

  const backBtnHandler = () => {
    return props.history.push("/services");
  };

  const calcStringParameter = () => {
    let seriesStringSize_ = parseInt(seriesStringSize);
    let parallelStringSize_ = parseInt(parallelStringSize);

    if (seriesStringSize_ < seriesLowerBound) {
      return handleToast(
        "error",
        `Selected Series String Size Can't be smaller than ${seriesLowerBound}`
      );
    } else if (seriesStringSize_ > seriesUpperBound) {
      return handleToast(
        "error",
        `Selected Series String Size Can't be greater than ${seriesUpperBound}`
      );
    } else if (parallelStringSize_ < parallelLowerBound) {
      return handleToast(
        "error",
        `Selected Parallel String Size Can't be smaller than ${parallelLowerBound}`
      );
    } else if (parallelStringSize_ > parallelUpperBound) {
      return handleToast(
        "error",
        `Selected Parallel String Size Can't be greater than ${parallelUpperBound}`
      );
    }

    setShowStringPara(true);
    let stringVoc = [];
    let stringVm = [];
    let stringIsc = [];
    let stringIm = [];

    let envPara = [tmod_min, tmod_nom, tmod_max];

    let tc_p = moduleDetails?.gamma_t;
    let tc_v = moduleDetails?.beta_t;
    let tc_i = moduleDetails?.alpha_t;

    envPara.forEach((val) => {
      let a = moduleDetails?.Voc * seriesStringSize_ * (1 + tc_v * (val - 25));
      stringVoc.push(parseFloat(a).toFixed(2));
      let b = moduleDetails?.Vm * seriesStringSize_ * (1 + tc_p * (val - 25));
      stringVm.push(parseFloat(b).toFixed(2));
      let c =
        moduleDetails?.Isc * parallelStringSize_ * (1 + tc_i * (val - 25));
      stringIsc.push(parseFloat(c).toFixed(2));
      let d = moduleDetails?.Im * parallelStringSize_ * (1 + tc_i * (val - 25));
      stringIm.push(parseFloat(d).toFixed(2));
    });

    setStringVoc(stringVoc);
    setStringVm(stringVm);
    setStringIm(stringIm);
    setStringIsc(stringIsc);
    console.log(stringVoc, stringVm);
  };

  const calcRecomStringSize = () => {
    const isValid = validateInputs();
    if (!isValid[0])
      return handleToast(
        "error",
        `Input '${isValid[1]}' can't be empty or zero!`
      );


    let Vm_ = [];
    let Voc_ = [];
    let Im_ = [];
    let Isc_ = [];

    let envPara = [tmod_min, tmod_nom, tmod_max];

    let tc_p = parseFloat(moduleDetails?.gamma_t);
    let tc_v = parseFloat(moduleDetails?.beta_t);
    let tc_i = parseFloat(moduleDetails?.alpha_t);

    envPara.forEach((e) => {
      console.log(e);
      let vm__ = moduleDetails?.Vm * (1 + tc_p * (e - 25));
      Vm_.push(vm__);

      let voc__ = moduleDetails?.Voc * (1 + tc_v * (e - 25));
      Voc_.push(voc__);

      let im__ = moduleDetails?.Im * (1 + tc_i * (e - 25));
      Im_.push(im__);

      let isc__ = moduleDetails?.Isc * (1 + tc_i * (e - 25));
      Isc_.push(isc__);
    });

    Vm_ = Vm_.sort();
    Voc_ = Voc_.sort();
    Im_ = Im_.sort();
    Isc_ = Isc_.sort();

    let seriesLB = Math.ceil(inverterDetails?.Vmpp_min / Vm_[0]);
    let seriesUB = Math.floor(
      Math.min(
        inverterDetails?.Vmax / Voc_[2],
        inverterDetails?.Vmpp_max / Vm_[2]
      )
    );
    let parallelLB = Math.ceil((inverterDetails?.Imax_dc * 0.5) / Im_[0]);
    let parallelUB = Math.floor(
      Math.min(
        inverterDetails?.Isc_dc / Isc_[2],
        inverterDetails?.Imax_dc / Im_[2],
        inverterDetails?.max_inputs
      )
    );
    
    if (seriesLB > seriesUB || parallelLB > parallelUB){
      return handleToast("error","String design is not possible, please check your inputs.")
    
    }
    setShowOutput(true);
    setSeriesLowerBound(seriesLB);
    setSeriesUpperBound(seriesUB);
    setParallelLowerBound(parallelLB);
    setParallelUpperBound(parallelUB);
  };

  const validateInputs = () => {
    let validatorObj = {
      tmod_min,
      tmod_nom,
      tmod_max,
    };

    let is_valid = true;
    let key = "";
    Object.keys(validatorObj).forEach((e) => {
      if (!validatorObj[e]) {
        key = e;
        return (is_valid = false);
      }
    });

    return [is_valid, key];
  };

  const handleModuleDetailsChanges = (key, value) => {
    let tempModuleDetails = null;
    if (key === "Model") {
      tempModuleDetails = value.details;
      tempModuleDetails.Model = value;
      tempModuleDetails.Make = moduleDetails.Make;
      setModuleDetails({ ...tempModuleDetails });
      return true;
    } else if (key === "Make") {
      tempModuleDetails = moduleLibraryFields;
    } else {
      tempModuleDetails = moduleDetails;
    }
    tempModuleDetails[key] = value;
    setModuleDetails({ ...tempModuleDetails });
    return true;
  };

  const handleInverterDetailsChanges = (key, value) => {
    console.log("first");
    let tempInverterDetails = null;
    if (key === "Model") {
      tempInverterDetails = value.details;
      tempInverterDetails.Model = value;
      tempInverterDetails.Make = inverterDetails.Make;
      setInverterDetails({ ...tempInverterDetails });
      return true;
    } else if (key === "Make") {
      tempInverterDetails = inverterLibraryFields;
    } else {
      tempInverterDetails = inverterDetails;
    }
    tempInverterDetails[key] = value;
    setInverterDetails({ ...tempInverterDetails });
    return true;
  };

  const resetCalc = () => {
    setTmod_min(10);
    setTmod_nom(50);
    setTmod_max(65);
    setSeriesLowerBound(0);
    setSeriesUpperBound(0);
    setParallelLowerBound(0);
    setParallelUpperBound(0);
    setSeriesStringSize(0);
    setParallelStringSize(0);
    setStringVoc([0, 0, 0]);
    setStringVm([0, 0, 0]);
    setStringIsc([0, 0, 0]);
    setStringIm([0, 0, 0]);
    setShowStringPara(false);
    setShowOutput(false);
    setModuleDetails(moduleLibraryFields);
    setInverterDetails(inverterLibraryFields);
  };

  const handleTextToNumber = (event, type) => {
    var regExp = /[a-zA-Z]/g;

    if (regExp.test(event.target.value)) {
      return;
    }
    let val = event.target.value;
    if (type === "series") {
      return setSeriesStringSize(val);
    } else if (type === "parallel") {
      return setParallelStringSize(val);
    }
  };

  return (
    <div className="main-wrapper">
      <Header {...props} />
      {isContentLoading && <MyLoader />}
      {!isContentLoading && (
        <Container id="stringSizer" className="mb-5">
          <Row className="mb-3">
            <Col lg={8} md={12} className="mb-3">
              <div className="custom_card p-4">
                <div className="mb-3 row">
                  <div className="col-12 col-md-5">
                    <span>
                      <img
                        src={backLogo}
                        alt="back"
                        className="backBtn"
                        onClick={backBtnHandler}
                      />
                    </span>
                    <span className="mx-3 sscalcHeading">
                      {service[0]?.name}
                    </span>
                  </div>
                  <div className="col-12 col-md-7">
                    <span className="float-right mt-2">
                      <button
                        className="btn btn-danger h-25 d-inline-block mx-2"
                        onClick={resetCalc}
                      >
                        Reset
                      </button>
                      <Button
                        color="primary"
                        variant="contained"
                        className=""
                        onClick={calcRecomStringSize}
                      >
                        Calculate
                      </Button>
                    </span>
                  </div>
                </div>

                <div className="mt-4 mb-3">
                  <h6>Module Database:</h6>
                  <Row className=" my-2">
                    <Col md={6}>
                      <SingleSelect
                        data={moduleDropdownData?.makes}
                        value={moduleDetails?.Make}
                        placeholder={"Select Make..."}
                        isDisabled={false}
                        isLoading={false}
                        handleChange={(e) =>
                          handleModuleDetailsChanges("Make", e)
                        }
                      />
                    </Col>
                    <Col md={6}>
                      <SingleSelect
                        data={moduleDetails?.Make?.models}
                        value={moduleDetails?.Model}
                        placeholder={"Select Model..."}
                        isDisabled={!moduleDetails?.Make}
                        isLoading={!moduleDetails?.Make}
                        handleChange={(e) =>
                          handleModuleDetailsChanges("Model", e)
                        }
                      />
                    </Col>
                  </Row>
                  <div>
                    <table className="table table-striped table-bordered mb-0 ">
                      <thead className="thead-dark">
                        <tr>
                          <th scope="col" className="text-center p-2">
                            Pmax
                          </th>
                          <th scope="col" className="text-center p-2">
                            Vm
                          </th>
                          <th scope="col" className="text-center p-2">
                            Im
                          </th>
                          <th scope="col" className="text-center p-2">
                            Voc
                          </th>
                          <th scope="col" className="text-center p-2">
                            Isc
                          </th>
                          <th scope="col" className="text-center p-2">
                            Beta
                          </th>
                          <th scope="col" className="text-center p-2">
                            Gamma
                          </th>
                          <th scope="col" className="text-center p-2">
                            Alpha
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="p-1">
                            <NumericInput
                              className="form-control custMinWidth"
                              value={moduleDetails?.Pmax}
                              onChange={(v) =>
                                handleModuleDetailsChanges("Pmax", v)
                              }
                              min={0}
                            />
                          </td>
                          <td className="p-1">
                            <NumericInput
                              className="form-control custMinWidth"
                              value={moduleDetails?.Vm}
                              onChange={(v) =>
                                handleModuleDetailsChanges("Vm", v)
                              }
                              min={0}
                            />
                          </td>
                          <td className="p-1">
                            <NumericInput
                              className="form-control custMinWidth"
                              value={moduleDetails?.Im}
                              onChange={(v) =>
                                handleModuleDetailsChanges("Im", v)
                              }
                              min={0}
                            />
                          </td>
                          <td className="p-1">
                            <NumericInput
                              className="form-control custMinWidth"
                              value={moduleDetails?.Voc}
                              onChange={(v) =>
                                handleModuleDetailsChanges("Voc", v)
                              }
                              min={0}
                            />
                          </td>
                          <td className="p-1">
                            <NumericInput
                              className="form-control custMinWidth"
                              value={moduleDetails?.Isc}
                              onChange={(v) =>
                                handleModuleDetailsChanges("Isc", v)
                              }
                              min={0}
                            />
                          </td>
                          <td className="p-1">
                            <input
                              className="form-control custMinWidth"
                              value={moduleDetails?.beta_t}
                              onChange={(v) =>
                                handleModuleDetailsChanges(
                                  "beta_t",
                                  v.target.value
                                )
                              }
                            />
                          </td>
                          <td className="p-1">
                            <input
                              className="form-control custMinWidth"
                              value={moduleDetails?.gamma_t}
                              onChange={(v) =>
                                handleModuleDetailsChanges(
                                  "gamma_t",
                                  v.target.value
                                )
                              }
                            />
                          </td>
                          <td className="p-1">
                            <input
                              className="form-control custMinWidth"
                              value={moduleDetails?.alpha_t}
                              onChange={(v) =>
                                handleModuleDetailsChanges(
                                  "alpha_t",
                                  v.target.value
                                )
                              }
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <div className="mt-4 mb-3">
                  <h6>Inverter Datasheet:</h6>
                  <Row className=" my-2">
                    <Col md={6}>
                      <SingleSelect
                        data={inverterDropdownData?.makes}
                        value={inverterDetails?.Make}
                        placeholder={"Select Make..."}
                        isDisabled={false}
                        isLoading={false}
                        handleChange={(e) =>
                          handleInverterDetailsChanges("Make", e)
                        }
                      />
                    </Col>
                    <Col md={6}>
                      <SingleSelect
                        data={inverterDetails?.Make?.models}
                        value={inverterDetails?.Model}
                        placeholder={"Select Model..."}
                        isDisabled={!inverterDetails?.Make}
                        isLoading={!inverterDetails?.Make}
                        handleChange={(e) =>
                          handleInverterDetailsChanges("Model", e)
                        }
                      />
                    </Col>
                  </Row>
                  <div>
                    <table className="table table-striped table-bordered mb-0 ">
                      <thead className="thead-dark">
                        <tr>
                          <th scope="col" className="text-center p-2">
                            Vmax
                          </th>
                          <th scope="col" className="text-center p-2">
                            V-mpp-min
                          </th>
                          <th scope="col" className="text-center p-2">
                            V-mpp-max
                          </th>
                          <th scope="col" className="text-center p-2">
                            I-max
                          </th>
                          <th scope="col" className="text-center p-2">
                            I-sc
                          </th>
                          <th scope="col" className="text-center p-2">
                            max-inputs
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="p-1">
                            <NumericInput
                              className="form-control custMinWidth"
                              value={inverterDetails?.Vmax}
                              onChange={(v) =>
                                handleInverterDetailsChanges("Vmax", v)
                              }
                              min={0}
                            />
                          </td>
                          <td className="p-1">
                            <NumericInput
                              className="form-control custMinWidth"
                              min={0}
                              value={inverterDetails?.Vmpp_min}
                              onChange={(v) =>
                                handleInverterDetailsChanges("Vmpp_min", v)
                              }
                            />
                          </td>
                          <td className="p-1">
                            <NumericInput
                              className="form-control custMinWidth"
                              value={inverterDetails?.Vmpp_max}
                              onChange={(v) =>
                                handleInverterDetailsChanges("Vmpp_max", v)
                              }
                              min={0}
                            />
                          </td>
                          <td className="p-1">
                            <NumericInput
                              className="form-control custMinWidth"
                              value={inverterDetails?.Imax_dc}
                              onChange={(v) =>
                                handleInverterDetailsChanges("Imax_dc", v)
                              }
                              min={0}
                            />
                          </td>
                          <td className="p-1">
                            <NumericInput
                              className="form-control custMinWidth"
                              value={inverterDetails?.Isc_dc}
                              onChange={(v) =>
                                handleInverterDetailsChanges("Isc_dc", v)
                              }
                              min={0}
                            />
                          </td>
                          <td className="p-1">
                            <NumericInput
                              className="form-control custMinWidth"
                              value={inverterDetails?.max_inputs}
                              onChange={(v) =>
                                handleInverterDetailsChanges("max_inputs", v)
                              }
                              min={0}
                              onKeyDown={(e) => {
                                if (e.key === ".") {
                                  e.preventDefault();
                                }
                              }}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <div className="mt-4 mb-3 overflow-auto">
                  <h6>Environmental Parameters:</h6>
                  <div>
                    <table className="table table-striped table-bordered mb-0 ">
                      <thead className="thead-dark">
                        <tr>
                          <th scope="col" className="text-center p-2">
                            Tmod_min
                          </th>
                          <th scope="col" className="text-center p-2">
                            Tmod_nom
                          </th>
                          <th scope="col" className="text-center p-2">
                            Tmod_max
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="p-1">
                            <NumericInput
                              className="form-control custMinWidth"
                              value={tmod_min}
                              onChange={setTmod_min}
                              min={0}
                              onKeyDown={(e) => {
                                if (e.key === ".") {
                                  e.preventDefault();
                                }
                              }}
                            />
                          </td>
                          <td className="p-1">
                            <NumericInput
                              className="form-control custMinWidth"
                              value={tmod_nom}
                              onChange={setTmod_nom}
                              min={0}
                              onKeyDown={(e) => {
                                if (e.key === ".") {
                                  e.preventDefault();
                                }
                              }}
                            />
                          </td>
                          <td className="p-1">
                            <NumericInput
                              className="form-control custMinWidth"
                              value={tmod_max}
                              onChange={setTmod_max}
                              min={0}
                              onKeyDown={(e) => {
                                if (e.key === ".") {
                                  e.preventDefault();
                                }
                              }}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              {showOutput && (
                <div className="custom_card my-3 p-4">
                  <div>
                    <h2 className="my-2">Help</h2>
                    <div className="row">
                      <div className="col-12 col-md-6">
                        <p className="text-justify my-3">
                          <b>What is {service[0]?.name} service?</b>
                          <br />
                          {service[0]?.description}
                        </p>
                      </div>
                      <div className="col-12 col-md-6">
                        <p className="my-3 text-justify">
                          <b>How to use {service[0]?.name} service?</b>
                          <br />
                          {service[0]?.how_to_use}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </Col>

            {showOutput && (
              <Col lg={4} md={12}>
                <div className="custom_card p-4">
                  <div>
                    <h2 className="my-2">Output</h2>

                    <div className="my-4 overflow-auto">
                      <h6>Recommended String size:</h6>
                      <div>
                        <table className="table table-striped table-bordered mb-0 ">
                          <thead className="thead-dark">
                            <tr>
                              <th scope="col" className="text-center p-2"></th>
                              <th scope="col" className="text-center p-2">
                                Lower Bound
                              </th>
                              <th scope="col" className="text-center p-2">
                                Upper Bound
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <th scope="row" className="align-middle">
                                Series
                              </th>
                              <td className="p-1">
                                <input
                                  type="text"
                                  disabled={true}
                                  className="form-control custMinWidth"
                                  value={seriesLowerBound}
                                />
                              </td>
                              <td className="p-1">
                                <input
                                  type="text"
                                  disabled={true}
                                  className="form-control custMinWidth"
                                  value={seriesUpperBound}
                                />
                              </td>
                            </tr>
                            <tr>
                              <th scope="row" className="align-middle">
                                Parallel
                              </th>

                              <td className="p-1">
                                <input
                                  type="text"
                                  disabled={true}
                                  className="form-control custMinWidth"
                                  value={parallelLowerBound}
                                />
                              </td>
                              <td className="p-1">
                                <input
                                  type="text"
                                  disabled={true}
                                  className="form-control custMinWidth"
                                  value={parallelUpperBound}
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div className="mb-3 overflow-auto">
                      {/* <h6>Select String Size:</h6> */}
                      <table className="table table-striped table-bordered mb-0 ">
                        <thead className="thead-dark">
                          <tr>
                            <th scope="col" className="text-center p-2">
                              Series
                            </th>
                            <th scope="col" className="text-center p-2">
                              Parallel
                            </th>
                            <th scope="col" className="text-center p-2">
                              o/p
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="p-1">
                              <input
                                type="text"
                                className="form-control"
                                value={String(seriesStringSize)}
                                onChange={(v) =>
                                  handleTextToNumber(v, "series")
                                }
                                // min={seriesLowerBound}
                                // max={seriesUpperBound}
                                onKeyDown={(e) => {
                                  if (e.key === ".") {
                                    e.preventDefault();
                                  }
                                }}
                              />
                            </td>
                            <td className="p-1">
                              <input
                                type="text"
                                className="form-control"
                                value={String(parallelStringSize)}
                                onChange={(v) =>
                                  handleTextToNumber(v, "parallel")
                                }
                                // min={parallelLowerBound}
                                // max={parallelUpperBound}
                                onKeyDown={(e) => {
                                  if (e.key === ".") {
                                    e.preventDefault();
                                  }
                                }}
                              />
                            </td>
                            <td className="p-1">
                              <Button
                                color="primary"
                                variant="contained"
                                className=""
                                onClick={calcStringParameter}
                              >
                                <small>Calc</small>
                              </Button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    {showStringPara && (
                      <div className="mb-3 overflow-auto">
                        <h6>String parameters o/p:</h6>
                        <table className="table table-striped table-bordered mb-0 ">
                          <thead className="thead-dark">
                            <tr>
                              <th scope="col" className="text-center p-2">
                                String
                              </th>
                              <th scope="col" className="text-center p-2">
                                @{tmod_min}
                              </th>
                              <th scope="col" className="text-center p-2">
                                @{tmod_nom}
                              </th>
                              <th scope="col" className="text-center p-2">
                                @{tmod_max}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <th scope="row" className="align-middle">
                                Voc
                              </th>
                              <td className="p-1">
                                <input
                                  type="text"
                                  disabled={true}
                                  className="form-control"
                                  value={stringVoc[0]}
                                />
                              </td>
                              <td className="p-1">
                                <input
                                  type="text"
                                  disabled={true}
                                  className="form-control"
                                  value={stringVoc[1]}
                                />
                              </td>
                              <td className="p-1">
                                <input
                                  type="text"
                                  disabled={true}
                                  className="form-control"
                                  value={stringVoc[2]}
                                />
                              </td>
                            </tr>
                            <tr>
                              <th scope="row" className="align-middle">
                                Vm
                              </th>
                              <td className="p-1">
                                <input
                                  type="text"
                                  disabled={true}
                                  className="form-control"
                                  value={stringVm[0]}
                                />
                              </td>
                              <td className="p-1">
                                <input
                                  type="text"
                                  disabled={true}
                                  className="form-control"
                                  value={stringVm[1]}
                                />
                              </td>
                              <td className="p-1">
                                <input
                                  type="text"
                                  disabled={true}
                                  className="form-control"
                                  value={stringVm[2]}
                                />
                              </td>
                            </tr>
                            {/* add Isc Im */}
                            <tr>
                              <th scope="row" className="align-middle">
                                Isc
                              </th>
                              <td className="p-1">
                                <input
                                  type="text"
                                  disabled={true}
                                  className="form-control"
                                  value={stringIsc[0]}
                                />
                              </td>
                              <td className="p-1">
                                <input
                                  type="text"
                                  disabled={true}
                                  className="form-control"
                                  value={stringIsc[1]}
                                />
                              </td>
                              <td className="p-1">
                                <input
                                  type="text"
                                  disabled={true}
                                  className="form-control"
                                  value={stringIsc[2]}
                                />
                              </td>
                            </tr>
                            <tr>
                              <th scope="row" className="align-middle">
                                Im
                              </th>
                              <td className="p-1">
                                <input
                                  type="text"
                                  disabled={true}
                                  className="form-control"
                                  value={stringIm[0]}
                                />
                              </td>
                              <td className="p-1">
                                <input
                                  type="text"
                                  disabled={true}
                                  className="form-control"
                                  value={stringIm[1]}
                                />
                              </td>
                              <td className="p-1">
                                <input
                                  type="text"
                                  disabled={true}
                                  className="form-control"
                                  value={stringIm[2]}
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    )}
                  </div>
                </div>
              </Col>
            )}

            {!showOutput && (
              <Col lg={4} md={12}>
                <div className="custom_card p-4">
                  <div>
                    <h2 className="my-2">Help</h2>

                    <div>
                      <p className="text-justify my-3">
                        <b>What is {service[0]?.name} service?</b>
                        <br />
                        {service[0]?.description}
                      </p>

                      <p className="my-3 text-justify">
                        <b>How to use {service[0]?.name} service?</b>
                        <br />
                        {service[0]?.how_to_use}
                      </p>
                    </div>
                  </div>
                </div>
              </Col>
            )}
          </Row>
        </Container>
      )}

      <ToastContainer />
      <Backdrop
        className={classes.backdrop}
        style={{ zIndex: "10" }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Footer />
    </div>
  );
};

export default StringSizerService;
