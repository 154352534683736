import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
// import downloadIco from "../assets/images/cloud-computing.png";
import ReactPaginate from "react-paginate";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grow,
} from "@material-ui/core";
import { getNameFromUrl, sliceName } from "../utils/Helpers";
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";
import { InputGroup } from "react-bootstrap";
import { FormControl } from "react-bootstrap";

import moment from "moment";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Grow ref={ref} {...props} style={{ transitionDuration: "400ms" }} />;
});

export default function CloudListModal(props) {
  const classes = useStyles();
  const [pageNumber, setPageNumber] = useState(0);
  const [processedData, setProcessedData] = useState([]);
  const [searchBarValue, setSearchBarValue] = useState("");

  useEffect(() => {
    setProcessedData(props.processedData);
  }, [props]);

  const dataPerPage = 4;
  const pageVisited = pageNumber * dataPerPage;
  const pageCount = Math.ceil(processedData.length / dataPerPage);

  const handleClose = () => {
    props.onHide();
    props.resetSelectedData();
  };

  const showProcessedList = processedData
    .slice(pageVisited, pageVisited + dataPerPage)
    .map((el) => {
      if (props.is_ivel) {
        return (
          <TableRow key={el?.id}>
            <TableCell
              component="th"
              scope="row"
              className="wrapText"
              align="left"
            >
              <a
                href={el?.processed_zip_url}
                download
                target="_blank"
                rel="noreferrer"
              >
                {sliceName(getNameFromUrl(el?.processed_zip_url))}
              </a>
            </TableCell>

            {/* <TableCell
              component="th"
              scope="row"
              className="wrapText"
              align="left"
            >
              <a
                href={el?.processed_xl_url}
                download
                target="_blank"
                rel="noreferrer"
              >
                {sliceName(getNameFromUrl(el?.processed_xl_url))}
              </a>
            </TableCell> */}

            <TableCell
              component="th"
              scope="row"
              className="wrapText"
              align="center"
            >
              {el?.iv_device_make}
            </TableCell>

            {el?.zip_size !== null && (
              <TableCell align="center">
                {String((parseInt(el?.zip_size) / 1024 / 1024).toFixed(2)) +
                  " MB"}
              </TableCell>
            )}

            {el?.zip_size === null && <TableCell align="center">NA</TableCell>}

            {/* {el?.xl_size !== null && (
              <TableCell align="center">
                {String((parseInt(el?.xl_size) / 1024 / 1024).toFixed(2)) +
                  " MB"}
              </TableCell>
            )}

            {el?.xl_size === null && <TableCell align="center">NA</TableCell>} */}

            <TableCell align="center">
              {moment(el?.created_at).format("Do MMM YY")}
            </TableCell>

            <TableCell align="center">
              <Button
                color="primary"
                variant="contained"
                onClick={() => props.handleDataSelect(el)}
              >
                Use
              </Button>
            </TableCell>
          </TableRow>
        );
      } else {
        return (
          <TableRow key={el?.id}>
            <TableCell
              component="th"
              scope="row"
              className="wrapText"
              align="left"
            >
              <a
                href={el?.uploaded_zip_url}
                download
                target="_blank"
                rel="noreferrer"
              >
                {sliceName(el?.original_zip_name)}
              </a>
            </TableCell>

            <TableCell
              component="th"
              scope="row"
              className="wrapText"
              align="left"
            >
              <a
                href={el?.result_xlsx}
                download
                target="_blank"
                rel="noreferrer"
              >
                {sliceName(getNameFromUrl(el?.result_xlsx))}
              </a>
            </TableCell>

            {el?.zip_size !== null && (
              <TableCell align="center">
                {String((parseInt(el?.zip_size) / 1024 / 1024).toFixed(2)) +
                  " MB"}
              </TableCell>
            )}

            {el?.zip_size === null && <TableCell align="center">NA</TableCell>}

            <TableCell align="center">
              {moment(el?.created_at).format("Do MMM YY")}
            </TableCell>

            <TableCell align="center">
              <Button
                color="primary"
                variant="contained"
                onClick={() => props.handleDataSelect(el)}
              >
                Use
              </Button>
            </TableCell>
          </TableRow>
        );
      }
    });

  const onPageChange = ({ selected }) => {
    setPageNumber(selected);
  };

  const handleSearch = () => {
    if (searchBarValue === "") {
      return setProcessedData(props.processedData);
    }
    let newProcessArr = [];
    if (props.is_ivel) {
      newProcessArr = props.processedData.filter((el) => {
        return String(getNameFromUrl(el?.processed_zip_url))
          .toLowerCase()
          .includes(searchBarValue.toLowerCase());
        //   ||
        // String(getNameFromUrl(el?.processed_xl_url))
        //   .toLowerCase()
        //   .includes(searchBarValue.toLowerCase())
      });
    } else {
      newProcessArr = props.processedData.filter((el) => {
        return (
          String(el?.original_zip_name)
            .toLowerCase()
            .includes(searchBarValue.toLowerCase()) ||
          String(getNameFromUrl(el?.result_xlsx))
            .toLowerCase()
            .includes(searchBarValue.toLowerCase())
        );
      });
    }

    return setProcessedData(newProcessArr);
  };

  return (
    <div>
      <Dialog
        fullScreen
        open={props.show}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {props.is_ivel
                ? "Your Processed IVSTC Data"
                : "Your Processed IVEL Data"}
            </Typography>
            {/* <Button autoFocus color="inherit" onClick={handleClose}>
              save
            </Button> */}
          </Toolbar>
        </AppBar>
        {/* {process.length !== 0 && ( */}
        <div className="px-3 mt-3 py-3">
          <Row className="my-3">
            <Col sm={12} md={6}>
              <div className="mb-3">
                <InputGroup className="search-bar">
                  <FormControl
                    placeholder="Search by zip or xl name..."
                    aria-label="Search by name"
                    aria-describedby="basic-addon2"
                    value={searchBarValue}
                    onChange={(e) => {
                      setSearchBarValue(e.target.value);
                      handleSearch();
                    }}
                  />
                </InputGroup>
              </div>
            </Col>
          </Row>

          <TableContainer>
            <Table aria-label="CustomTable">
              <TableHead>
                <TableRow>
                  <TableCell align="left">Processed Zip</TableCell>
                  {/* <TableCell align="left">Processed XL</TableCell> */}
                  <TableCell align="center">IV Device Make</TableCell>
                  <TableCell align="center">Zip Size</TableCell>
                  {/* {props.is_ivel && (
                    <TableCell align="center">XL Size</TableCell>
                  )} */}
                  <TableCell align="center">Date</TableCell>
                  <TableCell align="center">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{showProcessedList}</TableBody>
            </Table>
            {!processedData.length && (
              <Col md={12} className="mt-5">
                <h3 className="text-center">
                  No Data Found with name {searchBarValue}!
                </h3>
              </Col>
            )}

            {processedData.length > 0 && (
              <ReactPaginate
                previousLabel={"Previous"}
                nextLabel={"Next"}
                pageCount={pageCount}
                onPageChange={onPageChange}
                containerClassName={"pagnateContainer"}
                previousLinkClassName={"navigateBtn"}
                nextLinkClassName={"navigateBtn"}
                disabledClassName={"disabledBtn"}
                activeClassName={"activeBtn"}
              />
            )}
          </TableContainer>
        </div>
      </Dialog>
    </div>
  );
}
