import { createMuiTheme } from "@material-ui/core";
import * as axios from "axios";

export const fetchAuthToken = () => {
  try {
    const token = localStorage.getItem("token");
    return token;
  } catch (e) {
    console.log("local storage error: ", e);
    return false;
  }
};

export const fetchLoggedInUserEmail = () => {
  try {
    const userEmail = localStorage.getItem("email");
    return userEmail;
  } catch (e) {
    console.log("local storage error: ", e);
    return false;
  }
};

export const addHeaderInAxios = (props) => {
  axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
  const AUTH_TOKEN = localStorage.getItem("token");
  if (AUTH_TOKEN) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${AUTH_TOKEN}`;
    axios.defaults.headers.post["Content-Type"] =
      "application/x-www-form-urlencoded";
    return true;
  } else {
    localStorage.clear();
    props.history.replace("/");
    if (getCookie("csrftoken")) {
      delete_cookie("csrftoken");
    }
    console.log("not logged in");
    return false;
  }
};

function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
  return false;
}

export function delete_cookie(name) {
  document.cookie = name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
}

export const checkSignature = (error) => {
  if (
    error.response &&
    error.response.status &&
    error.response.status === 401
    // error.response.data.detail === "Signature has expired."
  ) {
    localStorage.clear();
    return window.location.reload("/");
  }
  return;
};

export const sliceName = (name) => {
  return String(name).length > 30 ? String(name).slice(0, 30) + "..." : name;
};

export const getNameFromUrl = (url) =>
  url ? url.split("/").slice(-1)[0].replace("%", " ") : false;

export const samplingMethodologyType = [
  { label: "Statistical Method", value: "statistical_method" },
  { label: "ISO 2859-1", value: "ISO2859-1" },
];

export const tooltipTheme = createMuiTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        color: "black",
        backgroundColor: "white",
        padding: "10px",
        border: "2px solid #3f51b5",
        boxShadow: `0px 2px 4px -1px rgb(0 0 0 / 20%),0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)`,
      },
    },
  },
});

export const helpTextsSampleSizeCalculation = {
  population_size:
    "	How many people are in the group your sample represents? (The sample size does not change much for populations larger than 20,000.)",
  margin_of_error:
    "This is the plus-or-minus figure usually reported in newspaper or television opinion poll results. For example, if you use a margin of error of 4% and 47% percent of your sample picks an answer, you can be 'sure' that if you had asked the question to the entire population, between 43% (47-4) and 51% (47+4) would have picked that answer.",
  confidence_level:
    "	This tells you how sure you can be of the margin of error. It is expressed as a percentage and represents how often the true percentage of the population who would pick an answer lies within the margin of error.",
  req_sample_size: "Number of respondents needed",
};

export const helpTextsMarginOfErrorCalculation = {
  population_size:
    "	How many people are in the group your sample represents? (The sample size does not change much for populations larger than 20,000.)",
  margin_of_error:
    "This is the plus-or-minus figure usually reported in newspaper or television opinion poll results. For example, if you use a margin of error of 4% and 47% percent of your sample picks an answer, you can be 'sure' that if you had asked the question to the entire population, between 43% (47-4) and 51% (47+4) would have picked that answer.",
  confidence_level:
    "	This tells you how sure you can be of the margin of error. It is expressed as a percentage and represents how often the true percentage of the population who would pick an answer lies within the margin of error.",
  num_of_respondents:
    "	The actual number of respondents that answered your survey.",
};

export const calInvNorCumulativeDistribution = (p) => {
  const a1 = -39.6968302866538,
    a2 = 220.946098424521,
    a3 = -275.928510446969;
  const a4 = 138.357751867269,
    a5 = -30.6647980661472,
    a6 = 2.50662827745924;
  const b1 = -54.4760987982241,
    b2 = 161.585836858041,
    b3 = -155.698979859887;
  const b4 = 66.8013118877197,
    b5 = -13.2806815528857,
    c1 = -7.78489400243029e-3;
  const c2 = -0.322396458041136,
    c3 = -2.40075827716184,
    c4 = -2.54973253934373;
  const c5 = 4.37466414146497,
    c6 = 2.93816398269878,
    d1 = 7.78469570904146e-3;
  const d2 = 0.32246712907004,
    d3 = 2.445134137143,
    d4 = 3.75440866190742;
  const p_low = 0.02425,
    p_high = 1 - p_low;
  let q, r;
  let retVal;

  if (p < 0 || p > 1) {
    alert("NormSInv: Argument out of range.");
    retVal = 0;
  } else if (p < p_low) {
    q = Math.sqrt(-2 * Math.log(p));
    retVal =
      (((((c1 * q + c2) * q + c3) * q + c4) * q + c5) * q + c6) /
      ((((d1 * q + d2) * q + d3) * q + d4) * q + 1);
  } else if (p <= p_high) {
    q = p - 0.5;
    r = q * q;
    retVal =
      ((((((a1 * r + a2) * r + a3) * r + a4) * r + a5) * r + a6) * q) /
      (((((b1 * r + b2) * r + b3) * r + b4) * r + b5) * r + 1);
  } else {
    q = Math.sqrt(-2 * Math.log(1 - p));
    retVal =
      -(((((c1 * q + c2) * q + c3) * q + c4) * q + c5) * q + c6) /
      ((((d1 * q + d2) * q + d3) * q + d4) * q + 1);
  }

  return retVal;
};

export const InspectionTypes = [
  { label: "S-1", value: "S-1" },
  { label: "S-2", value: "S-2" },
  { label: "S-3", value: "S-3" },
  { label: "S-4", value: "S-4" },
  { label: "G-1", value: "G-1" },
  { label: "G-2", value: "G-2" },
  { label: "G-3", value: "G-3" },
];

export const AQLDataArray = [
  "0.010",
  "0.015",
  "0.025",
  "0.040",
  "0.065",
  "0.10",
  "0.15",
  "0.25",
  "0.40",
  "0.65",
  "1.0",
  "1.5",
  "2.5",
  "4.0",
  "6.5",
  "10",
  "15",
  "25",
  "40",
  "65",
  "100",
  "150",
  "250",
  "400",
  "650",
  "1000",
];

export const sampleSizeCodes = [
  {
    min: 0,
    max: 8,
    data: {
      "S-1": "A",
      "S-2": "A",
      "S-3": "A",
      "S-4": "A",
      "G-1": "A",
      "G-2": "A",
      "G-3": "B",
    },
  },
  {
    min: 9,
    max: 15,
    data: {
      "S-1": "A",
      "S-2": "A",
      "S-3": "A",
      "S-4": "A",
      "G-1": "A",
      "G-2": "B",
      "G-3": "C",
    },
  },
  {
    min: 16,
    max: 25,
    data: {
      "S-1": "A",
      "S-2": "A",
      "S-3": "B",
      "S-4": "B",
      "G-1": "B",
      "G-2": "C",
      "G-3": "D",
    },
  },
  {
    min: 26,
    max: 50,
    data: {
      "S-1": "A",
      "S-2": "B",
      "S-3": "B",
      "S-4": "C",
      "G-1": "C",
      "G-2": "D",
      "G-3": "E",
    },
  },
  {
    min: 51,
    max: 90,
    data: {
      "S-1": "B",
      "S-2": "B",
      "S-3": "C",
      "S-4": "C",
      "G-1": "C",
      "G-2": "E",
      "G-3": "F",
    },
  },
  {
    min: 91,
    max: 150,
    data: {
      "S-1": "B",
      "S-2": "B",
      "S-3": "C",
      "S-4": "D",
      "G-1": "D",
      "G-2": "F",
      "G-3": "G",
    },
  },
  {
    min: 151,
    max: 280,
    data: {
      "S-1": "B",
      "S-2": "C",
      "S-3": "D",
      "S-4": "E",
      "G-1": "E",
      "G-2": "G",
      "G-3": "H",
    },
  },
  {
    min: 281,
    max: 500,
    data: {
      "S-1": "B",
      "S-2": "C",
      "S-3": "D",
      "S-4": "E",
      "G-1": "F",
      "G-2": "H",
      "G-3": "J",
    },
  },
  {
    min: 501,
    max: 1200,
    data: {
      "S-1": "C",
      "S-2": "C",
      "S-3": "E",
      "S-4": "F",
      "G-1": "G",
      "G-2": "J",
      "G-3": "K",
    },
  },
  {
    min: 1201,
    max: 3200,
    data: {
      "S-1": "C",
      "S-2": "D",
      "S-3": "E",
      "S-4": "G",
      "G-1": "H",
      "G-2": "K",
      "G-3": "L",
    },
  },
  {
    min: 3201,
    max: 10000,
    data: {
      "S-1": "C",
      "S-2": "D",
      "S-3": "F",
      "S-4": "G",
      "G-1": "J",
      "G-2": "L",
      "G-3": "M",
    },
  },
  {
    min: 10001,
    max: 35000,
    data: {
      "S-1": "C",
      "S-2": "D",
      "S-3": "F",
      "S-4": "H",
      "G-1": "K",
      "G-2": "M",
      "G-3": "N",
    },
  },
  {
    min: 35001,
    max: 150000,
    data: {
      "S-1": "D",
      "S-2": "E",
      "S-3": "G",
      "S-4": "J",
      "G-1": "L",
      "G-2": "N",
      "G-3": "P",
    },
  },
  {
    min: 150001,
    max: 500000,
    data: {
      "S-1": "D",
      "S-2": "E",
      "S-3": "G",
      "S-4": "J",
      "G-1": "M",
      "G-2": "P",
      "G-3": "Q",
    },
  },
  {
    min: 500001,
    max: 1000000000,
    data: {
      "S-1": "D",
      "S-2": "E",
      "S-3": "H",
      "S-4": "K",
      "G-1": "N",
      "G-2": "Q",
      "G-3": "R",
    },
  },
];

export const sampleSizeCodeValue = {
  A: 2,
  B: 3,
  C: 5,
  D: 8,
  E: 13,
  F: 20,
  G: 32,
  H: 50,
  J: 80,
  K: 125,
  L: 200,
  M: 315,
  N: 500,
  P: 800,
  Q: 1250,
  R: 2000,
};

export const AQLevels = {
  "0.010": {
    A: [0, 1],
    B: [0, 1],
    C: [0, 1],
    D: [0, 1],
    E: [0, 1],
    F: [0, 1],
    G: [0, 1],
    H: [0, 1],
    J: [0, 1],
    K: [0, 1],
    L: [0, 1],
    M: [0, 1],
    N: [0, 1],
    P: [0, 1],
    Q: [0, 1],
    R: [0, 1],
  },
  0.015: {
    A: [0, 1],
    B: [0, 1],
    C: [0, 1],
    D: [0, 1],
    E: [0, 1],
    F: [0, 1],
    G: [0, 1],
    H: [0, 1],
    J: [0, 1],
    K: [0, 1],
    L: [0, 1],
    M: [0, 1],
    N: [0, 1],
    P: [0, 1],
    Q: [0, 1],
    R: [0, 1],
  },
  0.025: {
    A: [0, 1],
    B: [0, 1],
    C: [0, 1],
    D: [0, 1],
    E: [0, 1],
    F: [0, 1],
    G: [0, 1],
    H: [0, 1],
    J: [0, 1],
    K: [0, 1],
    L: [0, 1],
    M: [0, 1],
    N: [0, 1],
    P: [0, 1],
    Q: [0, 1],
    R: [1, 2],
  },
  "0.040": {
    A: [0, 1],
    B: [0, 1],
    C: [0, 1],
    D: [0, 1],
    E: [0, 1],
    F: [0, 1],
    G: [0, 1],
    H: [0, 1],
    J: [0, 1],
    K: [0, 1],
    L: [0, 1],
    M: [0, 1],
    N: [0, 1],
    P: [0, 1],
    Q: [1, 2],
    R: [2, 3],
  },
  0.065: {
    A: [0, 1],
    B: [0, 1],
    C: [0, 1],
    D: [0, 1],
    E: [0, 1],
    F: [0, 1],
    G: [0, 1],
    H: [0, 1],
    J: [0, 1],
    K: [0, 1],
    L: [0, 1],
    M: [0, 1],
    N: [0, 1],
    P: [1, 2],
    Q: [2, 3],
    R: [3, 4],
  },
  "0.10": {
    A: [0, 1],
    B: [0, 1],
    C: [0, 1],
    D: [0, 1],
    E: [0, 1],
    F: [0, 1],
    G: [0, 1],
    H: [0, 1],
    J: [0, 1],
    K: [0, 1],
    L: [0, 1],
    M: [0, 1],
    N: [1, 2],
    P: [2, 3],
    Q: [3, 4],
    R: [5, 6],
  },
  0.15: {
    A: [0, 1],
    B: [0, 1],
    C: [0, 1],
    D: [0, 1],
    E: [0, 1],
    F: [0, 1],
    G: [0, 1],
    H: [0, 1],
    J: [0, 1],
    K: [0, 1],
    L: [1, 2],
    M: [1, 2],
    N: [2, 3],
    P: [3, 4],
    Q: [5, 6],
    R: [7, 8],
  },
  0.25: {
    A: [0, 1],
    B: [0, 1],
    C: [0, 1],
    D: [0, 1],
    E: [0, 1],
    F: [0, 1],
    G: [0, 1],
    H: [0, 1],
    J: [0, 1],
    K: [1, 2],
    L: [1, 2],
    M: [2, 3],
    N: [3, 4],
    P: [5, 6],
    Q: [7, 8],
    R: [10, 11],
  },
  "0.40": {
    A: [0, 1],
    B: [0, 1],
    C: [0, 1],
    D: [0, 1],
    E: [0, 1],
    F: [0, 1],
    G: [0, 1],
    H: [0, 1],
    J: [1, 2],
    K: [1, 2],
    L: [2, 3],
    M: [3, 4],
    N: [5, 6],
    P: [7, 8],
    Q: [10, 11],
    R: [14, 15],
  },
  0.65: {
    A: [0, 1],
    B: [0, 1],
    C: [0, 1],
    D: [0, 1],
    E: [0, 1],
    F: [0, 1],
    G: [0, 1],
    H: [1, 2],
    J: [1, 2],
    K: [2, 3],
    L: [3, 4],
    M: [5, 6],
    N: [7, 8],
    P: [10, 11],
    Q: [14, 15],
    R: [21, 22],
  },
  "1.0": {
    A: [0, 1],
    B: [0, 1],
    C: [0, 1],
    D: [0, 1],
    E: [0, 1],
    F: [0, 1],
    G: [1, 2],
    H: [1, 2],
    J: [2, 3],
    K: [3, 4],
    L: [5, 6],
    M: [7, 8],
    N: [10, 11],
    P: [14, 15],
    Q: [21, 22],
    R: [21, 22],
  },
  1.5: {
    A: [0, 1],
    B: [0, 1],
    C: [0, 1],
    D: [0, 1],
    E: [0, 1],
    F: [1, 2],
    G: [1, 2],
    H: [2, 3],
    J: [3, 4],
    K: [5, 6],
    L: [7, 8],
    M: [10, 11],
    N: [14, 15],
    P: [21, 22],
    Q: [21, 22],
    R: [21, 22],
  },
  2.5: {
    A: [0, 1],
    B: [0, 1],
    C: [0, 1],
    D: [0, 1],
    E: [1, 2],
    F: [1, 2],
    G: [2, 3],
    H: [4, 5],
    J: [5, 6],
    K: [7, 8],
    L: [10, 11],
    M: [14, 15],
    N: [21, 22],
    P: [21, 22],
    Q: [21, 22],
    R: [21, 22],
  },
  "4.0": {
    A: [0, 1],
    B: [0, 1],
    C: [0, 1],
    D: [1, 2],
    E: [1, 2],
    F: [2, 3],
    G: [3, 4],
    H: [5, 6],
    J: [7, 8],
    K: [10, 11],
    L: [14, 15],
    M: [21, 22],
    N: [21, 22],
    P: [21, 22],
    Q: [21, 22],
    R: [21, 22],
  },
  6.5: {
    A: [0, 1],
    B: [0, 1],
    C: [1, 2],
    D: [1, 2],
    E: [2, 3],
    F: [3, 4],
    G: [5, 6],
    H: [7, 8],
    J: [10, 11],
    K: [14, 15],
    L: [21, 22],
    M: [21, 22],
    N: [21, 22],
    P: [21, 22],
    Q: [21, 22],
    R: [21, 22],
  },
  10: {
    A: [1, 2],
    B: [1, 2],
    C: [1, 2],
    D: [2, 3],
    E: [3, 4],
    F: [5, 6],
    G: [7, 8],
    H: [10, 11],
    J: [14, 15],
    K: [21, 22],
    L: [21, 22],
    M: [21, 22],
    N: [21, 22],
    P: [21, 22],
    Q: [21, 22],
    R: [21, 22],
  },
  15: {
    A: [1, 2],
    B: [1, 2],
    C: [2, 3],
    D: [3, 4],
    E: [5, 6],
    F: [7, 8],
    G: [10, 11],
    H: [14, 15],
    J: [21, 22],
    K: [21, 22],
    L: [21, 22],
    M: [21, 22],
    N: [21, 22],
    P: [21, 22],
    Q: [21, 22],
    R: [21, 22],
  },
  25: {
    A: [1, 2],
    B: [2, 3],
    C: [3, 4],
    D: [5, 6],
    E: [7, 8],
    F: [10, 11],
    G: [14, 15],
    H: [21, 22],
    J: [21, 22],
    K: [21, 22],
    L: [21, 22],
    M: [21, 22],
    N: [21, 22],
    P: [21, 22],
    Q: [21, 22],
    R: [21, 22],
  },
  40: {
    A: [2, 3],
    B: [3, 4],
    C: [5, 6],
    D: [7, 8],
    E: [10, 11],
    F: [14, 15],
    G: [21, 22],
    H: [21, 22],
    J: [21, 22],
    K: [21, 22],
    L: [21, 22],
    M: [21, 22],
    N: [21, 22],
    P: [21, 22],
    Q: [21, 22],
    R: [21, 22],
  },
  65: {
    A: [3, 4],
    B: [5, 6],
    C: [7, 8],
    D: [10, 11],
    E: [14, 15],
    F: [21, 22],
    G: [21, 22],
    H: [21, 22],
    J: [21, 22],
    K: [21, 22],
    L: [21, 22],
    M: [21, 22],
    N: [21, 22],
    P: [21, 22],
    Q: [21, 22],
    R: [21, 22],
  },
  100: {
    A: [5, 6],
    B: [7, 8],
    C: [10, 11],
    D: [14, 15],
    E: [21, 22],
    F: [21, 22],
    G: [21, 22],
    H: [21, 22],
    J: [21, 22],
    K: [21, 22],
    L: [21, 22],
    M: [21, 22],
    N: [21, 22],
    P: [21, 22],
    Q: [21, 22],
    R: [21, 22],
  },
  150: {
    A: [7, 8],
    B: [10, 11],
    C: [14, 15],
    D: [21, 22],
    E: [30, 31],
    F: [30, 31],
    G: [30, 31],
    H: [30, 31],
    J: [30, 31],
    K: [30, 31],
    L: [30, 31],
    M: [30, 31],
    N: [30, 31],
    P: [30, 31],
    Q: [30, 31],
    R: [30, 31],
  },
  250: {
    A: [10, 11],
    B: [14, 15],
    C: [21, 22],
    D: [30, 31],
    E: [44, 45],
    F: [44, 45],
    G: [44, 45],
    H: [44, 45],
    J: [44, 45],
    K: [44, 45],
    L: [44, 45],
    M: [44, 45],
    N: [44, 45],
    P: [44, 45],
    Q: [44, 45],
    R: [44, 45],
  },
  400: {
    A: [14, 15],
    B: [21, 22],
    C: [30, 31],
    D: [44, 45],
    E: [44, 45],
    F: [44, 45],
    G: [44, 45],
    H: [44, 45],
    J: [44, 45],
    K: [44, 45],
    L: [44, 45],
    M: [44, 45],
    N: [44, 45],
    P: [44, 45],
    Q: [44, 45],
    R: [44, 45],
  },
  650: {
    A: [21, 22],
    B: [30, 31],
    C: [44, 45],
    D: [44, 45],
    E: [44, 45],
    F: [44, 45],
    G: [44, 45],
    H: [44, 45],
    J: [44, 45],
    K: [44, 45],
    L: [44, 45],
    M: [44, 45],
    N: [44, 45],
    P: [44, 45],
    Q: [44, 45],
    R: [44, 45],
  },
  1000: {
    A: [30, 31],
    B: [44, 45],
    C: [44, 45],
    D: [44, 45],
    E: [44, 45],
    F: [44, 45],
    G: [44, 45],
    H: [44, 45],
    J: [44, 45],
    K: [44, 45],
    L: [44, 45],
    M: [44, 45],
    N: [44, 45],
    P: [44, 45],
    Q: [44, 45],
    R: [44, 45],
  },
};

export const UncertaintyCalcTypes = [
  { label: "Single Calculation", value: "single" },
  { label: "Bulk Calculation", value: "bulk" },
];

export const IV_Device_Make = [
  { label: "EKO", value: "EKO" },
  { label: "SOLMETRIC", value: "SOLMETRIC" },
];

export const measurementTypes = [
  { label: "MODULE IV", value: "module_iv" },
  { label: "STRING IV", value: "string_iv" },
];

export const module_iv_value = {
  voltage: 100,
  current: 10,
};

export const string_iv_value = {
  voltage: 1000,
  current: 30,
};

export const Type_A = 0.6921;
export const sqrt_3 = 1.73205080757;
export const radiation_std_dev = 0.866;

export const radiation_std_dev_solmetric = 1.155;

export const TimeResOptns = [
  { label: "1 min", value: 1 },
  { label: "5 min", value: 5 },
  { label: "15 min", value: 15 },
  { label: "60 min", value: 60 },
  { label: "Custom", value: "custom" },
];

export const StructureType = [
  { label: "Fixed Tilt", value: "fixed_tilt" },
  { label: "Seasonal Tilt", value: "seasonal_tilt" },
  { label: "Tracker", value: "tracker" },
];

export const TranspositionModel = [
  { label: "Isotropic", value: "isotropic" },
  { label: "Sandia", value: "sandia" },
  { label: "Hays", value: "hays" },
  { label: "perez", value: "perez" },
  { label: "reindl", value: "reindl" },
];

export const CSVDataFormat = [
  { label: "Solargis TMY", value: "solargis_tmy" },
  { label: "Solargis Timeseries", value: "solargis_timeseries" },
  { label: "Custom", value: "custom", disabled: true },
];

export const seasonalTiltInputs = {
  Jan: {
    tilt: 0,
    azimuth: 0,
  },
  Feb: {
    tilt: 0,
    azimuth: 0,
  },
  March: {
    tilt: 0,
    azimuth: 0,
  },
  April: {
    tilt: 0,
    azimuth: 0,
  },
  May: {
    tilt: 0,
    azimuth: 0,
  },
  June: {
    tilt: 0,
    azimuth: 0,
  },
  July: {
    tilt: 0,
    azimuth: 0,
  },
  Aug: {
    tilt: 0,
    azimuth: 0,
  },
  Sep: {
    tilt: 0,
    azimuth: 0,
  },
  Oct: {
    tilt: 0,
    azimuth: 0,
  },
  Nov: {
    tilt: 0,
    azimuth: 0,
  },
  Dec: {
    tilt: 0,
    azimuth: 0,
  },
};

export const trackerInputs = {
  pitch: 0,
  collector_length: 0,
  tracker_limiting_angle: 0,
};

export const fixedTiltInputs = {
  tilt: 0,
  azimuth: 0,
};

export const stdLocalTime = [
  6, 6.1, 6.2, 6.3, 6.4, 6.5, 6.6, 6.7, 6.8, 6.9, 7, 7.1, 7.2, 7.3, 7.4, 7.5,
  7.6, 7.7, 7.8, 7.9, 8, 8.1, 8.2, 8.3, 8.4, 8.5, 8.6, 8.7, 8.8, 8.9, 9, 9.1,
  9.2, 9.3, 9.4, 9.5, 9.6, 9.7, 9.8, 9.9, 10, 10.1, 10.2, 10.3, 10.4, 10.5,
  10.6, 10.7, 10.8, 10.9, 11, 11.1, 11.2, 11.3, 11.4, 11.5, 11.6, 11.7, 11.8,
  11.9, 12, 12.1, 12.2, 12.3, 12.4, 12.5, 12.6, 12.7, 12.8, 12.9, 13, 13.1,
  13.2, 13.3, 13.4, 13.5, 13.6, 13.7, 13.8, 13.9, 14, 14.1, 14.2, 14.3, 14.4,
  14.5, 14.6, 14.7, 14.8, 14.9, 15, 15.1, 15.2, 15.3, 15.4, 15.5, 15.6, 15.7,
  15.8, 15.9, 16, 16.1, 16.2, 16.3, 16.4, 16.5, 16.6, 16.7, 16.8, 16.9, 17,
  17.1, 17.2, 17.3, 17.4, 17.5, 17.6, 17.7, 17.8, 17.9, 18,
];

export const earthingConductorTypes = [
  {
    label:
      "6A Bare Conductor with No Risk of Fire or Danger to Any Other Touching or Surrounding Material",
    value: "6A",
  },
  {
    label:
      "6B Insulated Protective Conductors not Incorporated in Cables or Bare Conductors Touching Other Insulated Cables",
    value: "6B",
  },
  {
    label: "6C Protective Conductor as a Core in Multicore Cables",
    value: "6C",
  },
  {
    label:
      "6D Protective Bare Conductors in Hazardous Areas Where Ther e is Risk of Fire from Petroleum Bound Oil or Other Surrounding Material",
    value: "6D",
  },
];

export const earthinMaterials = [
  {
    label: "COPPER",
    value: "COPPER",
    disabled: false,
  },
  {
    label: "ALUMINIUM",
    value: "ALUMINIUM",
    disabled: false,
  },
  {
    label: "STEEL",
    value: "STEEL",
    disabled: false,
  },
];

export const faultDuration = [
  {
    label: "1 second",
    value: "1.00",
  },
  {
    label: "3 second",
    value: "3.00",
  },
];

export const insulationTypes = {
  "6B": [
    {
      label: "PVC",
      value: {
        "1.00": {
          COPPER: 136,
          ALUMINIUM: 90,
          STEEL: 49,
        },
        "3.00": {
          COPPER: 79,
          ALUMINIUM: 52,
          STEEL: 28,
        },
      },
    },
    {
      label: "Butyl Rubber",
      value: {
        "1.00": {
          COPPER: 160,
          ALUMINIUM: 106,
          STEEL: 58,
        },
        "3.00": {
          COPPER: 92,
          ALUMINIUM: 61,
          STEEL: 33,
        },
      },
    },
    {
      label: "XLPE/EPR",
      value: {
        "1.00": {
          COPPER: 170,
          ALUMINIUM: 112,
          STEEL: 62,
        },
        "3.00": {
          COPPER: 98,
          ALUMINIUM: 65,
          STEEL: 36,
        },
      },
    },
  ],

  "6C": [
    {
      label: "PVC",
      value: {
        "1.00": {
          COPPER: 115,
          ALUMINIUM: 76,
        },
        "3.00": {
          COPPER: 66,
          ALUMINIUM: 44,
        },
      },
    },
    {
      label: "Butyl Rubber",
      value: {
        "1.00": {
          COPPER: 134,
          ALUMINIUM: 89,
        },
        "3.00": {
          COPPER: 77,
          ALUMINIUM: 51,
        },
      },
    },
    {
      label: "XLPE/EPR",
      value: {
        "1.00": {
          COPPER: 143,
          ALUMINIUM: 94,
        },
        "3.00": {
          COPPER: 83,
          ALUMINIUM: 54,
        },
      },
    },
  ],
};

export const finalTemp = [
  {
    label: "150° C",
    value: {
      "1.00": {
        COPPER: 131,
        ALUMINIUM: 86,
        STEEL: 47,
      },
      "3.00": {
        COPPER: 76,
        ALUMINIUM: 50,
        STEEL: 27,
      },
    },
  },
  {
    label: "200° C",
    value: {
      "1.00": {
        COPPER: 153,
        ALUMINIUM: 101,
        STEEL: 56,
      },
      "3.00": {
        COPPER: 88,
        ALUMINIUM: 58,
        STEEL: 32,
      },
    },
  },
];

export const _6AData = {
  "1.00": {
    COPPER: 205,
    ALUMINIUM: 126,
    STEEL: 80,
  },
  "3.00": {
    COPPER: 118,
    ALUMINIUM: 73,
    STEEL: 46,
  },
};

export const earthingCalcType = [
  {
    label: "DC",
    value: "dc",
  },
  {
    label: "AC",
    value: "ac",
  },
  {
    label: "SWITCH YARD",
    value: "switchyard",
  },
];

export const STC_Types = [
  {
    label: "STC: IEC-60891:2009",
    value: "STC: IEC-60891:2009",
  },
  {
    label: "STC_2021: IEC-60891:2021",
    value: "STC_2021: IEC-60891:2021",
  },
];
export const IV_Translations = [
  {
    label: "Module IV",
    value: "Module IV",
  },
  {
    label: "String IV",
    value: "String IV",
  },
];

export const moduleLibraryFields = {
  Isc: 0,
  Make: "",
  Model: "",
  Pmax: 0,
  Technology: "NA",
  Vm: 0,
  Voc: 0,
  Im: 0,
  FF: 0,
  YOM: "NA",
  alpha_t: 0,
  beta_t: 0,
  gamma_t: 0,
  kappa_t: 0,
};

export const inverterLibraryFields = {
  Make: "",
  Model: "",
  Type: "",
  Vmax: 0,
  Vmpp_min: 0,
  Vmpp_max: 0,
  Imax_dc: 0,
  Isc_dc: 0,
  max_inputs: 0,
  // Pmax_dc: 0,
  // Pac_rated: 0,
  // Vac_nom: 0,
  // Vac_min: 0,
  // Vac_max: 0,
  // Iac_nom: 0,
  // Eff_euro: 0,
  // Eff_max: 0,
  // THD_max: 0,
};

export const el_classification_rule = {
  ruleName: "",
  defectTypes: {
    COLD_SOLDER_PARTIAL: {
      low: 0,
      moderate: 0,
    },
    COLD_SOLDER_FULL: {
      low: 0,
      moderate: 0,
    },
    DARK_SOLDER_PARTIAL: {
      low: 0,
      moderate: 0,
    },
    DARK_SOLDER_FULL: {
      low: 0,
      moderate: 0,
    },
    FINGER_INTERRUPTIONS: {
      low: 0,
      moderate: 0,
    },
    PARTIAL_CRACK: {
      low: 0,
      moderate: 0,
    },
    FULL_CRACK: {
      low: 0,
      moderate: 0,
    },
    COMPOUND_CRACK: {
      low: 0,
      moderate: 0,
    },
    SPIDER_CRACK: {
      low: 0,
      moderate: 0,
    },
    "CRACK_INACTIVE_AREA_DARK_0-4%": {
      low: 0,
      moderate: 0,
    },
    "CRACK_INACTIVE_AREA_DARK_4-8%": {
      low: 0,
      moderate: 0,
    },
    "CRACK_INACTIVE_AREA_DARK_>8%": {
      low: 0,
      moderate: 0,
    },
    "CRACK_INACTIVE_AREA_LIGHT_0-8%": {
      low: 0,
      moderate: 0,
    },
    "CRACK_INACTIVE_AREA_LIGHT_>8%": {
      low: 0,
      moderate: 0,
    },
    DIODE_FAILURE: {
      low: 0,
      moderate: 0,
    },
    "BACKSHEET_SCRATCH_LARGE_0-4%": {
      low: 0,
      moderate: 0,
    },
    "BACKSHEET_SCRATCH_LARGE_4-8%": {
      low: 0,
      moderate: 0,
    },
    "BACKSHEET_SCRATCH_LARGE_>8%": {
      low: 0,
      moderate: 0,
    },
    BACKSHEET_SCRATCH_THIN: {
      low: 0,
      moderate: 0,
    },
    CORROSION: {
      low: 0,
      moderate: 0,
    },
    DEAD_CELL: {
      low: 0,
      moderate: 0,
    },
    "Total Cracks": {
      low: 0,
      moderate: 0,
    },
  },
};
