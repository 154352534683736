import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import {
  Backdrop,
  Button,
  CircularProgress,
  Container,
  makeStyles,
} from "@material-ui/core";
import Footer from "../components/Footer";
import { InputGroup, Row, Col, FormControl } from "react-bootstrap";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import downloadIco from "../assets/images/cloud-computing.png";
import backLogo from "../assets/images/back.png";
import MyLoader from "../components/ContentLoader";

import axios from "axios";
import {
  GET_ALL_IVOPCQC_PROCESS,
  GET_SERVICE_BY_ID,
  START_IVOPCQC_SERVICE,
  START_IVSTC_SERVICE,
} from "../shared/ApiURLs";
import {
  addHeaderInAxios,
  checkSignature,
  IV_Device_Make,
  sliceName,
} from "../utils/Helpers";
import { toast, ToastContainer } from "react-toastify";
import ReactPaginate from "react-paginate";
import { SingleSelect } from "../components/SingleSelect";
import ErrorLogModal from "../components/ErrorLogModal";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const IVOPCQCService = (props) => {
  const classes = useStyles();

  const [service, setService] = useState([]);
  const [selectedZipFile, setSelectedZipFile] = useState(null);
  const [selectedXLFile, setSelectedXLFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [process, setProcess] = useState([]);
  const [isContentLoading, setContentLoading] = useState(true);
  const [searchBarValue, setSearchBarValue] = useState("");
  const [filteredProcessArr, setFilteredProcessArr] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [deviceMake, setDeviceMake] = useState(IV_Device_Make[0]);
  const [errorLog, setErrorLog] = useState([]);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const dataPerPage = 4;
  const pageVisited = pageNumber * dataPerPage;
  const pageCount = Math.ceil(filteredProcessArr.length / dataPerPage);

  useEffect(() => {
    addHeaderInAxios(props)
      ? getServiceById(props.match.params.service_id)
      : props.history.replace("/");
  }, []);

  const getServiceById = (service_id) => {
    axios
      .get(GET_SERVICE_BY_ID(service_id))
      .then((res) => {
        setService(res.data.service);
        getAllProcess();
        setContentLoading(false);
      })
      .catch((error) => {
        checkSignature(error);
        setContentLoading(false);
        props.history.replace("/services");
      });
  };

  const fileChangeHandler = (event, type) => {
    if (type === "zip_upload") {
      setSelectedZipFile(event.target.files[0]);
    } else if (type === "xl_upload") {
      setSelectedXLFile(event.target.files[0]);
    }
  };

  const getAllProcess = () => {
    axios
      .get(GET_ALL_IVOPCQC_PROCESS)
      .then((res) => {
        setProcess(res.data.process);
        setFilteredProcessArr(res.data.process);
      })
      .catch((error) => {
        checkSignature(error);
      });
  };

  const SubmitService = () => {
    setLoading(true);
    if (selectedZipFile !== null) {
      const formdata = new FormData();
      formdata.append("zip_file", selectedZipFile);
      formdata.append("service_id", service[0].id);
      formdata.append("device_make", deviceMake.value);
      axios
        .post(START_IVOPCQC_SERVICE, formdata)
        .then((res) => {
          setSelectedXLFile(null);
          setSelectedZipFile(null);
          getAllProcess(service[0].id);
          setLoading(false);
          handleToast("success", res.data.message);
        })
        .catch((e) => {
          checkSignature(e);
          setLoading(false);
          handleToast("error", e?.response?.data?.message);
          if (e.response.status === 400 && e?.response?.data?.csv_errors) {
            setErrorLog(JSON.parse(e?.response?.data?.csv_errors));
            setOpenErrorModal(true);
          }
        });
    } else {
      setLoading(false);
      handleToast("error", "Please select zip file!");
    }
  };

  const handleToast = (event_type, message) => {
    return event_type === "success"
      ? toast.success(message)
      : toast.error(message);
  };

  const backBtnHandler = () => {
    return props.history.push("/services");
  };

  const handleSearch = () => {
    let newProcessArr = process.filter((el) => {
      return String(el?.original_zip_name)
        .toLowerCase()
        .includes(searchBarValue.toLowerCase());
    });

    setFilteredProcessArr(newProcessArr);
    return newProcessArr;
  };

  const setFilteredProcessToOriginal = () => setFilteredProcessArr(process);

  const onPageChange = ({ selected }) => {
    setPageNumber(selected);
  };

  const handleDeviceMakeChange = (selectedOption) => {
    setDeviceMake(selectedOption);
  };

  return (
    <div className="main-wrapper">
      <Header {...props} />
      {isContentLoading && <MyLoader />}
      {!isContentLoading && (
        <Container className="mb-5">
          <Row>
            <Col sm={12} md={7} className="my-2 h-auto">
              <div className="custom_card px-4 py-3 h-100">
                <div>
                  <div>
                    <span>
                      <img
                        src={backLogo}
                        alt="back"
                        className="backBtn"
                        onClick={backBtnHandler}
                      />
                    </span>
                    <span className="mx-3 sscalcHeading d-inline pb-2">
                      {service[0]?.name}
                    </span>
                  </div>

                  <div className="py-1 px-2 my-2">
                    <div className="my-3">
                      <div className="mb-3">
                        <div className="">
                          <Row>
                            <Col sm={12} md={6} className="mb-4">
                              <div className="d-flex justify-content-between mb-2">
                                <h6>IV Device Make: </h6>
                              </div>

                              <SingleSelect
                                data={IV_Device_Make}
                                value={deviceMake}
                                placeholder={"Select one of them..."}
                                isDisabled={false}
                                isLoading={false}
                                handleChange={handleDeviceMakeChange}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col sm={12} md={12} lg={4}>
                              <label htmlFor="upload_zip" className="w-100">
                                <input
                                  style={{ display: "none" }}
                                  id="upload_zip"
                                  name="upload_zip"
                                  type="file"
                                  accept=".zip"
                                  onChange={(e) =>
                                    fileChangeHandler(e, "zip_upload")
                                  }
                                  onClick={(event) => {
                                    const { target = {} } = event || {};
                                    target.value = "";
                                  }}
                                />
                                <Button
                                  color="secondary"
                                  className="px-4 w-100"
                                  variant="contained"
                                  component="span"
                                >
                                  Choose Zip File
                                </Button>
                              </label>
                            </Col>
                            <Col sm={12} md={12} lg={8}>
                              <input
                                className="form-control"
                                type="text"
                                value={
                                  selectedZipFile?.name
                                    ? selectedZipFile?.name
                                    : ""
                                }
                                readOnly
                              />
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </div>
                    <Button
                      onClick={SubmitService}
                      variant="contained"
                      color="primary"
                      className="px-4 my-3"
                    >
                      Submit
                    </Button>
                  </div>
                </div>
              </div>
            </Col>
            <Col sm={12} md={5} className="my-2">
              <div className="custom_card px-4 py-3">
                <div>
                  <h2 className="my-2">Help</h2>
                  <p className="my-3 text-justify">
                    <b>What is {service[0]?.name} service?</b>
                    <br />
                    {service[0]?.description}
                  </p>
                  <p className="my-3 text-justify">
                    <b>How to use {service[0]?.name} service?</b>
                    <br />
                    {service[0]?.how_to_use}
                  </p>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              {/* Table Content */}
              {process.length !== 0 && (
                <div className="custom_card px-3 mt-3 py-3">
                  <Row className="my-3">
                    <Col sm={12} md={6}>
                      <div className="mb-3">
                        <InputGroup className="search-bar">
                          <FormControl
                            placeholder="Search by zip name..."
                            aria-label="Search by name"
                            aria-describedby="basic-addon2"
                            value={searchBarValue}
                            onChange={(e) => {
                              setSearchBarValue(e.target.value);
                            }}
                          />
                          <Button
                            style={{ borderRadius: 0 }}
                            variant="contained"
                            color="primary"
                            id="button-addon2"
                            onClick={handleSearch}
                          >
                            Search
                          </Button>
                        </InputGroup>
                      </div>
                    </Col>
                  </Row>

                  <TableContainer>
                    <Table aria-label="CustomTable">
                      <TableHead>
                        <TableRow>
                          <TableCell align="left">Zip Name</TableCell>
                          <TableCell align="center">Zip Size</TableCell>
                          <TableCell align="center">Device Make</TableCell>
                          <TableCell align="center">Status</TableCell>
                          <TableCell align="center">Download XL</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {filteredProcessArr
                          .slice(pageVisited, pageVisited + dataPerPage)
                          .map((el) => {
                            return (
                              <TableRow key={el?.id}>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  className="wrapText"
                                  align="left"
                                >
                                  <a
                                    href={el?.uploaded_zip_url}
                                    download
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    {sliceName(el?.original_zip_name)}
                                  </a>
                                </TableCell>

                                {el?.zip_size !== null && (
                                  <TableCell align="center">
                                    {String(
                                      (
                                        parseInt(el?.zip_size) /
                                        1024 /
                                        1024
                                      ).toFixed(2)
                                    ) + " MB"}
                                  </TableCell>
                                )}

                                {el?.zip_size === null && (
                                  <TableCell align="center">NA</TableCell>
                                )}

                                <TableCell align="center">
                                  {el?.iv_device_make}
                                </TableCell>

                                <TableCell align="center">
                                  {el?.status}
                                </TableCell>
                                <TableCell align="center">
                                  <a
                                    href={el?.processed_xl_url}
                                    download
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    <img
                                      className="hovImg"
                                      src={downloadIco}
                                      alt="download"
                                    />
                                  </a>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                    {!filteredProcessArr.length && (
                      <Col md={12} className="my-3">
                        <h3 className="text-center">
                          No Process Found with name {searchBarValue}!
                        </h3>
                        <div className="text-center mt-2">
                          <Button
                            variant="outlined"
                            color="primary"
                            onClick={setFilteredProcessToOriginal}
                          >
                            Get All Processes
                          </Button>
                        </div>
                      </Col>
                    )}

                    {filteredProcessArr.length > 0 && (
                      <ReactPaginate
                        previousLabel={"Previous"}
                        nextLabel={"Next"}
                        pageCount={pageCount}
                        onPageChange={onPageChange}
                        containerClassName={"pagnateContainer"}
                        previousLinkClassName={"navigateBtn"}
                        nextLinkClassName={"navigateBtn"}
                        disabledClassName={"disabledBtn"}
                        activeClassName={"activeBtn"}
                      />
                    )}
                  </TableContainer>
                </div>
              )}
            </Col>
          </Row>
        </Container>
      )}
      <ErrorLogModal
        openErrorModal={openErrorModal}
        setOpenErrorModal={setOpenErrorModal}
        errorLog={errorLog}
        zipName={selectedZipFile?.name}
        size={"lg"}
      />
      <ToastContainer />
      <Backdrop
        className={classes.backdrop}
        style={{ zIndex: "10" }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Footer />
    </div>
  );
};

export default IVOPCQCService;
