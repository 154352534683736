import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { FORGOT_PASSWORD } from "../shared/ApiURLs";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Backdrop, TextField, Button } from "@material-ui/core";
import logo from "../assets/images/logo.png";
import loginImage from "../assets/images/login.png";
import Toast from "../shared/Toast";
import { ToastContainer } from "react-toastify";
import { toast } from "react-toastify";
import { fetchAuthToken } from "../utils/Helpers";
import backLogo from "../assets/images/back.png";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const inputStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      // margin: theme.spacing(1),
      width: "100%",
    },
  },
}));

const ForgotPassword = (props) => {
  const classes = useStyles();
  const inputClasses = inputStyles();

  const [email, setEmail] = useState("");
  const [emailHelper, setEmailHelper] = useState("");
  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [disableBtn, setDisableBtn] = useState(true);

  useEffect(() => {
    let token = fetchAuthToken();
    if (token) {
      props.history.push("/services");
    }
  });

  const hassError = (key) => {
    return errors.indexOf(key) !== -1;
  };

  const validate = () => {
    var temp_errors = [...errors];

    const emailRegex = /\S+@\S+/;
    var validEmail = emailRegex.test(String(email).toLowerCase());

    if (email === "" && temp_errors.length < 4) {
      temp_errors.push("email_blank");
      setEmailHelper("Email is required");
    } else {
      temp_errors = temp_errors.filter((item) => item !== "email_blank");
      setEmailHelper("");
    }

    if (!validEmail && temp_errors.length < 4) {
      temp_errors.push("email_invalid");
      setEmailHelper("Please enter a valid email");
    } else {
      temp_errors = temp_errors.filter((item) => item !== "email_invalid");
      setEmailHelper("");
    }

    setErrors(temp_errors);
    if (errors.length > 0) {
      return false;
    } else {
      return true;
    }
  };

  const handleEmailChange = (event) => {
    let email = event.target.value;
    if (email === "") {
    } else {
      setEmail(email);
      setDisableBtn(false);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    validate();
    setLoading(true);

    const requestData = {
      email: email,
    };
    axios
      .post(FORGOT_PASSWORD, requestData)
      .then((res) => {
        setLoading(false);
        handleToast("success", res.data.message);

        setTimeout(() => {
          props.history.push({
            pathname: "/",
            state: { email: email },
          });
        }, 900);
      })
      .catch((error) => {
        handleToast(
          "error",
          "Failed to send recovery email, Please try again!"
        );
        setLoading(false);
      });
  };

  const handleToast = (event_type, message) => {
    return event_type === "success"
      ? toast.success(message)
      : toast.error(message);
  };

  const backBtnHandler = () => {
    return props.history.push("/login");
  };

  return (
    <>
      <ToastContainer />

      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <div className="container px-0" style={{ marginTop: "50px" }}>
        <div className="row">
          <div className="col-12 mb-5">
            <Link to={"/"}>
              <img src={logo} alt="" />
            </Link>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-12">
            <h3>
              <span>
                <img
                  src={backLogo}
                  alt="back"
                  className="backBtn"
                  onClick={backBtnHandler}
                />
              </span>{" "}
              <b>FORGOT PASSWORD</b>
            </h3>

            <form className={inputClasses.root} onSubmit={handleSubmit}>
              <div className="form-group mt-5 mb-5">
                <TextField
                  error={hassError("email_blank") || hassError("email_invalid")}
                  onChange={(e) => handleEmailChange(e)}
                  id="outlined-error-helper-text"
                  label="Email"
                  autoComplete={true}
                  defaultValue=""
                  helperText={emailHelper}
                  variant="outlined"
                />
              </div>
              <div>
                <Button
                  disabled={disableBtn}
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  Send password reset link
                </Button>
              </div>
            </form>
          </div>
          <div
            className="col-lg-8 col-md-12"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <img src={loginImage} alt="" />
          </div>
        </div>
      </div>
      {/* <Footer/> */}
    </>
  );
};

export default ForgotPassword;
